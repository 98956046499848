import BookList from "layouts/book/book-list";
import EditBook from "layouts/book/edit-book";
import NewBook from "layouts/book/new-book";
import AssignmentList from "layouts/assignment/assignment-list";
import EditAssignment from "layouts/assignment/edit-assignment";
import NewAssignment from "layouts/assignment/new-assignment";
import MyStudents from "layouts/student/my-students";
import MessageList from "layouts/messages/message-list";
import NewMessage from "layouts/messages/new-message";
import ClassReports from "layouts/reports/class/class-reports";
import ClassStudentReports from "layouts/reports/class/class-student-reports";
import StudentReports from "layouts/reports/class/student-reports";
import SchoolReports from "layouts/reports/school/school-reports";
import SchoolClassList from "layouts/reports/school/school-class-list";
import SchoolClassReports from "layouts/reports/school/school-class-reports";
import SchoolClassStudentList from "layouts/reports/school/school-class-student-list";
// import SchoolStudentReports from "layouts/reports/school/school-student-reports";

export const screenComponentMapping = {
  LIST_BOOK: BookList,
  ADD_BOOK: NewBook,
  EDIT_BOOK: EditBook,
  LIST_ASSIGNMENT: AssignmentList,
  ADD_ASSIGNMENT: NewAssignment,
  EDIT_ASSIGNMENT: EditAssignment,
  MY_STUDENTS: MyStudents,
  LIST_MESSAGES: MessageList,
  NEW_MESSAGE: NewMessage,
  CLASS_REPORTS: ClassReports,
  CLASS_STUDENT_REPORTS: ClassStudentReports,
  STUDENT_REPORTS: StudentReports,
  SCHOOL_REPORTS: SchoolReports,
  SCHOOL_CLASS_LIST: SchoolClassList,
  SCHOOL_CLASS_REPORTS: SchoolClassReports,
  SCHOOL_CLASS_STUDENT_LIST: SchoolClassStudentList,
  SCHOOL_STUDENT_REPORTS: StudentReports,
};
