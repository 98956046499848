class DateHelper {
  static transformDateToLocale(date, seperator = "-") {
    const [day, month, year] = date.split(seperator);
    const months = [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ];

    return `${parseInt(day, 10)} ${months[parseInt(month, 10) - 1]} ${year}`;
  }
}

export default DateHelper;
