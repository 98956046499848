import { PAGES } from "redux/actions/pages/constants";

// feature name
export const CALENDAR = `${PAGES} [Calendar]`;

// action types
export const START_CALENDAR = `${CALENDAR} START`;

// action creators
export const startCalendar = () => ({
  type: START_CALENDAR,
});
