import { PAGES } from "redux/actions/pages";

// feature name
export const NAVBAR = `${PAGES} [Navbar]`;

// action types
export const SIGNOUT = `${NAVBAR} SIGNOUT`;
export const GET_SEARCH_BOOKS = `${NAVBAR} GET_SEARCH_BOOKS`;

// action creators
export const signout = ({ email }) => ({
  type: SIGNOUT,
  payload: { email },
});

export const getSearchBooks = () => ({
  type: GET_SEARCH_BOOKS,
});
