import {
  foundInStorage,
  notFoundInStorage,
  GET_FROM_STORAGE,
  SET_TO_STORAGE,
  REMOVE_FROM_STORAGE,
} from "redux/actions/storage";

export const storageMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (true) {
      case action.type.includes(GET_FROM_STORAGE):
        // eslint-disable-next-line no-case-declarations
        const storedData = localStorage.getItem(action.payload);
        if (storedData && storedData !== "") {
          dispatch(foundInStorage({ result: storedData, feature: action.meta.feature }));
        } else {
          dispatch(notFoundInStorage({ feature: action.meta.feature }));
        }
        break;
      case action.type.includes(SET_TO_STORAGE):
        localStorage.setItem(action.payload.storageKey, action.payload.storageValue);
        break;
      case action.type.includes(REMOVE_FROM_STORAGE):
        localStorage.removeItem(action.payload);
        break;
      default:
        break;
    }
  };
