const automaticMessages = [
  { value: "2", label: "İlk bitirene özel bir ödülümüz mevcut!" },
  { value: "3", label: "Lütfen dikkatli bir şekilde görevi tamamlayınız." },
  { value: "4", label: "Ev çalışması" },
  { value: "5", label: "Çarşamba son gün!" },
  { value: "6", label: "Cuma son gün!" },
  { value: "7", label: "Teşekkürler." },
  { value: "8", label: "İyi çalışmalar." },
];

export default automaticMessages;
