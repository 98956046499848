/* eslint-disable react/prop-types */
import React from "react";
import { ThumbsUp, HandPalm } from "phosphor-react";
import { connect } from "react-redux";
import SuiAlert from "components/SuiAlert";

import "./_custom-alert.css";

const Alert = ({ notifications }) => (
  <div className="custom-alert">
    {notifications.map((notification) => (
      <SuiAlert
        key={notification.message.id}
        color={notification.type === "success" ? "info" : "error"}
      >
        {notification.type === "success" ? (
          <ThumbsUp size={24} weight="duotone" />
        ) : (
          <HandPalm size={24} weight="duotone" />
        )}
        <strong>{notification.type === "success" ? " Başarılı! " : " Başarısız! "}</strong>
        <p>{notification.message.message}</p>
      </SuiAlert>
    ))}
  </div>
);
const mapStateToProps = (state) => ({
  notifications: state.notifications,
});

export default connect(mapStateToProps, {})(Alert);
