/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
/**
  This file is used for controlling the dark and light state of the TimelineList and TimelineItem.
*/

import { createContext, useContext } from "react";

// The Timeline main context
const MessageTimeline = createContext();

// Timeline context provider
function MessageTimelineProvider({ children, value }) {
  return <MessageTimeline.Provider value={value}>{children}</MessageTimeline.Provider>;
}

// MessageTimeline custom hook for using context
function useMessageTimeline() {
  return useContext(MessageTimeline);
}

export { MessageTimelineProvider, useMessageTimeline };
/* eslint-enable react/prop-types */
