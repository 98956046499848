/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, Tooltip } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiBadge from "components/SuiBadge";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import LessonPlanDialog from "../LessonPlanDialog";

const SummaryLessonPlan = ({
  material,
  localizations,
  language,
  bookLevel,
  bookOrder,
  bookTitle,
  coverImage,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Card heigth="auto">
        <SuiBox p={1.5} py={3}>
          <SuiTypography
            component="a"
            variant="button"
            fontWeight="bold"
            color="inherit"
            style={{ display: "block", textAlign: "center" }}
          >
            {(
              localizations[material.learningDesignType] ?? material.learningDesignType
            )?.toLocaleUpperCase(language)}
          </SuiTypography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "30px",
              fontSize: "13px",
              fontWeight: 400,
            }}
          >
            <p>{`${localizations?.LESSON ?? "Ders"}-${material.materialOrder}`}</p>
            <p>
              {material.duration < 40
                ? material.duration
                : material.duration === 40
                ? "1x40"
                : material.duration > 40
                ? `${material.duration / 40}x40`
                : ""}
            </p>
          </div>

          <div style={{ fontSize: "13px", marginTop: "20px" }}>
            <p style={{ fontWeight: 500 }}>
              {localizations?.SUMMARY_CARD_OBJECTIVE_HEADER ?? "Kazanım"}
            </p>
            <div style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}>
              {material.objectives.split(",").map((obj) => (
                <div key={obj} style={{ marginBottom: "5px", marginRight: "5px" }}>
                  <Tooltip title={localizations[obj] ?? obj} placement="left">
                    <SuiBadge
                      color="light"
                      badgeContent={obj.replace(/_/g, ".")}
                      size="sm"
                      container
                    />
                  </Tooltip>
                </div>
              ))}
            </div>
          </div>

          <div style={{ fontSize: "13px", marginTop: "20px" }}>
            <p style={{ fontWeight: 500 }}>
              {localizations?.SUMMARY_CARD_DESC_HEADER ?? "Açıklama"}
            </p>
            <p style={{ fontWeight: 400, whiteSpace: "pre-wrap" }}>{material.description}</p>
          </div>

          <div style={{ fontSize: "13px", marginTop: "20px" }}>
            <p style={{ fontWeight: 500 }}>
              {localizations?.SUMMARY_CARD_MATERIAL_HEADER ?? "Materyal"}
            </p>
            <a
              href={material.fileUrl}
              rel="noreferrer"
              target="_blank"
              style={{ fontWeight: 400, whiteSpace: "pre-wrap", color: "#328EF9" }}
            >
              {`${bookLevel.replace(
                "LEVEL_",
                ""
              )}.${bookOrder}_${material.documentType.toLowerCase()}_${bookTitle
                ?.toLowerCase()
                ?.replace(/ç/g, "c")
                ?.replace(/ğ/g, "g")
                ?.replace(/ı/g, "i")
                ?.replace(/ö/g, "o")
                ?.replace(/ş/g, "s")
                ?.replace(/ü/g, "u")
                ?.replace(/,|!|\?| /g, "")}_${material.materialOrder}`}
            </a>
          </div>
          <div style={{ marginTop: "20px" }}>
            <SuiButton
              size="small"
              variant="contained"
              fullWidth
              onClick={(e) => {
                e.preventDefault();
                setModalOpen(true);
              }}
              style={{ backgroundColor: "#E3E3E3", color: "#2C2C2C", textTransform: "none" }}
            >
              {localizations?.DETAILED_LESSON_PLAN_BTN ?? "Detaylı Plan"}
            </SuiButton>
          </div>
        </SuiBox>
      </Card>
      <LessonPlanDialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        material={material}
        bookLevel={bookLevel}
        bookOrder={bookOrder}
        bookTitle={bookTitle}
        coverImage={coverImage}
      />
    </>
  );
};

SummaryLessonPlan.defaultProps = {
  localizations: null,
  language: "TR",
  bookLevel: null,
  bookOrder: null,
  bookTitle: null,
  coverImage: null,
};

SummaryLessonPlan.propTypes = {
  material: PropTypes.objectOf(PropTypes.any).isRequired,
  localizations: PropTypes.objectOf(PropTypes.any),
  language: PropTypes.string,
  bookLevel: PropTypes.string,
  bookOrder: PropTypes.number,
  bookTitle: PropTypes.string,
  coverImage: PropTypes.string,
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
  language: state.localization?.language,
});

export default connect(mapStateToProps, null)(SummaryLessonPlan);
