/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Dialog from "@mui/material/Dialog";
import MessageTimelineList from "custom/MessageTimeline/MessageTimelineList";
import MessageTimelineItem from "custom/MessageTimeline/MessageTimelineItem";
import CustomSuiSelect from "custom/CustomSuiSelect";
import SuiInput from "components/SuiInput";
import Tooltip from "@mui/material/Tooltip";
import ReactAudioPlayer from "react-audio-player";
import { Card } from "@mui/material";
import Divider from "@mui/material/Divider";
import SuiBox from "components/SuiBox";
import { Envelope, Check, X, Microphone, Record } from "phosphor-react";
import Grid from "@mui/material/Grid";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import useAudio from "hooks/use-audio";
import {
  getAudioUploadParams,
  createNewMessage,
  removeAudioUploadParams,
} from "redux/actions/pages";

const FeedbackMessageDialog = ({
  onClose,
  open,
  messages,
  userId,
  assignmentId,
  localizations,
  uploadParameters,
  getAudioUploadingParameters,
  sendNewMessage,
  removeAudioUploadingParameters,
}) => {
  if (!(assignmentId > 0) || !(userId > 0)) {
    return <></>;
  }

  const [message, setMessage] = useState("");
  const [audioMessageDoUrl, setAudioMessageDoUrl] = useState("");

  const [audioUrl, isRecording, startRecording, stopRecording, audioData, removeAudioData] =
    useAudio({
      MESSAGE_ERROR: localizations?.MESSAGE_ERROR,
      MESSAGE_START_AUDIO_ERROR_TEXT: localizations?.MESSAGE_START_AUDIO_ERROR_TEXT,
      MESSAGE_CONFIRM_BTN: localizations?.MESSAGE_CONFIRM_BTN,
    });

  useEffect(() => {
    if (
      uploadParameters &&
      uploadParameters.key &&
      uploadParameters.key !== "" &&
      uploadParameters.url &&
      uploadParameters.url !== ""
    ) {
      const { key, url } = uploadParameters;
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", `${url}`);
      xhr.setRequestHeader("Content-Type", "audio/mpeg");
      xhr.setRequestHeader("x-amz-acl", "public-read");
      xhr.send(audioData);
      xhr.onload = (e) => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            const edictSoundUrl = `https://edictdigitalspaces.fra1.digitaloceanspaces.com/${key}`;
            setAudioMessageDoUrl(edictSoundUrl);
          } else {
            // eslint-disable-next-line no-console
            console.error(xhr.statusText);
          }
        }
      };
      xhr.onerror = (e) => {
        // eslint-disable-next-line no-console
        console.error("on sound upload error");
        // eslint-disable-next-line no-console
        console.error(xhr.statusText);
      };
      xhr.onloadend = (ev) => {};
    }
  }, [uploadParameters]);

  const handleUploadSound = () => {
    const fileType = "audio/mpeg";
    const extention = "mp3";
    const folderName = "usersoundfiles";
    getAudioUploadingParameters({ type: fileType, folder: folderName, extention });
  };

  return (
    <Dialog scroll="body" onClose={onClose} open={open}>
      <Card>
        <SuiBox variant="gradient">
          <MessageTimelineList title="Görev Mesajları">
            {messages && messages.length > 0 ? (
              <>
                {messages.map((m, index) => (
                  <MessageTimelineItem
                    key={`message-${m.id}`}
                    icon={<Envelope size={20} weight="duotone" />}
                    dateTime={m.createdAt}
                    content={m.content}
                    audioUrl={m.audioMessageUrl}
                    lastItem={m.length - 1 === index}
                  />
                ))}
              </>
            ) : (
              <SuiBox ml={3}>
                <SuiTypography variant="button" fontWeight="regular" color="dark">
                  Bu görevle ilgili daha önce bir mesaj gönderilmemiştir.
                </SuiTypography>
              </SuiBox>
            )}
          </MessageTimelineList>
        </SuiBox>
        <Divider sx={{ margin: 0 }} light={false} />
        <SuiBox variant="gradient">
          <SuiBox py={3} px={3}>
            <Grid item xs={12}>
              <Grid container flexWrap="wrap">
                <Grid item lg={6} xs={6} mb={1}>
                  <SuiTypography variant="h6" fontWeight="medium" color="dark">
                    Yeni Mesaj Gönder
                  </SuiTypography>
                </Grid>
                <Grid item lg={6} xs={6} style={{ minWidth: "200px" }} mb={1}>
                  <div className="message-select-container">
                    <CustomSuiSelect
                      size="small"
                      height={audioUrl && audioUrl !== "" && audioData ? 200 : 150}
                      onChange={(selected) => {
                        if (message === "") {
                          setMessage(`${selected.label}`);
                        } else {
                          setMessage(`${message}\n${selected.label}`);
                        }
                      }}
                      value={null}
                      placeholder="Mesaj Seç"
                      options={[
                        { value: "2", label: "İlk bitirene özel bir ödülümüz mevcut!" },
                        { value: "3", label: "Lütfen dikkatli bir şekilde görevi tamamlayınız." },
                        { value: "4", label: "Ev çalışması" },
                        { value: "5", label: "Çarşamba son gün!" },
                        { value: "6", label: "Cuma son gün!" },
                        { value: "7", label: "Teşekkürler." },
                        { value: "8", label: "İyi çalışmalar." },
                      ]}
                    />
                  </div>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <div className="message-container" style={{ margin: "0 10px" }}>
                    <SuiInput
                      placeholder="Mesaj yazınız..."
                      multiline
                      rows={5}
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                      style={{ paddingRight: "60px" }}
                    />
                    <div className="message-record-button">
                      {!isRecording ? (
                        <Tooltip title="Sesli mesaj kaydet" placement="left">
                          <Microphone
                            color="#67BCEC"
                            size={40}
                            weight="duotone"
                            onClick={(e) => {
                              e.preventDefault();
                              startRecording();
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <div
                          style={{
                            backgroundColor: "#DD6363",
                            width: "40px",
                            height: "40px",
                            borderRadius: "20px",
                          }}
                        >
                          <Tooltip title="Ses kaydını durdur" placement="left">
                            <Record
                              color="white"
                              size={40}
                              weight="duotone"
                              onClick={(e) => {
                                e.preventDefault();
                                stopRecording();
                              }}
                            />
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>

                {audioUrl && audioUrl !== "" && audioData ? (
                  <Grid item lg={12} xs={12}>
                    <div
                      className="audio-player-container"
                      style={{ margin: "10px 10px 0 10px", display: "flex" }}
                    >
                      <ReactAudioPlayer src={audioUrl} controls style={{ width: "100%" }} />
                      <Tooltip title="Kaydet" placement="top">
                        <SuiButton
                          className="icon-buttons gray"
                          onClick={(e) => {
                            e.preventDefault();
                            handleUploadSound();
                          }}
                          size="large"
                          iconOnly
                          circular
                        >
                          <Check weight="bold" color="green" />
                        </SuiButton>
                      </Tooltip>
                      <Tooltip title="Vazgeç" placement="top">
                        <SuiButton
                          className="icon-buttons gray"
                          onClick={(e) => {
                            e.preventDefault();
                            removeAudioData();
                            setAudioMessageDoUrl("");
                          }}
                          size="large"
                          iconOnly
                          circular
                        >
                          <X weight="bold" color="red" />
                        </SuiButton>
                      </Tooltip>
                    </div>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={12} mt={3}>
              <SuiButton
                color="info"
                fullWidth
                onClick={(e) => {
                  e.preventDefault();

                  const errorMessage = [];
                  if (
                    audioUrl &&
                    audioUrl !== "" &&
                    audioData &&
                    (!audioMessageDoUrl || audioMessageDoUrl === "")
                  ) {
                    errorMessage.push(
                      "Ses kaydınızı lütfen onaylayınız. Ses kaydını iptal etmek için lütfen iptal butonuna tıklayınız."
                    );
                  }

                  if (
                    (!audioMessageDoUrl || audioMessageDoUrl === "") &&
                    (!message || message === "")
                  ) {
                    errorMessage.push("Ses kaydı ya da metin olarak bir mesaj oluşturmalısınız.");
                  }

                  if (errorMessage.length === 0) {
                    const newMessage = {
                      assignmentId,
                      toUserId: userId,
                      content: message,
                      audioMessageUrl: audioMessageDoUrl,
                    };
                    removeAudioUploadingParameters();
                    removeAudioData();
                    setMessage("");
                    setAudioMessageDoUrl("");
                    sendNewMessage({ message: newMessage });
                  } else {
                    Swal.fire({
                      icon: "error",
                      title: "Mesaj Gönderilemedi",
                      html: `
                    <p class="error-desc">
                      Lütfen aşağıda belirtilen hataları veya eksiklikleri gidererek tekrar deneyiniz.
                    </p>
                    <ul class="message-error-list">
                      ${errorMessage
                        .map(
                          (errMessage) => `
                        <li class="message-error-item">* ${errMessage}</li>
                      `
                        )
                        .join("")}
                    </ul>
                  `,
                      showCancelButton: false,
                      confirmButtonText: "Tamam",
                    }).then((_) => {
                      // Cancelled
                    });
                  }
                }}
              >
                Gönder
              </SuiButton>
            </Grid>
          </SuiBox>
        </SuiBox>
      </Card>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
  uploadParameters: state.data.uploadParameters,
});

const mapDispatchToProps = {
  getAudioUploadingParameters: getAudioUploadParams,
  sendNewMessage: createNewMessage,
  removeAudioUploadingParameters: removeAudioUploadParams,
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackMessageDialog);
