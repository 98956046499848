import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { apiMiddleware } from "./middlewares/api";
import { authMiddleware } from "./middlewares/auth";
import { localizationMiddleware } from "./middlewares/localization";
import { notificationsMiddleware } from "./middlewares/notifications";
import { pagesMiddleware } from "./middlewares/pages";
import { storageMiddleware } from "./middlewares/storage";
import { logMiddleware } from "./middlewares/log";
import { authReducer } from "./reducers/auth";
import { dataReducer } from "./reducers/data";
import { messageReducer } from "./reducers/message";
import { notificationsReducer } from "./reducers/notifications";
import { uiReducer } from "./reducers/ui";
import { headerReducer } from "./reducers/header";
import { localizationReducer } from "./reducers/localization";

const rootReducer = combineReducers({
  auth: authReducer,
  notifications: notificationsReducer,
  ui: uiReducer,
  message: messageReducer,
  data: dataReducer,
  header: headerReducer,
  localization: localizationReducer,
});

const featureMiddlewares = [authMiddleware, localizationMiddleware, pagesMiddleware];
const coreMiddlewares = [logMiddleware, apiMiddleware, notificationsMiddleware, storageMiddleware];

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 100,
    })) ||
  compose;

const enhancer = composeEnhancers(applyMiddleware(...featureMiddlewares, ...coreMiddlewares));

export const store = createStore(rootReducer, {}, enhancer);
