/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { connect } from "react-redux";
import SuiBox from "components/SuiBox";

const requireAuth = (ChildComponent) => {
  class ComposedComponent extends Component {
    componentDidMount() {
      this.shouldNavigateAway();
    }

    componentDidUpdate() {
      this.shouldNavigateAway();
    }

    shouldNavigateAway() {
      const { auth, history } = this.props;

      if (!auth || !auth.currentUser || !auth.currentUser.id) {
        history.push("/");
      }
    }

    render() {
      const { auth } = this.props;
      return !auth || !auth.currentUser || !auth.currentUser.id ? (
        <SuiBox />
      ) : (
        <ChildComponent {...this.props} />
      );
    }
  }

  const mapToStateProps = (state) => ({ auth: state.auth });

  return connect(mapToStateProps)(ComposedComponent);
};

export default requireAuth;
