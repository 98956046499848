import { PAGES } from "redux/actions/pages/constants";

// feature name
export const MY_PROFILE = `${PAGES} [MyProfile]`;

// action types
export const GET_MY_PROFILE = `${MY_PROFILE} GET_MY_PROFILE`;
export const UPDATE_MY_PROFILE = `${MY_PROFILE} UPDATE`;
export const CHANGE_PASSWORD = `${MY_PROFILE} CHANGE_PASSWORD`;

// action creators
export const getMyProfile = () => ({
  type: GET_MY_PROFILE,
});

export const updateMyProfile = ({ updatedFields }) => ({
  type: UPDATE_MY_PROFILE,
  payload: { updatedFields },
});

export const changePassword = ({ oldPassword, newPassword }) => ({
  type: CHANGE_PASSWORD,
  payload: { oldPassword, newPassword },
});
