import { PAGES } from "redux/actions/pages/constants";

// feature name
export const SIDENAV = `${PAGES} [Sidenav]`;

// action types
export const SELECT_SIDENAV_ITEM = `${SIDENAV} SELECT`;

// action creators
export const selectSidenavItem = (header) => ({
  type: SELECT_SIDENAV_ITEM,
  payload: header,
});
