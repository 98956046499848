import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Howl } from "howler";
import { Play, Pause } from "phosphor-react";
import { Tooltip } from "@mui/material";

import SuiBox from "components/SuiBox";
import { connect } from "react-redux";

const AudioMessageCell = ({ url, localizations }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio, setAudio] = useState(null);

  useEffect(() => {
    if (isPlaying) {
      audio?.play();
    } else {
      audio?.pause();
    }
  }, [audio, isPlaying]);

  const onButtonClick = (e) => {
    e.preventDefault();
    if (!isPlaying) {
      if (audio) {
        setIsPlaying(true);
      } else {
        // Varolanı Kaldır!
        audio?.unload();
        setIsPlaying(false);
        setAudio(null);

        // Yeniden oluştur!
        const sound = new Howl({
          src: url,
        });

        sound.on("end", () => {
          audio?.unload();
          setIsPlaying(false);
          setAudio(null);
        });

        setAudio(sound);
        setIsPlaying(true);
      }
    } else {
      setIsPlaying(false);
    }
  };

  return url === "" || !url ? (
    <p>-</p>
  ) : (
    <Tooltip
      title={
        !isPlaying
          ? localizations?.PLAY_TOOLTIP ?? "Oynat"
          : localizations?.PAUSE_TOOLTIP ?? "Durdur"
      }
      placement="top"
    >
      <SuiBox
        style={{
          padding: "8px",
          backgroundColor: "#67BCEC",
          borderRadius: "23px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onClick={onButtonClick}
      >
        {!isPlaying ? (
          <Play weight="duotone" size={30} color="#FFFFFF" />
        ) : (
          <Pause weight="duotone" size={30} color="#FFFFFF" />
        )}
      </SuiBox>
    </Tooltip>
  );
};

AudioMessageCell.defaultProps = {
  url: "",
  localizations: null,
};

AudioMessageCell.propTypes = {
  url: PropTypes.string,
  localizations: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
});

export default connect(mapStateToProps, null)(AudioMessageCell);
