/* GENERATED FILE */
import React, { forwardRef } from "react";

import {
  IconWeight,
  IconProps,
  PaintFunction,
  renderPathForWeight,
} from "../lib";
import IconBase, { RenderFunction } from "../lib/IconBase";

const pathsByWeight = new Map<IconWeight, PaintFunction>();

pathsByWeight.set("bold", (color: string) => (
  <>
    <rect
      x="32"
      y="68"
      width="128"
      height="120"
      rx="8"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    />
    <line
      x1="114"
      y1="104"
      x2="78"
      y2="104"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    />
    <line
      x1="96"
      y1="152"
      x2="96"
      y2="104"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    />
    <path
      d="M160,88h60v80a56,56,0,0,1-56,56h0a55.9,55.9,0,0,1-52.3-36"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    />
    <path
      d="M134.3,68A32,32,0,1,1,164,88"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    />
  </>
));

pathsByWeight.set("duotone", (color: string) => (
  <>
    <rect x="32" y="72" width="120" height="112" rx="8" opacity="0.2" />
    <rect
      x="32"
      y="72"
      width="120"
      height="112"
      rx="8"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <path
      d="M152,88h32a8,8,0,0,1,8,8v72a56,56,0,0,1-56,56h0a56.1,56.1,0,0,1-53.7-40"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <line
      x1="110"
      y1="104"
      x2="74"
      y2="104"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <line
      x1="92"
      y1="152"
      x2="92"
      y2="104"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <path
      d="M108.3,72A32,32,0,1,1,152,83.7"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <path
      d="M166.1,45.2a26,26,0,1,1-3.4,28.4"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <path
      d="M192,88h27.3a4.7,4.7,0,0,1,4.7,4.7V152a32,32,0,0,1-32,32h-2.3"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
  </>
));

pathsByWeight.set("fill", () => (
  <>
    <path d="M219.3,80h-4.5A33.5,33.5,0,0,0,220,62a34,34,0,0,0-51.4-29.2A40,40,0,0,0,96,56a42.6,42.6,0,0,0,.8,8H40A16,16,0,0,0,24,80v96a16,16,0,0,0,16,16H76.7a64,64,0,0,0,118.7-.2A40,40,0,0,0,232,152V92.7A12.7,12.7,0,0,0,219.3,80ZM136,32a24.1,24.1,0,0,1,24,24,24.3,24.3,0,0,1-4.1,13.4A15.9,15.9,0,0,0,144,64H113.4a24.5,24.5,0,0,1-1.4-8A24.1,24.1,0,0,1,136,32ZM84,152V112H74a8,8,0,0,1,0-16h36a8,8,0,0,1,0,16H100v40a8,8,0,0,1-16,0Zm100,16a48,48,0,0,1-48,48,48.5,48.5,0,0,1-41.6-24H144a16,16,0,0,0,16-16V96h24Zm2-88a18,18,0,0,1-14-6.7A39.2,39.2,0,0,0,176,56a37.7,37.7,0,0,0-.9-8.3A17.7,17.7,0,0,1,186,44a18,18,0,0,1,0,36Zm30,72a24,24,0,0,1-16.4,22.7,49.2,49.2,0,0,0,.4-6.7V96h16Z" />
  </>
));

pathsByWeight.set("light", (color: string) => (
  <>
    <rect
      x="32"
      y="72"
      width="120"
      height="112"
      rx="8"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
    />
    <path
      d="M152,88h32a8,8,0,0,1,8,8v72a56,56,0,0,1-56,56h0a56.1,56.1,0,0,1-53.7-40"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
    />
    <line
      x1="110"
      y1="104"
      x2="74"
      y2="104"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
    />
    <line
      x1="92"
      y1="152"
      x2="92"
      y2="104"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
    />
    <path
      d="M108.3,72A32,32,0,1,1,152,83.7"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
    />
    <path
      d="M166.1,45.2a26,26,0,1,1-3.4,28.4"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
    />
    <path
      d="M192,88h27.3a4.7,4.7,0,0,1,4.7,4.7V152a32,32,0,0,1-32,32h-2.3"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
    />
  </>
));

pathsByWeight.set("thin", (color: string) => (
  <>
    <rect
      x="32"
      y="72"
      width="120"
      height="112"
      rx="8"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="8"
    />
    <path
      d="M152,88h32a8,8,0,0,1,8,8v72a56,56,0,0,1-56,56h0a56.1,56.1,0,0,1-53.7-40"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="8"
    />
    <line
      x1="110"
      y1="104"
      x2="74"
      y2="104"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="8"
    />
    <line
      x1="92"
      y1="152"
      x2="92"
      y2="104"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="8"
    />
    <path
      d="M108.3,72A32,32,0,1,1,152,83.7"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="8"
    />
    <path
      d="M166.1,45.2a26,26,0,1,1-3.4,28.4"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="8"
    />
    <path
      d="M192,88h27.3a4.7,4.7,0,0,1,4.7,4.7V152a32,32,0,0,1-32,32h-2.3"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="8"
    />
  </>
));

pathsByWeight.set("regular", (color: string) => (
  <>
    <rect
      x="32"
      y="72"
      width="120"
      height="112"
      rx="8"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <path
      d="M152,88h32a8,8,0,0,1,8,8v72a56,56,0,0,1-56,56h0a56.1,56.1,0,0,1-53.7-40"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <line
      x1="110"
      y1="104"
      x2="74"
      y2="104"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <line
      x1="92"
      y1="152"
      x2="92"
      y2="104"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <path
      d="M108.3,72A32,32,0,1,1,152,83.7"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <path
      d="M166.1,45.2a26,26,0,1,1-3.4,28.4"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <path
      d="M192,88h27.3a4.7,4.7,0,0,1,4.7,4.7V152a32,32,0,0,1-32,32h-2.3"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
  </>
));

const renderPath: RenderFunction = (weight: IconWeight, color: string) =>
  renderPathForWeight(weight, color, pathsByWeight);

const MicrosoftTeamsLogo = forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => <IconBase ref={ref} {...props} renderPath={renderPath} />
);

MicrosoftTeamsLogo.displayName = "MicrosoftTeamsLogo";

export default MicrosoftTeamsLogo;
