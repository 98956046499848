/* eslint-disable dot-notation */
import axios from "axios";

import { API_REQUEST, apiSuccess, apiError } from "redux/actions/api";
import { TOKEN_STORAGE_KEY, SIGNIN_ROUTE } from "redux/actions/constants";
import { setAuth } from "redux/actions/auth";
import { removeFromStorage } from "redux/actions/storage";
import { clearData } from "redux/actions/data";
import { setRedirect, setLoader } from "redux/actions/ui";

const notAuthenticatedActions = ({ feature, next }) => {
  next(setAuth({ auth: {} }));
  next(
    removeFromStorage({
      storageKey: TOKEN_STORAGE_KEY,
      feature,
    })
  );
  next(setRedirect({ to: SIGNIN_ROUTE, feature }));
  next(clearData());
  next(setLoader({ state: false, feature }));
};

export const apiMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    if (action.type.includes(API_REQUEST)) {
      const { url, method, feature, token } = action.meta;
      let headers = {};

      let bearerToken = "";

      if (token === undefined || token === null || token === "") {
        // try to get token from state
        bearerToken = getState().auth.token;
      } else {
        bearerToken = token;
      }

      if (bearerToken !== undefined && bearerToken !== null && bearerToken !== "") {
        headers["Authorization"] = `Bearer ${bearerToken}`;
      } else {
        delete headers["Authorization"];
      }

      const userInfo = getState().header;

      headers = { ...headers, ...userInfo };

      const axiosInstance = axios.create({
        headers,
        withCredentials: true,
      });

      axiosInstance[method.toLowerCase()](url, action.payload)
        .then((response) => {
          if (response.data.status === "success") {
            dispatch(apiSuccess({ response: response.data.data, feature }));
          } else if (response.data.status === "error") {
            if (response.data.message === "NOT_AUTHENTICATED_MSG") {
              notAuthenticatedActions({ feature, next });
            } else {
              dispatch(apiError({ error: response.data.message, feature }));
            }
          } else {
            // eslint-disable-next-line no-console
            // console.log("Error occured on response:", response);
            dispatch(apiError({ error: "Error occured", feature }));
          }
        })
        .catch((error) => {
          if (error.message.includes("Request failed with status code 401")) {
            notAuthenticatedActions({ feature, next });
          } else {
            dispatch(apiError({ error: error?.message, feature }));
          }
        });
    }
  };
