/* eslint-disable no-plusplus */
import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// import Box from "@mui/material/Box";
import { connect } from "react-redux";
import Swal from "sweetalert2";

import SuiBox from "components/SuiBox";
import DashboardLayout from "custom/LayoutContainers/DashboardLayout";
import Loading from "custom/Loading";
import DashboardNavbar from "custom/Navbars/DashboardNavbar";
import Footer from "custom/Footer";
import requireAuth from "hocs/requireAuth";
import {
  getAllAcademicYearItems,
  getAllAcademicYearDetails,
  getUserGrades,
  GET_ALL_ACADEMIC_YEAR_ITEMS,
  GET_ALL_ACADEMIC_YEAR_DETAILS,
  GET_USER_GRADES,
} from "redux/actions/pages";
import MessageTypes from "enums/message-types";
import { removeMessage } from "redux/actions/message";
import CustomSuiSelect from "custom/CustomSuiSelect";
import AcademicWeekPanel from "./components/AcademicWeekPanel";
// import "./_academic.css";

const Academic = ({
  getAcademicCalendar,
  getAcademicCalendarDetails,
  academicYearItems,
  academicYearDetails,
  getGrades,
  grades,
  apiErrorMsgForAcademicCalendar,
  apiErrorMsgForAcademicYearDetails,
  apiErrorMsgForUserGrades,
  removeApiErrorMsg,
  localizations,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [termOptions, setTermOptions] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [termBasedAcademicYearItems, setTermBasedAcademicYearItems] = useState([]);
  const [selectedWeekDetails, setSelectedWeekDetails] = useState(null);

  const handleChange = (event, newValue) => {
    setSelectedTabIndex(newValue);
  };

  useEffect(() => {
    getAcademicCalendar();
    getGrades();
    getAcademicCalendarDetails();
    setSelectedWeekDetails(null);
  }, []);

  useEffect(() => {
    setSelectedTabIndex(0);
  }, [selectedTerm]);

  useEffect(() => {
    if (academicYearItems.length > 0 && selectedTerm !== null) {
      const items = academicYearItems
        .filter((item) => item.term === selectedTerm?.value?.term)
        .sort((a, b) => a.week - b.week);

      setTermBasedAcademicYearItems(items);
    }
  }, [academicYearItems, selectedTerm]);

  useEffect(() => {
    if (academicYearDetails.length > 0 && termBasedAcademicYearItems.length > 0) {
      const currentWeek = termBasedAcademicYearItems[selectedTabIndex]?.week;
      const weekDetails = academicYearDetails.find((item) => item.week === currentWeek);
      setSelectedWeekDetails(weekDetails);
    }
  }, [academicYearDetails, selectedTabIndex, termBasedAcademicYearItems]);

  useEffect(() => {
    if (grades.length > 0) {
      const terms = [1, 2, 3, 4];
      const gradesAsNumber = [
        ...new Set(grades.map((g) => parseInt(g.classLevel.split("_")[1], 10))),
      ].sort((a, b) => a - b);

      const options = [];

      for (let i = 0; i < gradesAsNumber.length; i++) {
        for (let j = 0; j < terms.length; j++) {
          options.push({
            label: `${gradesAsNumber[i]}. Sınıf ${terms[j]}. Dönem`,
            value: { grade: gradesAsNumber[i], term: terms[j] },
          });
        }
      }

      setTermOptions(options);
    }
  }, [grades]);

  useEffect(() => {
    if (
      apiErrorMsgForAcademicCalendar &&
      apiErrorMsgForAcademicCalendar.messageContent !== undefined &&
      apiErrorMsgForAcademicCalendar.messageContent !== null &&
      apiErrorMsgForAcademicCalendar.messageContent !== ""
    ) {
      Swal.fire({
        icon: apiErrorMsgForAcademicCalendar.messageType || "error",
        title:
          apiErrorMsgForAcademicCalendar.messageType === MessageTypes.success
            ? localizations?.MESSAGE_SUCCESS ?? "BAŞARILI!"
            : localizations?.MESSAGE_ERROR ?? "HATA!",
        text: apiErrorMsgForAcademicCalendar.messageContent,
        showCancelButton: false,
        confirmButtonText: localizations?.MESSAGE_CONFIRM_BTN ?? `Tamam`,
      }).then(() => {
        removeApiErrorMsg(GET_ALL_ACADEMIC_YEAR_ITEMS);
      });
    }
  }, [apiErrorMsgForAcademicCalendar]);

  useEffect(() => {
    if (
      apiErrorMsgForAcademicYearDetails &&
      apiErrorMsgForAcademicYearDetails.messageContent !== undefined &&
      apiErrorMsgForAcademicYearDetails.messageContent !== null &&
      apiErrorMsgForAcademicYearDetails.messageContent !== ""
    ) {
      Swal.fire({
        icon: apiErrorMsgForAcademicYearDetails.messageType || "error",
        title:
          apiErrorMsgForAcademicYearDetails.messageType === MessageTypes.success
            ? localizations?.MESSAGE_SUCCESS ?? "BAŞARILI!"
            : localizations?.MESSAGE_ERROR ?? "HATA!",
        text: apiErrorMsgForAcademicYearDetails.messageContent,
        showCancelButton: false,
        confirmButtonText: localizations?.MESSAGE_CONFIRM_BTN ?? `Tamam`,
      }).then(() => {
        removeApiErrorMsg(GET_ALL_ACADEMIC_YEAR_ITEMS);
      });
    }
  }, [apiErrorMsgForAcademicYearDetails]);

  useEffect(() => {
    if (
      apiErrorMsgForUserGrades &&
      apiErrorMsgForUserGrades.messageContent !== undefined &&
      apiErrorMsgForUserGrades.messageContent !== null &&
      apiErrorMsgForUserGrades.messageContent !== ""
    ) {
      Swal.fire({
        icon: apiErrorMsgForUserGrades.messageType || "error",
        title:
          apiErrorMsgForUserGrades.messageType === MessageTypes.success
            ? localizations?.MESSAGE_SUCCESS ?? "BAŞARILI!"
            : localizations?.MESSAGE_ERROR ?? "HATA!",
        text: apiErrorMsgForUserGrades.messageContent,
        showCancelButton: false,
        confirmButtonText: localizations?.MESSAGE_CONFIRM_BTN ?? `Tamam`,
      }).then(() => {
        removeApiErrorMsg(GET_USER_GRADES);
      });
    }
  }, [apiErrorMsgForUserGrades]);

  useEffect(() => {
    if (termOptions.length > 0) {
      setSelectedTerm(termOptions[0]);
    }
  }, [termOptions]);

  return (
    <>
      <Loading />
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4} style={{ zIndex: 9999 }}>
              <CustomSuiSelect
                onChange={(selected) => setSelectedTerm(selected)}
                value={selectedTerm}
                options={termOptions}
                placeholder="Sınıf ve Dönem Seçiniz"
              />
            </Grid>
            <Grid item xs={12} xl={12}>
              <SuiBox sx={{ display: "flex" }}>
                <SuiBox sx={{ width: "140px" }}>
                  <Tabs
                    orientation="vertical"
                    value={selectedTabIndex}
                    onChange={handleChange}
                    scrollButtons={false}
                    variant="scrollable"
                  >
                    {termBasedAcademicYearItems.map((item, index) => (
                      <Tab
                        key={`tab-${item.id}`}
                        label={`${item.week}. Hafta`}
                        id={`vertical-tab-${index}`}
                        aria-controls={`vertical-tabpanel-${index}`}
                        sx={{
                          cursor: "pointer",
                          height: "50px",
                          width: "132px",
                          border:
                            item.id === termBasedAcademicYearItems[selectedTabIndex].id ? 1 : 0,
                          borderColor:
                            item.id === termBasedAcademicYearItems[selectedTabIndex].id
                              ? "#67BEEC"
                              : "background.paper",
                          borderRadius: 1,
                          backgroundColor:
                            item.id === termBasedAcademicYearItems[selectedTabIndex].id
                              ? "#67BEEC"
                              : "background.paper",
                          color:
                            item.id === termBasedAcademicYearItems[selectedTabIndex].id
                              ? "#FFFFFF !important"
                              : "text.primary",
                        }}
                      />
                    ))}
                  </Tabs>
                </SuiBox>
                <SuiBox sx={{ flex: 1 }}>
                  {selectedWeekDetails && selectedTerm && (
                    <AcademicWeekPanel selectedTerm={selectedTerm} week={selectedWeekDetails} />
                  )}
                </SuiBox>
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>
        <Footer />
      </DashboardLayout>
    </>
  );
};

Academic.defaultProps = {
  academicYearItems: [],
  academicYearDetails: [],
  grades: [],
  apiErrorMsgForAcademicCalendar: null,
  apiErrorMsgForAcademicYearDetails: null,
  apiErrorMsgForUserGrades: null,
  localizations: null,
};

Academic.propTypes = {
  getAcademicCalendar: PropTypes.func.isRequired,
  getAcademicCalendarDetails: PropTypes.func.isRequired,
  getGrades: PropTypes.func.isRequired,
  academicYearItems: PropTypes.arrayOf(PropTypes.object),
  academicYearDetails: PropTypes.arrayOf(PropTypes.object),
  grades: PropTypes.arrayOf(PropTypes.object),
  apiErrorMsgForAcademicCalendar: PropTypes.objectOf(PropTypes.any),
  apiErrorMsgForAcademicYearDetails: PropTypes.objectOf(PropTypes.any),
  apiErrorMsgForUserGrades: PropTypes.objectOf(PropTypes.any),
  localizations: PropTypes.objectOf(PropTypes.any),
  removeApiErrorMsg: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  academicYearItems: state.data.academicYearItems?.length > 0 ? state.data.academicYearItems : [],
  academicYearDetails:
    state.data.academicYearDetails?.length > 0 ? state.data.academicYearDetails : [],
  grades: state.data.userGrades?.length > 0 ? state.data.userGrades : [],
  apiErrorMsgForAcademicCalendar: state.message[GET_ALL_ACADEMIC_YEAR_ITEMS],
  apiErrorMsgForAcademicYearDetails: state.message[GET_ALL_ACADEMIC_YEAR_DETAILS],
  apiErrorMsgForUserGrades: state.message[GET_USER_GRADES],
  localizations: state.localization?.pairs,
});
const mapDispatchToProps = {
  getAcademicCalendar: getAllAcademicYearItems,
  getAcademicCalendarDetails: getAllAcademicYearDetails,
  getGrades: getUserGrades,
  removeApiErrorMsg: removeMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(requireAuth(Academic));
