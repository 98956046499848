import * as React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import "../../_academic.css";
import { connect } from "react-redux";
import DateHelper from "layouts/academic/helpers/DateHelper";
import { iconMapper } from "mappers/icon.mapper";

const AcademicWeekPanel = ({ week, localizations, selectedTerm }) => {
  const history = useHistory();

  if (week === null) {
    return <></>;
  }

  const weekParts = week.weekParts
    .filter((wp) => wp.grade === selectedTerm.value.grade)
    .sort((a, b) => a.seq - b.seq);

  return (
    <div className="academicWeekPanel" sx={{ maxWidth: "100%", width: "100%" }}>
      <table aria-label="custom pagination table">
        <tbody style={{ width: "100%" }}>
          <tr className="academicRow">
            <td className="academicHeader">HAFTA</td>
            <td className="academicCell" align="right" colSpan={weekParts?.length ?? 1}>
              {`${week.week}. Hafta (${DateHelper.transformDateToLocale(
                week.beginDate
              )} - ${DateHelper.transformDateToLocale(week.endDate)})`}
            </td>
          </tr>

          <tr className="academicRow">
            <td className="academicHeader">DERS SAATİ</td>
            {weekParts?.map((weekPart) => (
              <td className="academicCell" key={`sessionCount${weekPart.seq}`} align="right">
                {weekPart.sessionCount}
              </td>
            ))}
          </tr>

          <tr className="academicRow">
            <td className="academicHeader">İÇERİK ÇERÇEVESİ</td>
            {weekParts?.map((weekPart) => (
              <td className="academicCell" key={`contentFrame${weekPart.seq}`} align="right">
                {weekPart.contentFrame}
              </td>
            ))}
          </tr>

          <tr className="academicRow">
            <td className="academicHeader">ÖĞRENİM ÇIKTILARI</td>
            {weekParts?.map((weekPart) => (
              <td className="academicCell" key={`outputs${weekPart.seq}`} align="right">
                {weekPart.outputs
                  .sort((a, b) => a.seq - b.seq)
                  .map((output) => (
                    <div
                      className="academicCalendarOutput"
                      key={`output${weekPart.seq}${output.seq}`}
                    >
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault();
                          history.push({
                            pathname: "/all-books",
                            state: { filterObjective: output.objective },
                          });
                        }}
                        aria-hidden="true"
                      >
                        {`${
                          localizations[output.objective] ?? output.objective.replaceAll("_", ".")
                        }`}
                      </p>
                      <ol type="a">
                        {output.suboutputs
                          .sort((a, b) => a.seq - b.seq)
                          .map((suboutput) => (
                            <li key={`suboutput${weekPart.seq}${output.seq}${suboutput.seq}`}>
                              {`${suboutput.text}`}
                            </li>
                          ))}
                      </ol>
                    </div>
                  ))}
              </td>
            ))}
          </tr>

          <tr className="academicRow">
            <td className="academicHeader">ANAHTAR KAVRAMLAR</td>
            {weekParts?.map((weekPart) => (
              <td className="academicCell" key={`keyConcepts${weekPart.seq}`} align="right">
                {weekPart.keyConcepts}
              </td>
            ))}
          </tr>

          <tr className="academicRow">
            <td className="academicHeader">ÖĞRENME – ÖĞRETME UYGULAMALARI</td>
            {weekParts?.map((weekPart) => (
              <td className="academicCell" key={`learningActivities${weekPart.seq}`} align="right">
                {weekPart.learningActivities
                  .sort((a, b) => a.seq - b.seq)
                  .map((learningActivity) => (
                    <div
                      className="academicCalendarLearningActivity"
                      key={`learningActivity${weekPart.seq}${learningActivity.seq}`}
                    >
                      <p className="academicLearningActivityTitle">{`${learningActivity.title}`}</p>
                      <p>{`${learningActivity.text}`}</p>
                    </div>
                  ))}
              </td>
            ))}
          </tr>

          <tr className="academicRow">
            <td className="academicHeader">ARAÇ VE GEREÇLER</td>
            {weekParts?.map((weekPart) => (
              <td className="academicCell" key={`tools${weekPart.seq}`} align="right">
                {weekPart.tools
                  .sort((a, b) => a.seq - b.seq)
                  .map((tool) => (
                    <a
                      className="academicCalendarToolsLink"
                      href={tool.link}
                      target="_blank"
                      rel="noreferrer"
                      key={`tool${weekPart.seq}${tool.seq}`}
                    >
                      <div className="academicCalendarTools">
                        {tool.icon &&
                          iconMapper[tool.icon] &&
                          iconMapper[tool.icon]({ weight: "duotone", size: 24 })}
                        <span style={{ marginLeft: "4px" }}>{`${tool.text}`}</span>
                      </div>
                    </a>
                  ))}
              </td>
            ))}
          </tr>

          <tr className="academicRow">
            <td className="academicHeader">ÖĞRENME KANITLARI (ÖLÇME ve DEĞERLENDİRME)</td>
            {weekParts?.map((weekPart) => (
              <td className="academicCell" key={`assessments${weekPart.seq}`} align="right">
                {weekPart.assessments
                  .sort((a, b) => a.seq - b.seq)
                  .map((assessment) => (
                    <p
                      key={`assessment${weekPart.seq}${assessment.seq}`}
                      style={{ marginBottom: "4px" }}
                    >
                      {assessment.seq}- {assessment.text}
                    </p>
                  ))}
              </td>
            ))}
          </tr>

          <tr className="academicRow">
            <td className="academicHeader">FARKLILAŞTIRMA, DİSİPLİNLER ARASI İLİŞKİLER</td>
            {weekParts?.map((weekPart) => (
              <td className="academicCell" key={`interdisciplinary${weekPart.seq}`} align="right">
                {weekPart.interdisciplinary
                  .sort((a, b) => a.seq - b.seq)
                  .map((disciplinary) => (
                    <div
                      className="academicCalendarInterdisciplinary"
                      key={`interdisciplinary${weekPart.seq}${disciplinary.seq}`}
                    >
                      <p className="academicInterdisciplinaryTitle">{`${disciplinary.title}`}</p>
                      <p>{`${disciplinary.text}`}</p>
                    </div>
                  ))}
              </td>
            ))}
          </tr>

          <tr className="academicRow">
            <td className="academicHeader">ATATÜRKÇÜLÜK BELİRLİ GÜN VE HAFTALAR</td>
            {weekParts?.map((weekPart) => (
              <td className="academicCell" key={`specialDays${weekPart.seq}`} align="right">
                {weekPart.specialDays
                  .sort((a, b) => a.seq - b.seq)
                  .map((specialDay) => (
                    <p
                      key={`specialDay${weekPart.seq}${specialDay.seq}`}
                      style={{ marginBottom: "4px" }}
                    >
                      {specialDay.text}
                    </p>
                  ))}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

AcademicWeekPanel.defaultProps = {
  week: null,
  localizations: null,
  selectedTerm: null,
};

AcademicWeekPanel.propTypes = {
  selectedTerm: PropTypes.objectOf(PropTypes.any),
  week: PropTypes.shape({
    id: PropTypes.number,
    beginDate: PropTypes.string,
    endDate: PropTypes.string,
    term: PropTypes.number,
    week: PropTypes.number,
    weekParts: PropTypes.arrayOf(
      PropTypes.shape({
        assessments: PropTypes.arrayOf(PropTypes.any),
        contentFrame: PropTypes.string,
        interdisciplinary: PropTypes.arrayOf(PropTypes.any),
        keyConcepts: PropTypes.string,
        learningActivities: PropTypes.arrayOf(PropTypes.any),
        outputs: PropTypes.arrayOf(PropTypes.any),
        pdfUrl: PropTypes.string,
        seq: PropTypes.number,
        sessionCount: PropTypes.number,
        specialDays: PropTypes.arrayOf(PropTypes.any),
        tools: PropTypes.arrayOf(PropTypes.any),
        grade: PropTypes.number,
      })
    ),
  }),
  localizations: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AcademicWeekPanel);
