/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Dialog, Tooltip } from "@mui/material";
import { Pause, Play } from "phosphor-react";
import { Howl } from "howler";
import { getQuizDetail } from "redux/actions/pages";
import "./_quiz-preview-dialog.css";

const QuizPreviewDialog = ({ open, onClose, quizId, previewQuiz, localizations, getQuiz }) => {
  const [selectedQuestionId, setSelectedQuestionId] = useState(0);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [audio, setAudio] = useState(null);
  const [currentAudioId, setCurrentAudioId] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);

  useEffect(() => {
    if (quizId > 0) {
      getQuiz({ quizId });
    }
  }, [quizId]);

  useEffect(() => {
    if (previewQuiz && previewQuiz.questions.length > 0) {
      setSelectedQuestionId(previewQuiz.questions[0].id);
    } else {
      setSelectedQuestionId(0);
    }
  }, [previewQuiz]);

  useEffect(() => {
    if (selectedQuestionId > 0) {
      setSelectedQuestion(previewQuiz?.questions?.find((q) => q.id === selectedQuestionId) ?? null);
    } else {
      setSelectedQuestion(null);
    }
  }, [selectedQuestionId]);

  useEffect(() => {
    // If selected question changed, unload and reset about audio
    audio?.unload();
    setIsPlaying(false);
    setCurrentAudioId("");
    setAudio(null);
  }, [selectedQuestion]);

  useEffect(() => {
    if (isPlaying) {
      audio?.play();
    } else {
      audio?.pause();
    }
  }, [audio, isPlaying]);

  const onPlayClick = (audioId, url) => {
    if (audio && currentAudioId === audioId) {
      setIsPlaying(true);
    } else {
      // Varolanı Kaldır!
      audio?.unload();
      setIsPlaying(false);
      setCurrentAudioId("");
      setAudio(null);

      // Yeniden oluştur!
      const sound = new Howl({
        src: url,
      });

      sound.on("end", () => {
        audio?.unload();
        setIsPlaying(false);
        setCurrentAudioId("");
        setAudio(null);
      });

      setAudio(sound);
      setCurrentAudioId(audioId);
      setIsPlaying(true);
    }
  };

  if (previewQuiz !== null && previewQuiz !== undefined && previewQuiz?.id === quizId) {
    return (
      <Dialog scroll="body" fullWidth maxWidth="lg" onClose={onClose} open={open}>
        <div className="dialog-container">
          <div className="dialog-title">{`${localizations?.QUIZ ?? "Değerlendirme"} - ${
            localizations[previewQuiz?.type] ?? previewQuiz?.type
          }`}</div>
          <div className="dialog-tabs">
            {previewQuiz?.questions
              ?.sort((a, b) => a.questionOrder - b.questionOrder)
              ?.map((question) => (
                <div
                  key={question.id}
                  className={`dialog-tab-item ${
                    question.id === selectedQuestionId ? "active" : ""
                  }`}
                  aria-hidden
                  onClick={() => {
                    if (selectedQuestionId !== question.id) {
                      setSelectedQuestionId(question.id);
                      setShowAnswer(false);
                    }
                  }}
                >
                  <p>{question.questionOrder}</p>
                </div>
              ))}
          </div>
          {selectedQuestion !== null ? (
            <div className="dialog-question-wrapper">
              <div className="dialog-question-container">
                {selectedQuestion.audioUrl && selectedQuestion.audioUrl !== "" ? (
                  <>
                    {!isPlaying || currentAudioId !== `Question-${selectedQuestion.id}` ? (
                      <Tooltip title={localizations?.PLAY_TOOLTIP ?? "Oynat"} placement="top">
                        <div
                          className="dialog-play-btn"
                          aria-hidden
                          onClick={(event) => {
                            event.preventDefault();
                            onPlayClick(
                              `Question-${selectedQuestion.id}`,
                              selectedQuestion.audioUrl
                            );
                          }}
                        >
                          <Play color="#67BCEC" size={30} weight="duotone" />
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip title={localizations?.PAUSE_TOOLTIP ?? "Durdur"} placement="top">
                        <div
                          className="dialog-play-btn"
                          aria-hidden
                          onClick={(event) => {
                            event.preventDefault();
                            if (currentAudioId === `Question-${selectedQuestion.id}`) {
                              setIsPlaying(false);
                            }
                          }}
                        >
                          <Pause color="#67BCEC" size={30} weight="duotone" />
                        </div>
                      </Tooltip>
                    )}
                  </>
                ) : null}
                <div className="dialog-text-container">
                  <div className="dialog-question-title">
                    <p>{`${localizations?.QUESTION ?? "Soru"} ${
                      selectedQuestion.questionOrder
                    }`}</p>
                  </div>
                  <p className="dialog-question-text">{selectedQuestion.text}</p>
                </div>
                {selectedQuestion.imageUrl && selectedQuestion.imageUrl !== "" ? (
                  <div className="dialog-question-image">
                    <img src={selectedQuestion.imageUrl} alt={selectedQuestion.text} />
                  </div>
                ) : null}
              </div>
              {selectedQuestion.choices && selectedQuestion.choices.length > 0 ? (
                <div className="dialog-question-choices">
                  {selectedQuestion.choices
                    .sort((a, b) => a.choiceOrder - b.choiceOrder)
                    .map((ch, i) => (
                      <div
                        key={ch.id}
                        className={`dialog-question-choice-item ${
                          ch.isCorrectAnswer && showAnswer ? "active-choice" : ""
                        } ${
                          (!ch.text || ch.text === "") && ch.imageUrl && ch.imageUrl !== ""
                            ? "only-image"
                            : ""
                        }`}
                      >
                        <div className="dialog-choice-item-char">{["A", "B", "C", "D"][i]}</div>
                        {ch.audioUrl && ch.audioUrl !== "" ? (
                          <>
                            {!isPlaying || currentAudioId !== `Choice-${ch.id}` ? (
                              <Tooltip
                                title={localizations?.PLAY_TOOLTIP ?? "Oynat"}
                                placement="right"
                              >
                                <div
                                  className="dialog-play-btn"
                                  aria-hidden
                                  onClick={(event) => {
                                    event.preventDefault();
                                    onPlayClick(`Choice-${ch.id}`, ch.audioUrl);
                                  }}
                                >
                                  <Play color="#67BCEC" size={30} weight="duotone" />
                                </div>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title={localizations?.PAUSE_TOOLTIP ?? "Durdur"}
                                placement="top"
                              >
                                <div
                                  className="dialog-play-btn"
                                  aria-hidden
                                  onClick={(event) => {
                                    event.preventDefault();
                                    if (currentAudioId === `Choice-${ch.id}`) {
                                      setIsPlaying(false);
                                    }
                                  }}
                                >
                                  <Pause color="#67BCEC" size={30} weight="duotone" />
                                </div>
                              </Tooltip>
                            )}
                          </>
                        ) : null}
                        {ch.text && ch.text !== "" ? (
                          <div className="dialog-choice-item-text">{ch.text}</div>
                        ) : null}
                        {ch.imageUrl && ch.imageUrl !== "" ? (
                          <div className="dialog-choice-item-image">
                            <img src={ch.imageUrl} alt={["A", "B", "C", "D"][i]} />
                          </div>
                        ) : null}
                      </div>
                    ))}
                </div>
              ) : null}
            </div>
          ) : null}
          <div className="dialog-close">
            <div
              className="quiz-preview-dialog-btn"
              aria-hidden
              onClick={(_event) => setShowAnswer(!showAnswer)}
            >
              {showAnswer
                ? localizations?.HIDE_ANSWER_BTN_TEXT ?? "Cevabı Gizle"
                : localizations?.SHOW_ANSWER_BTN_TEXT ?? "Cevabı Göster"}
            </div>
            <div className="quiz-preview-dialog-btn" aria-hidden onClick={(_event) => onClose()}>
              {localizations?.CLOSE_BTN ?? "KAPAT"}
            </div>
          </div>
        </div>
      </Dialog>
    );
  }

  return <></>;
};

QuizPreviewDialog.defaultProps = {
  previewQuiz: null,
  localizations: null,
  quizId: null,
};

QuizPreviewDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  previewQuiz: PropTypes.objectOf(PropTypes.any),
  localizations: PropTypes.objectOf(PropTypes.any),
  quizId: PropTypes.number,
  getQuiz: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
  previewQuiz: state.data.previewQuiz,
});

const mapDispatchToProps = {
  getQuiz: getQuizDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(QuizPreviewDialog));
