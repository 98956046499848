import React from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Checkbox, Tooltip } from "@mui/material";
import { connect } from "react-redux";

// eslint-disable-next-line no-unused-vars
const FilterCheckBox = ({ disabled, checked, onChange, label, localizedLabel, width }) => (
  <FormControlLabel
    control={
      <Checkbox
        size="small"
        color="info"
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
    }
    label={
      <Tooltip title={localizedLabel} placement="right">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <p
            style={{
              display: "inline-block",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: `${width}px`,
            }}
          >
            {localizedLabel}
          </p>
        </div>
      </Tooltip>
    }
  />
);

FilterCheckBox.defaultProps = {
  disabled: false,
  width: 100,
};

FilterCheckBox.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  localizedLabel: PropTypes.string.isRequired,
  width: PropTypes.number,
};

const mapStateToProps = (state, ownProps) => ({
  localizedLabel: state.localization?.pairs
    ? state.localization?.pairs[ownProps.label] ?? ownProps.label
    : ownProps.label,
});

export default connect(mapStateToProps, null)(FilterCheckBox);
