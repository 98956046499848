import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import SuiTypography from "components/SuiTypography";
import { COURSES } from "redux/actions/constants";
import FilterCheckBox from "../FilterCheckBox";

const CourseFilter = ({ lookups, localizations, filters, setFilters, width }) => (
  <>
    <SuiTypography
      component="a"
      variant="button"
      fontWeight="bold"
      color="inherit"
      textTransform="capitalize"
    >
      {localizations?.COURSE_FILTER_HEADER ?? "DERS"}
    </SuiTypography>
    <ul style={{ listStyle: "none", marginLeft: "20px" }}>
      {lookups.l_courses?.lookupValues
        ?.filter(
          (lv) =>
            filters.grades.includes(lv.parentValueKey) ||
            (filters.grades.length > 0 && lv.parentValueKey === null)
        )
        ?.map((lv) => (
          <li key={`course-lookupValue-${lv.id}`}>
            <FilterCheckBox
              width={width}
              checked={filters.course === lv.lookupValueKey}
              onChange={(e) => {
                // Eğer course işaretlenir ya da işareti kaldırılırsa;
                // Beceri, ünite, özel gün, kazanım filtreleri sıfırlanır.
                if (e.target.checked) {
                  setFilters({
                    ...filters,
                    course: lv.lookupValueKey,
                    units: [],
                    skills: [],
                    specialDays: [],
                    objectives: [],
                  });
                } else {
                  setFilters({
                    ...filters,
                    course: "",
                    units: [],
                    skills: [],
                    specialDays: [],
                    objectives: [],
                  });
                }
              }}
              label={lv.lookupValueKey}
            />
          </li>
        ))}
    </ul>
  </>
);

CourseFilter.defaultProps = {
  localizations: null,
  lookups: {},
  width: 100,
};

CourseFilter.propTypes = {
  localizations: PropTypes.objectOf(PropTypes.any),
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  setFilters: PropTypes.func.isRequired,
  lookups: PropTypes.objectOf(PropTypes.any),
  width: PropTypes.number,
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
  lookups:
    state.data.lookups && Object.keys(state.data.lookups).length !== 0
      ? {
          l_courses: state.data.lookups[COURSES],
        }
      : {},
});

export default connect(mapStateToProps, null)(CourseFilter);
