export default function sidenavDropdown(theme) {
  const { functions, borders } = theme;
  // const { miniSidenav } = ownerState;

  const { pxToRem } = functions;
  const { borderRadius } = borders;

  return {
    padding: `${pxToRem(10.8)} ${pxToRem(4.0)} ${pxToRem(10.8)} ${pxToRem(16)}`,
    margin: `0 ${pxToRem(16)}`,
    borderRadius: borderRadius.md,
  };
}
