import { PAGES } from "redux/actions/pages/constants";

// feature name
export const HOME = `${PAGES} [Home]`;

// action types
export const START_HOME_PAGE = `${HOME} START`;
export const GET_HOME_ASSIGNMENT_DETAILS = `${HOME} GET_HOME_ASSIGNMENT_DETAILS`;
export const CHANGE_PLAYING_AUDIO_URL = `${HOME} SET_PLAYING_AUDIO_URL`;

// action creators
export const startHomePage = () => ({
  type: START_HOME_PAGE,
});

export const getHomeAssignmentDetails = ({ assignmentId }) => ({
  type: GET_HOME_ASSIGNMENT_DETAILS,
  payload: { assignmentId },
});

export const changePlayingAudioUrl = (audioUrl) => ({
  type: CHANGE_PLAYING_AUDIO_URL,
  payload: { audioUrl },
});
