import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import { regeneratePassword } from "redux/actions/pages";

const GeneratePasswordCell = ({ data, generatePassword }) => (
  <SuiBox mr={1}>
    <SuiButton circular color="info" onClick={() => generatePassword({ studentId: data.id })}>
      Şifre Oluştur
    </SuiButton>
  </SuiBox>
);

GeneratePasswordCell.defaultProps = {};

GeneratePasswordCell.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  generatePassword: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  generatePassword: regeneratePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneratePasswordCell);
