import { SET_LOCALIZATION } from "redux/actions/localization";

const initState = {};

export const localizationReducer = (localization = initState, action) => {
  switch (action.type) {
    case SET_LOCALIZATION:
      return action.payload;
    default:
      return localization;
  }
};
