import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, Grid } from "@mui/material";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { updateMyProfile } from "redux/actions/pages";
import { connect } from "react-redux";

const ProfileInfo = ({ profile, localizations, updateProfile, loading }) => {
  const [linkedin, setLinkedin] = useState(profile.linkedin);

  return (
    <Card sx={{ height: "100%" }}>
      <SuiBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {localizations?.PROFILE_INFO_HEADER ?? "Profil Bilgileri"}
        </SuiTypography>
      </SuiBox>
      <SuiBox p={2}>
        <Grid container spacing={2} lineHeight={1}>
          <Grid item xs={3} xl={3}>
            <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
              {localizations?.PROFILE_NAME ?? "İsim"}
            </SuiTypography>
          </Grid>
          <Grid item xs={9} xl={9}>
            <SuiTypography variant="button" fontWeight="regular" color="text">
              {`${profile.name} ${profile.surname}`}
            </SuiTypography>
          </Grid>
          <Grid item xs={3} xl={3}>
            <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
              {localizations?.PROFILE_NICKNAME ?? "Hitap"}
            </SuiTypography>
          </Grid>
          <Grid item xs={9} xl={9}>
            <SuiTypography variant="button" fontWeight="regular" color="text">
              {profile.nickName}
            </SuiTypography>
          </Grid>
          <Grid item xs={3} xl={3}>
            <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
              {localizations?.PROFILE_EMAIL ?? "Eposta"}
            </SuiTypography>
          </Grid>
          <Grid item xs={9} xl={9}>
            <SuiTypography variant="button" fontWeight="regular" color="text">
              {profile.email}
            </SuiTypography>
          </Grid>
          <Grid item xs={3} xl={3}>
            <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
              {localizations?.PROFILE_ORGANIZATION ?? "Okul"}
            </SuiTypography>
          </Grid>
          <Grid item xs={9} xl={9}>
            <SuiTypography variant="button" fontWeight="regular" color="text">
              {localizations?.[profile?.organizationKey] ?? ""}
            </SuiTypography>
          </Grid>
          <Grid item xs={3} xl={3}>
            <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
              {localizations?.PROFILE_SUBORGANIZATIONS ?? "Şubeler"}
            </SuiTypography>
          </Grid>
          <Grid item xs={9} xl={9}>
            <SuiTypography variant="button" fontWeight="regular" color="text">
              {profile?.subOrganizationKeys?.map((so) => localizations[so]).join(", ")}
            </SuiTypography>
          </Grid>
          <Grid item xs={3} xl={3}>
            <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
              {localizations?.PROFILE_CLASSNAMES ?? "Sınıflar"}
            </SuiTypography>
          </Grid>
          <Grid item xs={9} xl={9}>
            <SuiTypography variant="button" fontWeight="regular" color="text">
              {profile?.classNames.join(", ")}
            </SuiTypography>
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox p={2}>
        {/* <SuiBox display="flex" py={1} pr={2}>
          <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
            {localizations?.PROFILE_NAME ?? "İsim"}: &nbsp;
          </SuiTypography>
          <SuiTypography variant="button" fontWeight="regular" color="text">
            &nbsp;{`${profile.name} ${profile.surname}`}
          </SuiTypography>
        </SuiBox> */}

        {/* <SuiBox display="flex" py={1} pr={2}>
          <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
            {localizations?.PROFILE_NICKNAME ?? "Hitap"}: &nbsp;
          </SuiTypography>
          <SuiTypography variant="button" fontWeight="regular" color="text">
            &nbsp;{profile.nickName}
          </SuiTypography>
        </SuiBox> */}

        {/* <SuiBox display="flex" py={1} pr={2}>
          <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
            {localizations?.PROFILE_EMAIL ?? "Eposta"}: &nbsp;
          </SuiTypography>
          <SuiTypography variant="button" fontWeight="regular" color="text">
            &nbsp;{profile.email}
          </SuiTypography>
        </SuiBox> */}

        {/* <SuiBox display="flex" py={1} pr={2}>
          <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
            {localizations?.PROFILE_ORGANIZATION ?? "Okul"}: &nbsp;
          </SuiTypography>
          <SuiTypography variant="button" fontWeight="regular" color="text">
            &nbsp;{localizations?.[profile?.organizationKey] ?? ""}
          </SuiTypography>
        </SuiBox> */}

        {/* <SuiBox display="flex" py={1} pr={2}>
          <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
            {localizations?.PROFILE_SUBORGANIZATIONS ?? "Şubeler"}: &nbsp;
          </SuiTypography>
          <SuiTypography variant="button" fontWeight="regular" color="text">
            &nbsp;{profile?.subOrganizationKeys?.map((so) => localizations[so]).join(", ")}
          </SuiTypography>
        </SuiBox> */}

        {/* <SuiBox display="flex" py={1} pr={2}>
          <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
            {localizations?.PROFILE_CLASSNAMES ?? "Sınıflar"}: &nbsp;
          </SuiTypography>
          <SuiTypography variant="button" fontWeight="regular" color="text">
            &nbsp;{profile?.classNames.join(", ")}
          </SuiTypography>
        </SuiBox> */}

        <SuiBox display="flex">
          <SuiBox
            display="flex"
            py={1}
            pr={2}
            width="100%"
            flexDirection="column"
            justifyContent="flex-end"
            height="100%"
          >
            <SuiBox mb={1} lineHeight={0} display="inline-block">
              <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
                {localizations?.PROFILE_LINKEDIN ?? "LinkedIn Hesabı"}: &nbsp;
              </SuiTypography>
            </SuiBox>
            <SuiBox display="flex" alignItems="center">
              <SuiInput
                placeholder={
                  localizations?.PROFILE_LINKEDIN_PLACEHOLDER ??
                  "Kullanıcı adınız veya profil linkiniz"
                }
                inputProps={{
                  type: "text",
                  autoComplete: "",
                  value: linkedin,
                  // eslint-disable-next-line no-console
                  onChange: (e) => setLinkedin(e.target.value),
                }}
              />
              <SuiButton
                variant="text"
                disabled={loading || linkedin === profile.linkedin}
                style={{
                  color: "rgba(103, 116, 142, 0.87)",
                  textTransform: "none",
                  marginLeft: "5px",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  updateProfile({ updatedFields: { linkedin } });
                }}
              >
                {localizations?.PROFILE_SAVE_BTN ?? "Kaydet"}
              </SuiButton>
            </SuiBox>
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </Card>
  );
};

ProfileInfo.defaultProps = {
  localizations: null,
  loading: false,
};

ProfileInfo.propTypes = {
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
  localizations: PropTypes.objectOf(PropTypes.any),
  updateProfile: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
  loading: state.ui?.loading?.GENERAL_LOADING,
});

const mapDispatchToProps = {
  updateProfile: updateMyProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfo);
