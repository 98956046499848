export const monthMapper = [
  { index: "09", text: "Eylül" },
  { index: "10", text: "Ekim" },
  { index: "11", text: "Kasım" },
  { index: "12", text: "Aralık" },
  { index: "01", text: "Ocak" },
  { index: "02", text: "Şubat" },
  { index: "03", text: "Mart" },
  { index: "04", text: "Nisan" },
  { index: "05", text: "Mayıs" },
  { index: "06", text: "Haziran" },
  { index: "07", text: "Temmuz" },
  { index: "08", text: "Ağustos" },
];

export const startOfAcademicYear = "01.09";

export const endOfAcademicYear = "31.08";

export const getAcademicStartDate = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  if (currentDate.getMonth() + 1 >= parseInt(monthMapper[0].index, 10)) {
    return `${startOfAcademicYear}.${currentYear}`;
  }

  return `${startOfAcademicYear}.${currentYear - 1}`;
};

export const getAcademicEndDate = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  if (currentDate.getMonth() + 1 >= parseInt(monthMapper[0].index, 10)) {
    return `${endOfAcademicYear}.${currentYear + 1}`;
  }

  return `${endOfAcademicYear}.${currentYear}`;
};

export const calculateMinOfStartDate = getAcademicStartDate;

export const calculateMaxOfStartDate = (endDate) => {
  if (endDate !== null && endDate !== "") {
    return endDate;
  }

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  if (currentDate.getMonth() + 1 >= parseInt(monthMapper[0].index, 10)) {
    return `${endOfAcademicYear}.${currentYear + 1}`;
  }

  return `${endOfAcademicYear}.${currentYear}`;
};

export const calculateMinOfEndDate = (startDate) => {
  if (startDate !== null && startDate !== "") {
    return startDate;
  }

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  if (currentDate.getMonth() + 1 >= parseInt(monthMapper[0].index, 10)) {
    return `${startOfAcademicYear}.${currentYear}`;
  }

  return `${startOfAcademicYear}.${currentYear + 1}`;
};

export const calculateMaxOfEndDate = getAcademicEndDate;
