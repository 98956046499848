// general loading identifier
export const GENERAL_LOADING_IDENTIFIER = "GENERAL_LOADING";

// action types
export const SET_REDIRECT = "SET_REDIRECT";
export const SET_LOADER = "SET_LOADER";
export const SET_ORIGIN = "SET_ORIGIN";

// action creators
export const setRedirect = ({ to, feature }) => ({
  type: `${feature} ${SET_REDIRECT}`,
  payload: to,
  meta: { feature },
});

export const setLoader = ({ state, feature, identifier }) => ({
  type: `${feature} ${SET_LOADER}`,
  payload: state,
  meta: { identifier: identifier ?? GENERAL_LOADING_IDENTIFIER },
});

export const setOrigin = ({ origin, feature }) => ({
  type: `${feature} ${SET_ORIGIN}`,
  payload: origin,
  meta: { feature },
});
