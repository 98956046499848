import { PAGES } from "redux/actions/pages";

// feature name
export const CREATE_PASSWORD_PAGE = `${PAGES} [CreatePassword]`;

// action types
export const START_CREATE_PASSWORD_PAGE = `${CREATE_PASSWORD_PAGE} START`;
export const VALIDATE_EMAIL_TOKEN = `${CREATE_PASSWORD_PAGE} VALIDATE_EMAIL_TOKEN`;
export const RESET_PASSWORD = `${CREATE_PASSWORD_PAGE} RESET_PASSWORD`;

// action creators
export const startCreatePasswordPage = () => ({
  type: START_CREATE_PASSWORD_PAGE,
});

export const validateEmailToken = ({ token }) => ({
  type: VALIDATE_EMAIL_TOKEN,
  payload: { token },
});

export const resetPassword = ({ password, password2, token }) => ({
  type: RESET_PASSWORD,
  payload: { password, password2, token },
});
