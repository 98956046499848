/* eslint-disable no-case-declarations */
import {
  SET_DATA,
  PUT_DATA,
  UPDATE_ITEM_DATA,
  UPDATE_ITEM_WITH_ITEM,
  REMOVE_DATA,
  UPDATE_ITEM_OF_ARRAY_OF_OBJECT,
  CLEAR_DATA,
  APPEND_DATA_TO_OBJECT_ITEM,
  MANIPULATE_DATA,
} from "redux/actions/data";
import { StateHelper } from "helpers/state.helper";

const initState = {};

export const dataReducer = (data = initState, action) => {
  switch (true) {
    case action.type.includes(SET_DATA):
      // Data içerisine action.payload içeriğini aynen kopyalıyor.
      return { ...data, ...action.payload };

    case action.type.includes(PUT_DATA):
      // data.books içerisinde action.payload.data = { 4: { id: 4, title: "lorem", ... }}
      // koymak için kullanılır.
      // where = books
      return {
        ...data,
        [action.payload.where]: {
          ...data[action.payload.where],
          ...action.payload.data,
        },
      };

    case action.type.includes(UPDATE_ITEM_DATA):
      // data.books.4.isPublished = false yapmak için aşağıdaki kullanılır.
      // where = books, item = 4, key = isPublished, value = false
      return {
        ...data,
        [action.payload.where]: {
          ...data[action.payload.where],
          [action.payload.item]: {
            ...data[action.payload.where][action.payload.item],
            [action.payload.key]: action.payload.value,
          },
        },
      };

    case action.type.includes(UPDATE_ITEM_WITH_ITEM):
      return {
        ...data,
        [action.payload.where]: {
          ...data[action.payload.where],
          [action.payload.itemLocation]: {
            ...data[action.payload.where][action.payload.itemLocation],
            ...action.payload.itemValues,
          },
        },
      };

    // data.books.18.pages array'ı içerisine
    // action.payload.data = { id: 3, pageNumber: 2 ...} koyar.
    // where = books, item = 18, key = pages, id = 3, data = { id: 3, pageNumber: 2 ...}
    case action.type.includes(UPDATE_ITEM_OF_ARRAY_OF_OBJECT):
      if (data[action.payload.where]) {
        if (data[action.payload.where][action.payload.item]) {
          let filteredList = [];
          if (data[action.payload.where][action.payload.item][action.payload.key]) {
            filteredList = data[action.payload.where][action.payload.item][
              action.payload.key
            ].filter((item) => item.id !== action.payload.id);
          }
          filteredList = [...filteredList, action.payload.data];
          return {
            ...data,
            [action.payload.where]: {
              ...data[action.payload.where],
              [action.payload.item]: {
                ...data[action.payload.where][action.payload.item],
                // eslint-disable-next-line prettier/prettier
                [action.payload.key]: filteredList,
              },
            },
          };
        }
      }
      return data;

    case action.type.includes(REMOVE_DATA):
      // eslint-disable-next-line no-case-declarations
      const copyOfData = { ...data };
      delete copyOfData[action.payload.where];
      return copyOfData;

    case action.type.includes(CLEAR_DATA):
      return initState;

    case action.type.includes(APPEND_DATA_TO_OBJECT_ITEM):
      return {
        ...data,
        [action.payload.where]: {
          ...data[action.payload.where],
          [action.payload.item]: {
            ...data[action.payload.where][action.payload.item],
            ...action.payload.data,
          },
        },
      };

    case action.type.includes(MANIPULATE_DATA):
      const { operation, finder, value } = action.payload;
      const newData = StateHelper.stateManipulator(data, operation, finder, value);
      return newData;

    default:
      return data;
  }
};
