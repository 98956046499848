/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// @mui material components
import { Table as MuiTable } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
// import SuiAvatar from "components/SuiAvatar";
import SuiCover from "components/SuiCover";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";

function CollapsibleInnerTable({ columns, rows, innerHeader }) {
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;

  const renderColumns = columns.map(({ name, align, width, title }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SuiBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {title}
      </SuiBox>
    );
  });

  const renderRows = rows.map((row, key) => {
    const rowKey = `row-${key}`;

    const tableRow = columns.map(({ name, align, fontWeight }) => {
      let template;

      if (Array.isArray(row[name])) {
        template = (
          <SuiBox
            key={uuidv4()}
            component="td"
            p={1}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
          >
            <SuiBox display="flex" alignItems="center" py={0.5} px={1}>
              <SuiBox mr={2}>
                <SuiCover src={row[name][0]} name={row[name][1]} variant="square" size="sm" />
              </SuiBox>
              <SuiTypography
                variant="button"
                fontWeight={fontWeight ?? "regular"}
                sx={{ width: "max-content" }}
              >
                {row[name][1]}
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        );
      } else if (typeof row[name] === "string") {
        template = (
          <SuiBox
            key={uuidv4()}
            component="td"
            p={1}
            textAlign={align}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
          >
            <SuiTypography
              variant="button"
              fontWeight={fontWeight ?? "regular"}
              color="secondary"
              sx={{ display: "inline-block", width: "max-content" }}
            >
              {row[name]}
            </SuiTypography>
          </SuiBox>
        );
      } else {
        template = (
          <SuiBox
            key={uuidv4()}
            component="td"
            p={1}
            textAlign={align}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
          >
            <SuiBox
              display="flex"
              justifyContent={align === "left" ? "start" : align}
              alignItems="center"
              py={0.5}
            >
              {row[name]}
            </SuiBox>
          </SuiBox>
        );
      }

      return template;
    });

    return <TableRow key={rowKey}>{tableRow}</TableRow>;
  });

  return useMemo(
    () => (
      <>
        {innerHeader && innerHeader !== "" ? (
          <SuiTypography pl={3} pt={3} variant="h6" fontWeight="bold">
            {innerHeader}
          </SuiTypography>
        ) : null}
        <TableContainer style={{ borderRadius: "0", boxShadow: "none" }}>
          <MuiTable>
            <SuiBox component="thead">
              <TableRow>{renderColumns}</TableRow>
            </SuiBox>
            <TableBody>{renderRows}</TableBody>
          </MuiTable>
        </TableContainer>
      </>
    ),
    [
      columns.map((col) => col.title).join(","),
      rows
        .filter((r) => r.name?.length > 0)
        .map((row) => row.name)
        .join(","),
    ]
  );
}

// Setting default values for the props of CollapsibleInnerTable
CollapsibleInnerTable.defaultProps = {
  columns: [],
  rows: [{}],
  innerHeader: "",
};

// Typechecking props for the CollapsibleInnerTable
CollapsibleInnerTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  innerHeader: PropTypes.string,
};

export default CollapsibleInnerTable;
