import { PAGES } from "redux/actions/pages/constants";

// feature name
export const MESSAGING = `${PAGES} [Messaging]`;

// action types
export const CREATE_NEW_MESSAGE = `${MESSAGING} CREATE_NEW_MESSAGE`;
export const CREATE_MESSAGE_TO_USERS = `${MESSAGING} CREATE_MESSAGE_TO_USERS`;
export const GET_MESSAGE_LIST = `${MESSAGING} GET_MESSAGE_LIST`;

// action creators
export const createNewMessage = ({ message }) => ({
  type: CREATE_NEW_MESSAGE,
  payload: { message },
});

export const createMessageToUsers = ({ toUserIdList, content, audioMessageUrl }) => ({
  type: CREATE_MESSAGE_TO_USERS,
  payload: { toUserIdList, content, audioMessageUrl },
});

export const getMessageList = () => ({
  type: GET_MESSAGE_LIST,
});
