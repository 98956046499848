/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import SuiBox from "components/SuiBox";
import Alert from "custom/Alert";
import Footer from "custom/Footer";
import DashboardLayout from "custom/LayoutContainers/DashboardLayout";
import DashboardNavbar from "custom/Navbars/DashboardNavbar";
import AddOrEditAssignment from "../components/AddOrEditAssignment.js";

const NewAssignment = ({ unsavedAssignment }) => {
  const location = useLocation();
  return (
    <>
      <Alert />
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox mt={3} mb={8}>
          <AddOrEditAssignment assignment={unsavedAssignment} bookId={location.state?.bookId} />
        </SuiBox>
        <Footer />
      </DashboardLayout>
    </>
  );
};

const mapStateToProps = (state) => ({
  unsavedAssignment: state.data.unsavedAssignment,
});

export default connect(mapStateToProps, null)(NewAssignment);
