// Feature Name Part
export const AUTH = "[Auth]";

// Action Types
export const START_AUTH = `${AUTH} START`;
export const SET_AUTH = `${AUTH} SET`;

// Action Creator Functions
export const startAuth = () => ({
  type: START_AUTH,
});

export const setAuth = ({ auth }) => ({
  type: SET_AUTH,
  payload: auth,
});
