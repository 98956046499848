/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import ReactAudioPlayer from "react-audio-player";
// import SuiBadge from "components/SuiBadge";

// Timeline context
// import { useMessageTimeline } from "custom/MessageTimeline/context";

// Custom styles for the TimelineItem
import { timelineItem, timelineItemIcon } from "custom/MessageTimeline/MessageTimelineItem/styles";

function MessageTimelineItem({ icon, dateTime, content, audioUrl, lastItem }) {
  // const isDark = useMessageTimeline();

  return (
    <SuiBox position="relative" sx={(theme) => timelineItem(theme, { lastItem })}>
      <SuiBox
        bgColor="white"
        width="1.625rem"
        height="1.625rem"
        borderRadius="50%"
        position="absolute"
        top="3.25%"
        left="2px"
        zIndex={2}
      >
        <Icon sx={(theme) => timelineItemIcon(theme, { color: "info" })}>{icon}</Icon>
      </SuiBox>
      <SuiBox ml={5.75} pt={content ? 0.7 : 0.5} lineHeight={0} maxWidth="30rem">
        <SuiTypography variant="button" fontWeight="medium" color="dark">
          {dateTime}
        </SuiTypography>
        {content ? (
          <SuiBox mt={2} mb={1.5}>
            <SuiTypography
              variant="button"
              fontWeight="regular"
              color="text"
              sx={{ whiteSpace: "pre-line" }}
            >
              {content}
            </SuiTypography>
          </SuiBox>
        ) : null}

        {audioUrl ? (
          <SuiBox mt={2} mb={1.5}>
            <SuiBox mb={1}>
              <SuiTypography variant="button" fontWeight="regular" color="dark">
                Sesli Mesaj
              </SuiTypography>
            </SuiBox>
            <SuiTypography variant="button" fontWeight="regular" color="text">
              <ReactAudioPlayer src={audioUrl} controls style={{ width: "100%" }} />
            </SuiTypography>
          </SuiBox>
        ) : null}
      </SuiBox>
    </SuiBox>
  );
}

// Setting default values for the props of TimelineItem
MessageTimelineItem.defaultProps = {
  lastItem: false,
  content: "",
  audioUrl: "",
};

// Typechecking props for the TimelineItem
MessageTimelineItem.propTypes = {
  icon: PropTypes.node.isRequired,
  dateTime: PropTypes.string.isRequired,
  content: PropTypes.string,
  audioUrl: PropTypes.string,
  lastItem: PropTypes.bool,
};

export default MessageTimelineItem;
