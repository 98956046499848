import { PAGES } from "redux/actions/pages/constants";

// common feature name
export const COMMON = `${PAGES} [Common]`;

// action types
export const GET_UPLOAD_PARAMS = `${COMMON} GET_UPLOAD_PARAMS`;
export const REMOVE_UPLOAD_PARAMS = `${COMMON} REMOVE_UPLOAD_PARAMS`;

// action creators
export const getUploadParams = ({ type, extention, folder, identifier }) => ({
  type: GET_UPLOAD_PARAMS,
  payload: { type, extention, folder, identifier },
});

export const removeUploadParams = ({ identifier }) => ({
  type: REMOVE_UPLOAD_PARAMS,
  payload: { identifier },
});
