const SeverityTypes = Object.freeze({
  info: "INFO",
  minor: "MINOR",
  major: "MAJOR",
  critical: "CRITICAL",
});

/**
 * Severity Types Meanings
 *
 * - info     => Hata değil, her request için seçilir.
 * - minor    => Akışı bozmayan ufak hatalar için kullanılır.
 * - major    => Akışı kullanıcı bazında bozan hatalar için kullanılır.
 * - critical => Akışı bozan hatalar için kullanılır.
 */

export default SeverityTypes;
