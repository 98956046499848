import React from "react";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { connect } from "react-redux";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const QuizResults = ({ quizResults, localizations }) => {
  if (
    quizResults &&
    (quizResults.correct !== null || quizResults.wrong !== null || quizResults.blank !== null)
  ) {
    return (
      <div className="student-quiz-summary">
        <div className="section-header">
          {localizations?.QUIZ_RESULTS_HEADER ?? "Değerlendirme Sonuçları"}
        </div>
        <div className="chart-container">
          <Bar
            options={{
              aspectRatio: 1.5,
              indexAxis: "x",
              elements: {
                bar: { borderWidth: 2 },
              },
              responsive: true,
              plugins: {
                legend: {
                  position: "right",
                  labels: { font: { size: 18 } },
                },
                title: {
                  display: false,
                },
                datalabels: {
                  // display: (context) => {
                  //   console.log(context.chart);
                  //   if (context.dataset.data[0] >= 18) {
                  //     return true;
                  //   }
                  //   return false;
                  // },
                  display: true,
                  color: "#FFF",
                  font: { size: 16, weight: "bold" },
                  // formatter: function (value, _context) {
                  //   return `% ${value}`;
                  // },
                },
              },
              scales: {
                y: {
                  ticks: { font: { size: 20 }, precision: 0 },
                  beginAtZero: true,
                  min: 0,
                },
              },
            }}
            data={{
              labels: [""],
              datasets: [
                {
                  label: localizations?.QUIZ_RESULTS_CORRECT_LABEL ?? "Doğru",
                  data: [quizResults.correct ?? 0],
                  borderColor:
                    localizations?.QUIZ_RESULTS_CORRECT_BORDER_COLOR ?? "rgba(79, 118, 255, 1)",
                  backgroundColor:
                    localizations?.QUIZ_RESULTS_CORRECT_BGCOLOR ?? "rgba(79, 118, 255, 1)",
                },
                {
                  label: localizations?.QUIZ_RESULTS_WRONG_LABEL ?? "Yanlış",
                  data: [quizResults.wrong ?? 0],
                  borderColor:
                    localizations?.QUIZ_RESULTS_WRONG_BORDER_COLOR ?? "rgba(255, 41, 96, 1)",
                  backgroundColor:
                    localizations?.QUIZ_RESULTS_WRONG_BGCOLOR ?? "rgba(255, 41, 96, 1)",
                },
                {
                  label: localizations?.QUIZ_RESULTS_BLANK_LABEL ?? "Boş",
                  data: [quizResults.blank ?? 0],
                  borderColor:
                    localizations?.QUIZ_RESULTS_BLANK_BORDER_COLOR ?? "rgba(255, 193, 23, 1)",
                  backgroundColor:
                    localizations?.QUIZ_RESULTS_BLANK_BGCOLOR ?? "rgba(255, 193, 23, 1)",
                },
              ],
            }}
          />
        </div>
      </div>
    );
  }

  return <></>;
};

QuizResults.defaultProps = {
  localizations: null,
  quizResults: null,
};

QuizResults.propTypes = {
  localizations: PropTypes.objectOf(PropTypes.any),
  quizResults: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
});

export default connect(mapStateToProps, null)(QuizResults);
