/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Card,
  Grid,
  Tooltip,
  Checkbox,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
} from "@mui/material";
import { Microphone, Record, Check, X, FunnelSimple } from "phosphor-react";
import ReactAudioPlayer from "react-audio-player";

import DashboardLayout from "custom/LayoutContainers/DashboardLayout";
import DashboardNavbar from "custom/Navbars/DashboardNavbar";
import Footer from "custom/Footer";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import CustomSuiSelect from "custom/CustomSuiSelect";
import automaticMessages from "hardcoded/automatic-messages";
import SuiButton from "components/SuiButton";
import {
  getMyStudents,
  getUploadParams,
  removeUploadParams,
  createMessageToUsers,
} from "redux/actions/pages";
import useAudio from "hooks/use-audio";
import "./_new-message.css";
import Alert from "custom/Alert";

const AUDIO_MESSAGE_IDENTIFIER = "AUDIO_MESSAGE";

const NewMessage = ({
  getStudentList,
  myStudents,
  localizations,
  uploadParameters,
  getUploadingParameters,
  removeUploadParameters,
  lang,
  createMessage,
  redirectTo,
  suborganization,
}) => {
  const history = useHistory();

  const [refSize, setRefSize] = useState(0);
  const [message, setMessage] = useState("");
  const [audioMessageDoUrl, setAudioMessageDoUrl] = useState("");
  const [selectedStudentIds, setSelectedStudentIds] = useState([]);
  const [studentFilter, setStudentFilter] = useState("");
  const [selectAllStudents, setSelectAllStudents] = useState(false);
  const [filteredStudentList, setFilteredStudentList] = useState([]);

  const nodeRef = useRef(null);

  const [audioUrl, isRecording, startRecording, stopRecording, audioData, removeAudioData] =
    useAudio({
      MESSAGE_ERROR: localizations?.MESSAGE_ERROR,
      MESSAGE_START_AUDIO_ERROR_TEXT: localizations?.MESSAGE_START_AUDIO_ERROR_TEXT,
      MESSAGE_CONFIRM_BTN: localizations?.MESSAGE_CONFIRM_BTN,
    });

  const updateSize = () => {
    if (nodeRef.current?.clientHeight > 0) {
      setRefSize(nodeRef.current?.clientHeight - 360);
    }
  };

  useEffect(() => {
    if (redirectTo && redirectTo !== "" && redirectTo !== "/messages/new") {
      history.push(redirectTo);
    }
  }, [redirectTo]);

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    if (suborganization > 0) {
      getStudentList();
    }
  }, [suborganization]);

  useEffect(() => {
    if (
      uploadParameters &&
      uploadParameters.key &&
      uploadParameters.key !== "" &&
      uploadParameters.url &&
      uploadParameters.url !== ""
    ) {
      const { key, url } = uploadParameters;
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", `${url}`);
      xhr.setRequestHeader("Content-Type", "audio/mpeg");
      xhr.setRequestHeader("x-amz-acl", "public-read");
      xhr.send(audioData);
      xhr.onload = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            const fileUrl = `https://edictdigitalspaces.fra1.digitaloceanspaces.com/${key}`;
            setAudioMessageDoUrl(fileUrl);
            removeUploadParameters({ identifier: AUDIO_MESSAGE_IDENTIFIER });
          } else {
            // eslint-disable-next-line no-console
            console.error(xhr.statusText);
          }
        }
      };
      xhr.onerror = () => {
        // eslint-disable-next-line no-console
        console.error("on file upload error");
        // eslint-disable-next-line no-console
        console.error(xhr.statusText);
      };
      xhr.onloadend = () => {};
    }
  }, [uploadParameters]);

  useEffect(() => {
    if (myStudents.length > 0) {
      if (studentFilter === "") {
        setFilteredStudentList(myStudents);
      } else {
        const lowerStudentFilter = studentFilter.toLowerCase();
        const newStudentList = myStudents.filter(
          (s) =>
            s.name?.toLowerCase().includes(lowerStudentFilter) ||
            s.surname?.toLowerCase().includes(lowerStudentFilter) ||
            s.nickName?.toLowerCase().includes(lowerStudentFilter) ||
            s.studentNo?.toString().includes(studentFilter) ||
            s.className?.toLowerCase().includes(lowerStudentFilter) ||
            s.groups
              .map((g) => g.key)
              .join(", ")
              .toLocaleLowerCase(lang)
              // .toLowerCase()
              .includes(lowerStudentFilter)
        );

        setFilteredStudentList(newStudentList);
      }
    }
  }, [studentFilter, myStudents]);

  useEffect(() => {
    const result = filteredStudentList
      .map((fs) => fs.id)
      .every((el) => selectedStudentIds.indexOf(el) !== -1);
    setSelectAllStudents(result);
  }, [selectedStudentIds, filteredStudentList]);

  useEffect(() => {
    updateSize();
  }, [filteredStudentList]);

  const handleUploadSound = () => {
    const fileType = "audio/mpeg";
    const extention = "mp3";
    const folderName = "usersoundfiles";
    getUploadingParameters({
      type: fileType,
      folder: folderName,
      extention,
      identifier: AUDIO_MESSAGE_IDENTIFIER,
    });
  };

  return (
    <>
      <Alert />
      <DashboardLayout>
        <DashboardNavbar />
        <div style={{ position: "relative" }}>
          <div ref={nodeRef} style={{ position: "absolute", height: "100vh", width: 0 }} />
          <SuiBox mt={3} mb={6}>
            <Grid container spacing={3}>
              <Grid item xs={12} xl={12}>
                <h3>{localizations?.NEW_MESSAGE_HEADER ?? "Yeni Mesaj"}</h3>
              </Grid>
              <Grid item xs={12} xl={7}>
                <Card>
                  <SuiBox pt={2} px={2}>
                    <SuiBox mb={0.5}>
                      <Grid container flexWrap="wrap" alignItems="center">
                        <Grid item lg={6} xs={6}>
                          <SuiTypography variant="h6" fontWeight="medium">
                            {localizations?.MESSAGE ?? "Mesaj"}
                          </SuiTypography>
                        </Grid>
                        <Grid item lg={6} xs={6} style={{ minWidth: "200px" }}>
                          <div className="new-message-select-container">
                            <CustomSuiSelect
                              size="small"
                              onChange={(selected) => {
                                if (message === "") {
                                  setMessage(`${selected.label}`);
                                } else {
                                  setMessage(`${message}\n${selected.label}`);
                                }
                              }}
                              value={null}
                              placeholder={localizations?.SELECT_MESSAGE_PLACEHOLDER ?? "Mesaj Seç"}
                              options={automaticMessages}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </SuiBox>
                  </SuiBox>
                  <SuiBox p={2}>
                    <div className="new-message-container">
                      <SuiInput
                        placeholder={localizations?.WRITE_MESSAGE_PLACEHOLDER ?? "Mesaj yazınız..."}
                        multiline
                        rows={8}
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                        style={{ paddingRight: "60px" }}
                      />
                      <div className="new-message-audio-record-btn">
                        {!isRecording ? (
                          <Tooltip
                            title={localizations?.RECORD_AUDIO_MSG_TOOLTIP ?? "Sesli mesaj kaydet"}
                            placement="left"
                          >
                            <Microphone
                              color="#67BCEC"
                              size={40}
                              weight="duotone"
                              onClick={(e) => {
                                e.preventDefault();
                                startRecording();
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <div className="pause">
                            <Tooltip
                              title={localizations?.PAUSE_TOOLTIP ?? "Ses kaydını durdur"}
                              placement="left"
                            >
                              <Record
                                color="white"
                                size={40}
                                weight="duotone"
                                onClick={(e) => {
                                  e.preventDefault();
                                  stopRecording();
                                }}
                              />
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    </div>

                    {audioUrl && audioUrl !== "" && audioData ? (
                      <Grid item lg={12} xs={12}>
                        <div
                          className="audio-player-container"
                          style={{ margin: "10px 10px 0 10px", display: "flex" }}
                        >
                          <ReactAudioPlayer src={audioUrl} controls style={{ width: "100%" }} />
                          <Tooltip title={localizations?.SAVE_TOOLTIP ?? "Kaydet"} placement="top">
                            <SuiButton
                              className="icon-buttons gray"
                              onClick={(e) => {
                                e.preventDefault();
                                handleUploadSound();
                              }}
                              size="large"
                              iconOnly
                              circular
                            >
                              <Check weight="bold" color="green" />
                            </SuiButton>
                          </Tooltip>
                          <Tooltip
                            title={localizations?.REMOVE_RECORD_TOOLTIP ?? "Vazgeç"}
                            placement="top"
                          >
                            <SuiButton
                              className="icon-buttons gray"
                              onClick={(e) => {
                                e.preventDefault();
                                removeAudioData();
                                setAudioMessageDoUrl("");
                              }}
                              size="large"
                              iconOnly
                              circular
                            >
                              <X weight="bold" color="red" />
                            </SuiButton>
                          </Tooltip>
                        </div>
                      </Grid>
                    ) : (
                      <SuiBox mt={8} />
                    )}
                  </SuiBox>
                  <SuiBox p={2} display="flex" justifyContent="flex-end">
                    <SuiButton
                      variant="contained"
                      color="info"
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          message !== "" &&
                          selectedStudentIds.length > 0 &&
                          (!audioData || (audioData && audioMessageDoUrl !== ""))
                        ) {
                          createMessage({
                            toUserIdList: selectedStudentIds,
                            content: message,
                            audioMessageUrl: audioMessageDoUrl ?? null,
                          });
                        }
                      }}
                    >
                      {localizations?.SEND_BTN ?? "Gönder"}
                    </SuiButton>
                  </SuiBox>
                </Card>
              </Grid>
              <Grid item xs={12} xl={5}>
                <Card>
                  <SuiBox pt={2} px={2}>
                    <SuiBox mb={0.5}>
                      <SuiTypography variant="h6" fontWeight="medium">
                        {localizations?.STUDENTS ?? "Öğrenciler"}
                      </SuiTypography>
                    </SuiBox>
                  </SuiBox>
                  <SuiBox
                    py={2}
                    lineHeight={1.6}
                    style={{ margin: "0 1rem" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <div className="select-all-container">
                      <Checkbox
                        checked={selectAllStudents}
                        onClick={(e) => {
                          e.preventDefault();

                          if (!selectAllStudents) {
                            // Filtredeki herşeyi select et!
                            const newSelectedList = [
                              ...selectedStudentIds,
                              ...filteredStudentList.map((fs) => fs.id),
                            ];
                            const newSelectedListWithoutDuplications = [
                              ...new Set(newSelectedList),
                            ];
                            setSelectedStudentIds(newSelectedListWithoutDuplications);
                          } else {
                            const filteredStudentIds = selectedStudentIds.filter(
                              (s) => !filteredStudentList.map((fs) => fs.id).includes(s)
                            );
                            setSelectedStudentIds(filteredStudentIds);
                          }
                        }}
                      />
                      <SuiTypography variant="button" fontWeight="regular" color="text" ml={1}>
                        {localizations?.SELECT_ALL ?? "Hepsini Seç"}
                      </SuiTypography>
                    </div>

                    <SuiBox pr={1}>
                      <SuiInput
                        size="small"
                        type="search"
                        placeholder={
                          localizations?.STUDENTS_FILTER_INPUT_PLACEHOLDER ?? "Öğrenci Filtrele"
                        }
                        icon={{ component: <FunnelSimple />, direction: "left" }}
                        value={studentFilter}
                        onChange={(e) => {
                          setStudentFilter(e.target.value);
                        }}
                      />
                    </SuiBox>
                  </SuiBox>
                  <div
                    style={{
                      overflow: "scroll",
                      maxHeight: `${refSize}px`,
                      height: `${refSize}px`,
                    }}
                  >
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableBody>
                          {filteredStudentList.map((student) => (
                            <TableRow
                              key={student.id}
                              sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                                "&:first-of-type td": { borderTop: "1px solid rgb(233, 236, 239)" },
                              }}
                            >
                              <TableCell width="60px">
                                <Checkbox
                                  checked={selectedStudentIds.includes(student.id)}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectedStudentIds([...selectedStudentIds, student.id]);
                                    } else {
                                      const filteredStudentIds = selectedStudentIds.filter(
                                        (s) => s !== student.id
                                      );
                                      setSelectedStudentIds(filteredStudentIds);

                                      if (selectAllStudents) {
                                        setSelectAllStudents(false);
                                      }
                                    }
                                  }}
                                />
                              </TableCell>
                              <TableCell component="td" scrope="row">
                                {`${student.name} ${student.surname} (${student.studentNo}) ${student.className}`}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </SuiBox>
        </div>
        <Footer />
      </DashboardLayout>
    </>
  );
};

NewMessage.defaultProps = {
  myStudents: [],
  localizations: null,
  uploadParameters: null,
  lang: "TR",
  redirectTo: null,
  suborganization: 0,
};

NewMessage.propTypes = {
  getStudentList: PropTypes.func.isRequired,
  myStudents: PropTypes.arrayOf(PropTypes.any),
  localizations: PropTypes.objectOf(PropTypes.any),
  getUploadingParameters: PropTypes.func.isRequired,
  uploadParameters: PropTypes.objectOf(PropTypes.any),
  removeUploadParameters: PropTypes.func.isRequired,
  createMessage: PropTypes.func.isRequired,
  lang: PropTypes.string,
  redirectTo: PropTypes.string,
  suborganization: PropTypes.number,
};

const mapStateToProps = (state) => ({
  myStudents: state.data.students ?? [],
  localizations: state.localization?.pairs,
  lang: state.localization?.language ?? "TR",
  uploadParameters: state.data.uploadParameters
    ? state.data.uploadParameters[AUDIO_MESSAGE_IDENTIFIER]
    : null,
  redirectTo: state.ui.redirectTo,
  suborganization: state.header?.suborganization,
});

const mapDispatchToProps = {
  getStudentList: getMyStudents,
  getUploadingParameters: getUploadParams,
  removeUploadParameters: removeUploadParams,
  createMessage: createMessageToUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewMessage);
