import React from "react";
import PageLayout from "examples/LayoutContainers/PageLayout";
import batWing from "assets/images/error-images/bat-wing.png";
import batBody from "assets/images/error-images/bat-body.png";
import hountedHouseForeground from "assets/images/error-images/haunted-house-foreground.png";
import "./_error-403.css";

function Error403() {
  return (
    <PageLayout>
      <div className="error-container">
        <div className="maincontainer">
          <div className="bat">
            <img className="wing leftwing" alt="Bat Left Wing" src={batWing} />
            <img className="body" src={batBody} alt="Bat Body" />
            <img alt="Bat Right Wing" className="wing rightwing" src={batWing} />
          </div>
          <div className="bat">
            <img alt="Bat Left Wing" className="wing leftwing" src={batWing} />
            <img className="body" src={batBody} alt="Bat Body" />
            <img alt="Bat Right Wing" className="wing rightwing" src={batWing} />
          </div>
          <div className="bat">
            <img alt="Bat Left Wing" className="wing leftwing" src={batWing} />
            <img className="body" src={batBody} alt="Bat Body" />
            <img alt="Bat Right Wing" className="wing rightwing" src={batWing} />
          </div>
          <img className="foregroundimg" src={hountedHouseForeground} alt="Haunted House" />
        </div>
        <h1 className="errorcode">HATA! 403</h1>
        <div className="errortext">YASAKLI ALAN! BU ALANA GİRİŞ YETKİNİZ BULUNMAMAKTADIR.</div>
      </div>
    </PageLayout>
  );
}

export default Error403;
