import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { FunnelSimple } from "phosphor-react";
import DashboardLayout from "custom/LayoutContainers/DashboardLayout";
import DashboardNavbar from "custom/Navbars/DashboardNavbar";
import Footer from "custom/Footer";
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import DataTable from "custom/Tables/DataTable";
import Alert from "custom/Alert";
import { getMyStudentsLookups, getMyStudents, updateMyStudent } from "redux/actions/pages";
import StudentCell from "../components/StudentCell";
import EmailApproveCell from "../components/EmailApproveCell";
import BookLevelCell from "../components/BookLevelCell";
import AllowUpdateCell from "../components/AllowUpdateCell";
import GeneratePasswordCell from "../components/GeneratePasswordCell";
import PasswordGeneratedDialog from "../components/PasswordGeneratedDialog";

const MyStudents = ({
  localizations,
  language,
  getLookups,
  getStudentList,
  myStudents,
  updateStudent,
  suborganization,
}) => {
  const [studentFilter, setStudentFilter] = useState("");

  useEffect(() => {
    getLookups();
  }, []);

  useEffect(() => {
    if (suborganization > 0) {
      getStudentList();
    }
  }, [suborganization]);

  const handleApproveSwitchChange = useCallback(({ studentId, isEmailApproved }) => {
    updateStudent({ studentId, updatedFields: { approveEmail: isEmailApproved } });
  }, []);

  const handleAllowUpdateSwitchChange = useCallback(({ studentId, allowUpdate }) => {
    updateStudent({ studentId, updatedFields: { allowUpdate } });
  }, []);

  const columns = [
    {
      Header: localizations?.NAME_SURNAME ?? "Adı Soyadı",
      accessor: "student",
      width: "20%",
      // eslint-disable-next-line react/prop-types
      Cell: ({ value: [data] }) => <StudentCell {...data} />,
    },
    {
      Header: localizations?.SCHOOL_NO ?? "Okul No",
      accessor: "schoolNo",
    },
    {
      Header: localizations?.NICKNAME ?? "Hitap",
      accessor: "nickName",
    },
    {
      Header: localizations?.GRADE ?? "Sınıf",
      accessor: "className",
    },
    {
      Header: localizations?.LEVEL ?? "Seviye",
      accessor: "maxAssignedBookLevel",
      // eslint-disable-next-line react/prop-types
      Cell: ({ value: [data] }) => <BookLevelCell {...data} />,
    },
    {
      Header: localizations?.PARENT_EMAIL ?? "Veli Email",
      accessor: "parentEmail",
    },
    {
      Header: localizations?.APPROVE_EMAIL ?? "Email Onay",
      accessor: "approveSendReportToParent",
      // eslint-disable-next-line react/prop-types
      Cell: ({ value: [data] }) => (
        <EmailApproveCell data={data} handleApproveSwitchChange={handleApproveSwitchChange} />
      ),
    },
    {
      Header: localizations?.ALLOW_UPDATE ?? "Yetki",
      accessor: "allowStudentToUpdateProfile",
      // eslint-disable-next-line react/prop-types
      Cell: ({ value: [data] }) => (
        <AllowUpdateCell
          data={data}
          handleAllowUpdateSwitchChange={handleAllowUpdateSwitchChange}
        />
      ),
    },
    {
      Header: localizations?.USERNAME ?? "Kullanıcı Adı",
      accessor: "username",
    },
    {
      Header: localizations?.PASSWORD ?? "Şifre",
      accessor: "generatedPassword",
    },
    {
      Header: localizations?.ACTION ?? "İşlem",
      accessor: "generatePasswordAction",
      // eslint-disable-next-line react/prop-types
      Cell: ({ value: [data] }) => <GeneratePasswordCell data={data} />,
    },
  ];

  const getDataTableData = (studentList, filter, lang) => ({
    columns,
    rows:
      studentList && studentList.length > 0
        ? studentList
            .filter((s) => {
              let lowercaseFilter = "";
              if (filter && filter !== "") {
                lowercaseFilter = filter.toLocaleLowerCase(lang);
              }

              return (
                s.name?.toLocaleLowerCase(lang)?.includes(lowercaseFilter) ||
                s.surname?.toLocaleLowerCase(lang)?.includes(lowercaseFilter) ||
                `${s.name} ${s.surname}`?.toLocaleLowerCase(lang)?.includes(lowercaseFilter) ||
                s.nickName?.toLocaleLowerCase(lang)?.includes(lowercaseFilter) ||
                s.studentNo?.toString()?.toLocaleLowerCase(lang)?.includes(lowercaseFilter) ||
                s.className?.toLocaleLowerCase(lang)?.includes(lowercaseFilter) ||
                (localizations[s.level] ?? s.level)
                  ?.toLocaleLowerCase(lang)
                  ?.includes(lowercaseFilter)
              );
            })
            .sort((a, b) => `${a.name} ${a.surname}`.localeCompare(`${b.name} ${b.surname}`, "TR"))
            .map((s) => {
              let { level } = s;
              if (!s.level) {
                level = "LEVEL_1";
              }
              return {
                student: [
                  {
                    avatarUrl: s.avatarUrl,
                    fontWeight: "medium",
                    name: s.name,
                    surname: s.surname,
                  },
                ],
                schoolNo: s.studentNo,
                nickName: s.nickName,
                className: s.className,
                maxAssignedBookLevel: [{ content: level.replace("LEVEL_", ""), color: level }],
                parentEmail: s.parentEmail,
                approveSendReportToParent: [
                  { id: s.id, checked: s.approveEmail },
                  handleApproveSwitchChange,
                ],
                allowStudentToUpdateProfile: [
                  { id: s.id, checked: s.allowUpdate },
                  handleAllowUpdateSwitchChange,
                ],
                username: s.email,
                generatedPassword: s.generatedPassword,
                generatePasswordAction: [{ id: s.id }],
              };
            })
        : [],
  });

  const memoizedTableData = useMemo(
    () => getDataTableData(myStudents, studentFilter, language),
    [myStudents, studentFilter, language]
  );

  return (
    <>
      <Alert />
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <Grid container>
            <Grid item sm={7} xs={12}>
              <h3>{localizations?.MY_STUDENTS}</h3>
            </Grid>
            <Grid item sm={5} xs={12}>
              <SuiInput
                size="small"
                type="search"
                placeholder={localizations?.MY_STUDENTS_FILTER_INPUT_PLACEHOLDER}
                icon={{ component: <FunnelSimple />, direction: "left" }}
                value={studentFilter}
                onChange={(e) => {
                  setStudentFilter(e.target.value);
                }}
              />{" "}
            </Grid>
          </Grid>
          <Grid item lg={12} mt={3} xs={12}>
            <DataTable
              isSorted={false}
              table={memoizedTableData}
              entriesPerPage={{
                defaultValue: 25,
                entries: [5, 10, 15, 20, 25],
              }}
              // canSearch
              showPagination
              pagination={{ variant: "contained", color: "dark" }}
            />
          </Grid>
        </SuiBox>
        <Footer />
      </DashboardLayout>
      <PasswordGeneratedDialog />
    </>
  );
};

MyStudents.defaultProps = {
  localizations: null,
  language: "TR",
  myStudents: [],
  suborganization: 0,
};

MyStudents.propTypes = {
  localizations: PropTypes.objectOf(PropTypes.any),
  language: PropTypes.string,
  getLookups: PropTypes.func.isRequired,
  getStudentList: PropTypes.func.isRequired,
  updateStudent: PropTypes.func.isRequired,
  myStudents: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  suborganization: PropTypes.number,
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
  language: state.localization?.language ?? "TR",
  myStudents: state.data.students ?? [],
  suborganization: state.header?.suborganization,
});

const mapDispatchToProps = {
  getLookups: getMyStudentsLookups,
  getStudentList: getMyStudents,
  updateStudent: updateMyStudent,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyStudents);
