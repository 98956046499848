import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tooltip } from "@mui/material";

import SuiBox from "components/SuiBox";
import SuiAvatar from "components/SuiAvatar";
import avatarPlaceholder from "assets/images/avatar-placeholder.png";

const StudentsCell = ({ studentIds, myStudents }) =>
  myStudents && myStudents.length > 0 ? (
    <SuiBox display="flex">
      {studentIds.map((sId) => {
        const student = myStudents.find((my) => my.id === sId);

        if (student) {
          return (
            <Tooltip
              key={student.id}
              title={`${student.name} ${student.surname}`}
              placement="bottom"
            >
              <SuiAvatar
                src={
                  student.avatarUrl && student.avatarUrl !== ""
                    ? student.avatarUrl
                    : avatarPlaceholder
                }
                alt={`${student.name} ${student.surname}`}
                size="xs"
                sx={({ borders: { borderWidth }, palette: { white } }) => ({
                  border: `${borderWidth[2]} solid ${white.main}`,
                  cursor: "pointer",
                  position: "relative",
                  ml: -1.25,

                  "&:hover, &:focus": {
                    zIndex: "10",
                  },
                })}
              />
            </Tooltip>
          );
        }
        return null;
      })}
    </SuiBox>
  ) : (
    <p>-</p>
  );

StudentsCell.defaultProps = {
  studentIds: [],
  myStudents: [],
};

StudentsCell.propTypes = {
  studentIds: PropTypes.arrayOf(PropTypes.number),
  myStudents: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

const mapStateToProps = (state) => ({
  myStudents: state.data.students ?? [],
});

export default connect(mapStateToProps, null)(StudentsCell);
