import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { Grid } from "@mui/material";

import DashboardLayout from "custom/LayoutContainers/DashboardLayout";
import DashboardNavbar from "custom/Navbars/DashboardNavbar";
import SuiBox from "components/SuiBox";
import requireAuth from "hocs/requireAuth";
import MessageTypes from "enums/message-types";
import {
  getAllBooksWithFilterLookups,
  getBooksWithFilter,
  GET_ALL_BOOKS_WITH_FILTER,
} from "redux/actions/pages";
import { removeMessage } from "redux/actions/message";
import Loading from "custom/Loading";

import BooksFilter from "./components/BooksFilter";
import BooksContainer from "./components/BooksContainer";
import LevelFilter from "./components/LevelFilter";

const AllBooks = ({ getLookups, getBooks, apiErrorMsg, removeApiErrorMsg, localizations }) => {
  const location = useLocation();

  const initialFilters = {
    levels: [],
    grades: [],
    course: "",
    units: [],
    skills: [],
    specialDays: [],
    objectives: [],
  };

  const [filters, setFilters] = useState(initialFilters);

  const filterMapper = () => ({
    grades: filters.grades?.length === 0 ? null : filters.grades,
    course: filters.course === "" ? null : filters.course,
    levels: filters.levels?.length === 0 ? null : filters.levels,
    objectives: filters.objectives?.length === 0 ? null : filters.objectives,
    units: filters.units?.length === 0 ? null : filters.units,
    skills: filters.skills?.length === 0 ? null : filters.skills,
    specialDays: filters.specialDays?.length === 0 ? null : filters.specialDays,
  });

  const isFilterEmpty = () => {
    const filter = filterMapper();
    return Object.keys(filter).every((key) => filter[key] === null);
  };

  useEffect(() => {
    getLookups();
  }, []);

  useEffect(() => {
    if (
      location?.state?.filterObjective !== null &&
      location?.state?.filterObjective !== undefined &&
      isFilterEmpty()
    ) {
      getBooks({ filter: { ...filterMapper(), objectives: [location.state.filterObjective] } });
    } else {
      getBooks({ filter: filterMapper() });
    }
  }, [filters]);

  useEffect(() => {
    if (
      apiErrorMsg &&
      apiErrorMsg.messageContent !== undefined &&
      apiErrorMsg.messageContent !== null &&
      apiErrorMsg.messageContent !== ""
    ) {
      Swal.fire({
        icon: apiErrorMsg.messageType || "error",
        title:
          apiErrorMsg.messageType === MessageTypes.success
            ? localizations?.MESSAGE_SUCCESS ?? "BAŞARILI!"
            : localizations?.MESSAGE_ERROR ?? "HATA!",
        text: apiErrorMsg.messageContent,
        showCancelButton: false,
        confirmButtonText: localizations?.MESSAGE_CONFIRM_BTN ?? `Tamam`,
      }).then(() => {
        removeApiErrorMsg(GET_ALL_BOOKS_WITH_FILTER);
      });
    }
  }, [apiErrorMsg]);

  return (
    <>
      <Loading />
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <BooksFilter filters={filters} setFilters={setFilters} />
            </Grid>
            <Grid item xs={12} lg={9}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <LevelFilter filters={filters} setFilters={setFilters} />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <BooksContainer filterCourse={filters.course} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>
      </DashboardLayout>
    </>
  );
};

AllBooks.defaultProps = {
  apiErrorMsg: null,
  localizations: null,
  // classes: [],
};

AllBooks.propTypes = {
  getLookups: PropTypes.func.isRequired,
  localizations: PropTypes.objectOf(PropTypes.any),
  apiErrorMsg: PropTypes.objectOf(PropTypes.any),
  removeApiErrorMsg: PropTypes.func.isRequired,
  getBooks: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  apiErrorMsg: state.message[GET_ALL_BOOKS_WITH_FILTER],
  localizations: state.localization?.pairs,
});

const mapDispatchToProps = {
  getLookups: getAllBooksWithFilterLookups,
  removeApiErrorMsg: removeMessage,
  getBooks: getBooksWithFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(requireAuth(AllBooks));
