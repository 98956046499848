import { PAGES } from "redux/actions/pages/constants";

// feature name
export const ASSIGNMENT_FORM = `${PAGES} [AssignmentForm]`;
export const ASSIGNMENT_LIST = `${PAGES} [AssignmentList]`;

// action types
export const START_ADD_OR_EDIT_ASSIGNMENT = `${ASSIGNMENT_FORM} START`;
export const GET_ASSIGNMENT_STUDENTS = `${ASSIGNMENT_FORM} GET_STUDENTS`;
export const GET_AUDIO_UPLOAD_PARAMS = `${ASSIGNMENT_FORM} GET_AUDIO_UPLOAD_PARAMS`;
export const REMOVE_AUDIO_UPLOAD_PARAMS = `${ASSIGNMENT_FORM} REMOVE_AUDIO_UPLOAD_PARAMS`;
export const SAVE_ASSIGNMENT = `${ASSIGNMENT_FORM} SAVE`;
export const UPDATE_ASSIGNMENT = `${ASSIGNMENT_FORM} UPDATE`;
export const SAVE_UNSAVED_ASSIGNMENT = `${ASSIGNMENT_FORM} SAVE_UNSAVED_ASSIGNMENT`;
export const START_ASSIGNMENT_LIST = `${ASSIGNMENT_LIST} START`;
export const GET_ASSIGNMENT_DETAILS = `${ASSIGNMENT_LIST} GET_ASSIGNMENT_DETAILS`;
export const GET_QUIZ_LIST_OF_BOOK = `${ASSIGNMENT_FORM} GET_QUIZ_LIST_OF_BOOK`;
export const GET_MATERIAL_LIST_OF_BOOK = `${ASSIGNMENT_FORM} GET_MATERIAL_LIST_OF_BOOK`;
export const GET_QUIZ_DETAIL = `${ASSIGNMENT_FORM} GET_QUIZ_DETAIL`;
export const DELETE_ASSIGNMENT = `${ASSIGNMENT_LIST} DELETE_ASSIGNMENT`;

// action creators
export const startAddOrEditAssignment = () => ({
  type: START_ADD_OR_EDIT_ASSIGNMENT,
});

export const getAssignmentStudents = () => ({
  type: GET_ASSIGNMENT_STUDENTS,
});

export const getAudioUploadParams = ({ type, extention, folder }) => ({
  type: GET_AUDIO_UPLOAD_PARAMS,
  payload: { type, extention, folder },
});

export const removeAudioUploadParams = () => ({
  type: REMOVE_AUDIO_UPLOAD_PARAMS,
});

export const saveAssignment = ({ assignment }) => ({
  type: SAVE_ASSIGNMENT,
  payload: { assignment },
});

export const updateAssignment = ({ assignment }) => ({
  type: UPDATE_ASSIGNMENT,
  payload: { assignment },
});

export const startAssignmentList = () => ({
  type: START_ASSIGNMENT_LIST,
});

export const getAssignmentDetails = ({ assignmentId }) => ({
  type: GET_ASSIGNMENT_DETAILS,
  payload: { assignmentId },
});

export const saveUnsavedAssignment = ({ unsavedAssignment }) => ({
  type: SAVE_UNSAVED_ASSIGNMENT,
  payload: { unsavedAssignment },
});

export const getQuizListOfBook = ({ bookId }) => ({
  type: GET_QUIZ_LIST_OF_BOOK,
  payload: { bookId },
});

export const getMaterialListOfBook = ({ bookId }) => ({
  type: GET_MATERIAL_LIST_OF_BOOK,
  payload: { bookId },
});

export const getQuizDetail = ({ quizId }) => ({
  type: GET_QUIZ_DETAIL,
  payload: { quizId },
});

export const deleteAssignment = ({ assignmentId }) => ({
  type: DELETE_ASSIGNMENT,
  payload: { assignmentId },
});
