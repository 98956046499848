// action types
export const SET_MESSAGE = "SET_MESSAGE";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";

// action creators
export const setMessage = ({ messageType, messageContent, feature }) => ({
  type: `${feature} ${SET_MESSAGE}`,
  payload: { messageType, messageContent },
  meta: { feature },
});

export const removeMessage = (feature) => ({
  type: `${feature} ${REMOVE_MESSAGE}`,
  meta: { feature },
});
