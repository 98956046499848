/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import SuiBox from "components/SuiBox";
import Alert from "custom/Alert";
import Footer from "custom/Footer";
import DashboardLayout from "custom/LayoutContainers/DashboardLayout";
import DashboardNavbar from "custom/Navbars/DashboardNavbar";
import { connect } from "react-redux";
import { getAssignmentDetails } from "redux/actions/pages";
import AddOrEditAssignment from "../components/AddOrEditAssignment.js";

const EditAssignment = ({ assignment, getDetails }) => {
  const location = useLocation();

  const [selectedAssignment, setSelectedAssignment] = useState(null);

  useEffect(() => {
    if (location.state?.assignmentId) {
      getDetails({ assignmentId: location.state.assignmentId });
    }
  }, [location.state?.assignmentId]);

  useEffect(() => {
    if (assignment) {
      const preparedAssignment = {
        assignmentId: assignment.id,
        bookId: assignment.book.id,
        listen: assignment.listen,
        read: assignment.read,
        record: assignment.record,
        quiz: assignment.quiz,
        material: assignment.material,
        dueDate: assignment.dueDate,
      };

      if (assignment.users?.length > 0) {
        preparedAssignment.users = assignment.users.map((u) => u.id);
        const assignmentMessage = assignment.users[0].messages?.find((m) => m.isFeedback === false);
        preparedAssignment.message = assignmentMessage?.content;
        preparedAssignment.audioMessageUrl = assignmentMessage?.audioMessageUrl;
        preparedAssignment.tasks = assignment.users[0].tasks;
      }
      setSelectedAssignment(preparedAssignment);
    }
  }, [assignment]);

  return (
    <>
      <Alert />
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox mt={3} mb={8}>
          <AddOrEditAssignment assignment={selectedAssignment} />
        </SuiBox>
        <Footer />
      </DashboardLayout>
    </>
  );
};

const mapStateToProps = (state, { location }) => ({
  assignment: state.data.assignments[location.state.assignmentId],
});

const mapDispatchToProps = {
  getDetails: getAssignmentDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAssignment);
