import React from "react";
import PropTypes from "prop-types";

const TableCell = ({ data }) => (
  <div
    style={{
      display: "inline-block",
      width: "max-content",
      opacity: 1,
      background: "transparent",
      color: data.id > 0 ? "#67748E" : "red",
      verticalAlign: "middle",
    }}
  >
    {data.value}
  </div>
);

TableCell.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default TableCell;
