import { PAGES } from "redux/actions/pages/constants";

// feature name
export const ACADEMIC_YEAR = `${PAGES} [AcademicYear]`;

// action types
export const GET_ALL_ACADEMIC_YEAR_ITEMS = `${ACADEMIC_YEAR} GET_ALL_ACADEMIC_YEAR_ITEMS`;
export const GET_USER_GRADES = `${ACADEMIC_YEAR} GET_USER_GRADES`;
export const GET_ALL_ACADEMIC_YEAR_DETAILS = `${ACADEMIC_YEAR} GET_ALL_ACADEMIC_YEAR_DETAILS`;

// action creators
export const getAllAcademicYearItems = () => ({
  type: GET_ALL_ACADEMIC_YEAR_ITEMS,
});

export const getUserGrades = () => ({
  type: GET_USER_GRADES,
});

export const getAllAcademicYearDetails = () => ({
  type: GET_ALL_ACADEMIC_YEAR_DETAILS,
});
