import { SET_HEADER, CLEAR_HEADER } from "redux/actions/header";

const initState = {};

export const headerReducer = (header = initState, action) => {
  switch (true) {
    case action.type.includes(SET_HEADER):
      return { ...header, ...action.payload };
    case action.type.includes(CLEAR_HEADER):
      return initState;
    default:
      return header;
  }
};
