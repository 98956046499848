import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, Grid } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import { changePassword } from "redux/actions/pages";
import FormField from "../FormField";

const ChangePassword = ({ localizations, updatePassword }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");

  return (
    <Card id="change-password">
      <SuiBox pt={2} px={2} lineHeight={1}>
        <SuiTypography variant="h6" fontWeight="medium">
          {localizations?.PROFILE_CHANGE_PASSWORD_HEADER ?? "Şifre Değiştir"}
        </SuiTypography>
        <SuiTypography variant="button" fontWeight="regular" color="text">
          {localizations?.PROFILE_CHANGE_PASSWORD_DESC ??
            "Lütfen şifrenizi bir başkası ile paylaşmayınız."}
        </SuiTypography>
      </SuiBox>
      <SuiBox component="form" p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField
              label={localizations?.PROFILE_FORM_CURRENT_PASS_LABEL ?? "Eski Şifreniz"}
              placeholder={localizations?.PROFILE_FORM_CURRENT_PASS_PLACEHOLDER ?? "Eski Şifreniz"}
              inputProps={{
                type: "password",
                autoComplete: "",
                value: oldPassword,
                onChange: (e) => setOldPassword(e.target.value),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              label={localizations?.PROFILE_FORM_NEW_PASS_LABEL ?? "Yeni Şifreniz"}
              placeholder={localizations?.PROFILE_FORM_NEW_PASS_PLACEHOLDER ?? "Yeni Şifreniz"}
              inputProps={{
                type: "password",
                autoComplete: "",
                value: newPassword,
                onChange: (e) => setNewPassword(e.target.value),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              label={localizations?.PROFILE_FORM_CONFIRM_PASS_LABEL ?? "Yeni Şifre Tekrarı"}
              placeholder={localizations?.PROFILE_FORM_CONFIRM_PASS_PLACEHOLDER ?? "Yeni Şifreniz"}
              inputProps={{
                type: "password",
                autoComplete: "",
                value: newPassword2,
                onChange: (e) => setNewPassword2(e.target.value),
              }}
            />
          </Grid>
        </Grid>
        <SuiBox mt={2}>
          <SuiButton
            style={{ textTransform: "none" }}
            variant="contained"
            color="dark"
            disabled={oldPassword === "" || newPassword === "" || newPassword2 === ""}
            fullWidth
            onClick={(e) => {
              e.preventDefault();
              if (newPassword !== "" && newPassword === newPassword2) {
                updatePassword({
                  oldPassword,
                  newPassword,
                });
                setOldPassword("");
                setNewPassword("");
                setNewPassword2("");
              } else {
                // eslint-disable-next-line no-console
                // console.log("HATA GÖSTER!");
              }
            }}
          >
            {localizations?.PROFILE_FORM_UPDATE_PASS_BTN ?? "Şifremi Değiştir"}
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </Card>
  );
};

ChangePassword.defaultProps = {
  localizations: null,
};

ChangePassword.propTypes = {
  localizations: PropTypes.objectOf(PropTypes.any),
  updatePassword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
});

const mapDispatchToProps = {
  updatePassword: changePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
