import { PAGES } from "redux/actions/pages/constants";

// feature name
export const ALL_BOOKS_WITH_FILTER = `${PAGES} [AllBooksWithFilter]`;

// action types
export const GET_ALL_BOOKS_WITH_FILTER_LOOKUPS = `${ALL_BOOKS_WITH_FILTER} GET_LOOKUPS`;
export const GET_ALL_BOOKS_WITH_FILTER = `${ALL_BOOKS_WITH_FILTER} GET_BOOKS`;
export const GET_MY_CLASSES = `${ALL_BOOKS_WITH_FILTER} GET_MY_CLASSES`;

// action creators
export const getAllBooksWithFilterLookups = () => ({
  type: GET_ALL_BOOKS_WITH_FILTER_LOOKUPS,
});

export const getBooksWithFilter = ({ filter }) => ({
  type: GET_ALL_BOOKS_WITH_FILTER,
  payload: { filter },
});

export const getMyClasses = () => ({
  type: GET_MY_CLASSES,
});
