import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import SuiTypography from "components/SuiTypography";
import { OBJECTIVES } from "redux/actions/constants";
import FilterCheckBox from "../FilterCheckBox";

const ObjectiveFilter = ({ lookups, localizations, filters, setFilters, width }) => (
  <>
    <SuiTypography
      component="a"
      variant="button"
      fontWeight="bold"
      color="inherit"
      textTransform="capitalize"
    >
      {localizations?.OBJECTIVE_FILTER_HEADER ?? "KAZANIM"}
    </SuiTypography>
    <ul style={{ listStyle: "none", marginLeft: "20px" }}>
      {lookups.l_objectives?.lookupValues
        ?.filter(
          (lv) =>
            filters.skills.includes(lv.parentValueKey) || filters.units.includes(lv.parentValueKey)
        )
        ?.map((lv) => (
          <li key={`objective-lookupValue-${lv.id}`}>
            <FilterCheckBox
              width={width}
              checked={filters.objectives?.includes(lv.lookupValueKey)}
              onChange={(e) => {
                // Eğer course işaretlenir ya da işareti kaldırılırsa;
                // Beceri, ünite, özel gün, kazanım filtreleri sıfırlanır.
                if (e.target.checked) {
                  setFilters({
                    ...filters,
                    objectives: [...filters.objectives, lv.lookupValueKey],
                  });
                } else {
                  setFilters({
                    ...filters,
                    objectives: filters.objectives.filter((obj) => obj !== lv.lookupValueKey),
                  });
                }
              }}
              label={lv.lookupValueKey}
            />
          </li>
        ))}
    </ul>
  </>
);

ObjectiveFilter.defaultProps = {
  localizations: null,
  lookups: {},
  width: 100,
};

ObjectiveFilter.propTypes = {
  localizations: PropTypes.objectOf(PropTypes.any),
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  setFilters: PropTypes.func.isRequired,
  lookups: PropTypes.objectOf(PropTypes.any),
  width: PropTypes.number,
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
  lookups:
    state.data.lookups && Object.keys(state.data.lookups).length !== 0
      ? {
          l_objectives: state.data.lookups[OBJECTIVES],
        }
      : {},
});

export default connect(mapStateToProps, null)(ObjectiveFilter);
