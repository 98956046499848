import { PAGES } from "redux/actions/pages/constants";

// feature name
export const REPORTS = `${PAGES} [Reports]`;

// action types
export const GET_STUDENT_REPORTS = `${REPORTS} GET_STUDENT_REPORTS`;
export const GET_CLASS_STUDENT_REPORTS = `${REPORTS} GET_CLASS_STUDENT_REPORTS`;
export const GET_GENERAL_CLASS_REPORTS = `${REPORTS} GET_GENERAL_CLASS_REPORTS`;
export const GET_SCHOOL_CLASS_REPORTS = `${REPORTS} GET_SCHOOL_CLASS_REPORTS`;
export const GET_GENERAL_SCHOOL_CLASS_REPORTS = `${REPORTS} GET_GENERAL_SCHOOL_CLASS_REPORTS`;
export const GET_SCHOOL_CLASS_STUDENT_REPORTS = `${REPORTS} GET_SCHOOL_CLASS_STUDENT_REPORTS`;
export const GET_GENERAL_SUBORGANIZATION_GRADE_REPORTS = `${REPORTS} GET_GENERAL_SUBORGANIZATION_GRADE_REPORTS`;

// action creators
export const getStudentReports = ({ studentId, academicStartDate }) => ({
  type: GET_STUDENT_REPORTS,
  payload: { studentId, academicStartDate },
});

export const getClassStudentReports = ({ startDate, endDate, academicStartDate }) => ({
  type: GET_CLASS_STUDENT_REPORTS,
  payload: { startDate, endDate, academicStartDate },
});

export const getGeneralClassReports = ({ academicStartDate }) => ({
  type: GET_GENERAL_CLASS_REPORTS,
  payload: { academicStartDate },
});

export const getSchoolClassReports = ({ startDate, endDate, academicStartDate }) => ({
  type: GET_SCHOOL_CLASS_REPORTS,
  payload: { startDate, endDate, academicStartDate },
});

export const getGeneralSchoolClassReports = ({ academicStartDate }) => ({
  type: GET_GENERAL_SCHOOL_CLASS_REPORTS,
  payload: { academicStartDate },
});

export const getSchoolClassStudentReports = ({ startDate, endDate, academicStartDate }) => ({
  type: GET_SCHOOL_CLASS_STUDENT_REPORTS,
  payload: { startDate, endDate, academicStartDate },
});

export const getGeneralSuborganizationGradeReports = ({ academicStartDate }) => ({
  type: GET_GENERAL_SUBORGANIZATION_GRADE_REPORTS,
  payload: { academicStartDate },
});
