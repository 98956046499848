import { PAGES } from "redux/actions/pages/constants";

// feature name
export const MY_STUDENTS = `${PAGES} [MyStudents]`;

// action types
export const GET_MY_STUDENTS = `${MY_STUDENTS} GET_MY_STUDENTS`;
export const GET_MY_STUDENTS_LOOKUPS = `${MY_STUDENTS} GET_LOOKUPS`;
export const UPDATE_MY_STUDENT = `${MY_STUDENTS} UPDATE`;
export const REGENERATE_PASSWORD = `${MY_STUDENTS} REGENERATE_PASSWORD`;
export const REMOVE_PASSWORD_GENERATION_RESULT = `${MY_STUDENTS} REMOVE_PASSWORD_GENERATION_RESULT`;

// action creators
export const getMyStudents = () => ({
  type: GET_MY_STUDENTS,
});

export const getMyStudentsLookups = () => ({
  type: GET_MY_STUDENTS_LOOKUPS,
});

export const updateMyStudent = ({ studentId, updatedFields }) => ({
  type: UPDATE_MY_STUDENT,
  payload: { studentId, updatedFields },
});

export const regeneratePassword = ({ studentId }) => ({
  type: REGENERATE_PASSWORD,
  payload: { studentId },
});

export const removePasswordGenerationResult = () => ({
  type: REMOVE_PASSWORD_GENERATION_RESULT,
});
