import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { jsPDF as JsPdf } from "jspdf";
import html2canvas from "html2canvas";
import Switch from "@mui/material/Switch";

import Loading from "custom/Loading";
import DashboardLayout from "custom/LayoutContainers/DashboardLayout";
import DashboardNavbar from "custom/Navbars/DashboardNavbar";
import Footer from "custom/Footer";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import { Card, Grid } from "@mui/material";
import { connect } from "react-redux";
import { getStudentReports } from "redux/actions/pages";
import { getAcademicStartDate } from "layouts/reports/components/month.mapper";
import ReportSummary from "./components/ReportSummary";
import ReportGeneralInfo from "./components/ReportGeneralInfo";
import "./_student-reports.css";
import MonthlyTaskComparisonChart from "../../components/MonthlyTaskComparisonChart";
import MonthlyReadingTaskIncentiveOrNotComparison from "../../components/MonthlyReadingTaskIncentiveOrNotComparison";
import TaskCompletionSummary from "../../components/TaskCompletionSummary";
import QuizResults from "../../components/QuizResults";

const PageOrientationType = Object.freeze({
  portrait: "portrait",
  landscape: "landscape",
});

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const StudentReports = ({ getReports, studentReportsData, localizations }) => {
  const location = useLocation();
  const pdfContainerRef = useRef();

  const [showAverages, setShowAverages] = useState(true);

  useEffect(() => {
    getReports({ studentId: location.state.studentId, academicStartDate: getAcademicStartDate() });
  }, []);

  const downloadPDFReport = async (e) => {
    e.preventDefault();
    const element = pdfContainerRef.current;
    element.style.display = "block";

    await delay(2000);

    const { clientHeight, clientWidth } = element;

    const canvas = await html2canvas(element, { scale: 1 });
    const data = canvas.toDataURL("image/jpeg", 100);

    const pageOrientation = PageOrientationType.portrait;

    const pdf = new JsPdf({ orientation: pageOrientation });

    const originalPdfWidth = pdf.internal.pageSize.getWidth();

    let pdfWidth = 0;
    let pdfHeight = 0;

    // calculate belongs to width
    pdfWidth = originalPdfWidth;
    pdfHeight = (originalPdfWidth * clientHeight) / clientWidth;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("print.pdf");
    element.style.display = "none";
  };

  return (
    <>
      <Loading />
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <Grid container>
            <Grid item sm={7} xs={12} mb={{ xs: 2, sm: 0 }}>
              <h3>Öğrenci Raporu</h3>
            </Grid>
            <Grid
              item
              size={{ sm: 5, xs: 12 }}
              flex={{ sm: true, xs: false }}
              ml={{ sm: "auto", xs: 0 }}
            >
              <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                <SuiBox display="flex" mr={3}>
                  <SuiBox>
                    <Switch
                      checked={showAverages}
                      onChange={() => setShowAverages(!showAverages)}
                    />
                  </SuiBox>
                  <SuiBox width="80%" ml={1}>
                    <SuiTypography variant="button" fontWeight="regular" color="text">
                      Ortalama Göster
                    </SuiTypography>
                  </SuiBox>
                </SuiBox>
                <SuiButton
                  size="medium"
                  variant="contained"
                  color="error"
                  // color="info"
                  onClick={downloadPDFReport}
                >
                  {localizations?.REPORTS_DOWNLOAD_PDF_BTN_LABEL ?? "PDF İndir"}
                </SuiButton>
              </div>
            </Grid>
            {studentReportsData !== null && studentReportsData !== undefined ? (
              <Grid item xs={12} mt={3} xl={12}>
                <Card>
                  <div className="student-report-container">
                    <SuiBox pt={3} px={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} xl={12}>
                          <ReportSummary
                            avatarUrl={studentReportsData.avatarUrl}
                            name={studentReportsData.name}
                            surname={studentReportsData.surname}
                            level={studentReportsData.level}
                            totalPoint={parseInt(studentReportsData.totalPoint, 10) ?? 0}
                          />
                        </Grid>
                        <Grid item xs={12} xl={12}>
                          <div className="student-report-line" />
                        </Grid>
                        <Grid item xs={12} xl={12}>
                          <ReportGeneralInfo
                            bookCount={studentReportsData.generalInformation.reduce(
                              (total, current) => {
                                if (current.type === "TOTAL") {
                                  return total + current.count;
                                }
                                return total;
                              },
                              0
                            )}
                            readingCount={studentReportsData.generalInformation.reduce(
                              (total, current) => {
                                if (current.type === "READ") {
                                  return total + current.count;
                                }
                                return total;
                              },
                              0
                            )}
                            listeningCount={studentReportsData.generalInformation.reduce(
                              (total, current) => {
                                if (current.type === "LISTEN") {
                                  return total + current.count;
                                }
                                return total;
                              },
                              0
                            )}
                            recordingCount={studentReportsData.generalInformation.reduce(
                              (total, current) => {
                                if (current.type === "RECORD") {
                                  return total + current.count;
                                }
                                return total;
                              },
                              0
                            )}
                            quizCount={studentReportsData.generalInformation.reduce(
                              (total, current) => {
                                if (current.type === "QUIZ") {
                                  return total + current.count;
                                }
                                return total;
                              },
                              0
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} xl={6}>
                          <MonthlyTaskComparisonChart
                            monthlyResults={studentReportsData.monthlyResults}
                            totalClassMonthlyResults={
                              showAverages
                                ? location?.state?.totalMonthlyReadingCounts ?? null
                                : null
                            }
                            itemCount={location?.state?.studentCount ?? 0}
                          />
                        </Grid>
                        <Grid item xs={12} xl={6}>
                          <MonthlyReadingTaskIncentiveOrNotComparison
                            monthlyResults={studentReportsData.monthlyResults}
                          />
                        </Grid>
                        <Grid item xs={12} xl={6}>
                          <TaskCompletionSummary
                            taskCompletion={studentReportsData.taskCompletion}
                          />
                        </Grid>
                        <Grid item xs={12} xl={6}>
                          <QuizResults quizResults={studentReportsData.quizInformation[0]} />
                        </Grid>
                      </Grid>
                      {/* <div
                        style={{ display: "flex", justifyContent: "center", marginBottom: "40px" }}
                      >
                        <SuiButton
                          size="large"
                          variant="contained"
                          color="error"
                          onClick={downloadPDFReport}
                        >
                          {localizations?.REPORTS_DOWNLOAD_PDF_BTN_LABEL ?? "PDF İndir"}
                        </SuiButton>
                      </div> */}
                    </SuiBox>
                  </div>
                </Card>
              </Grid>
            ) : null}
          </Grid>
        </SuiBox>
        <Footer />
      </DashboardLayout>

      {/* This part will be created dynamically, after saving pdf this part will be hidden */}
      {studentReportsData !== null && studentReportsData !== undefined ? (
        <div className="student-report-container-to-pdf" id="to-pdf" ref={pdfContainerRef}>
          <ReportSummary
            avatarUrl={studentReportsData.avatarUrl}
            name={studentReportsData.name}
            surname={studentReportsData.surname}
            level={studentReportsData.level}
            totalPoint={parseInt(studentReportsData.totalPoint, 10) ?? 0}
          />

          <div className="student-report-line" />

          <ReportGeneralInfo
            bookCount={studentReportsData.generalInformation.reduce((total, current) => {
              if (current.type === "TOTAL") {
                return total + current.count;
              }
              return total;
            }, 0)}
            readingCount={studentReportsData.generalInformation.reduce((total, current) => {
              if (current.type === "READ") {
                return total + current.count;
              }
              return total;
            }, 0)}
            listeningCount={studentReportsData.generalInformation.reduce((total, current) => {
              if (current.type === "LISTEN") {
                return total + current.count;
              }
              return total;
            }, 0)}
            recordingCount={studentReportsData.generalInformation.reduce((total, current) => {
              if (current.type === "RECORD") {
                return total + current.count;
              }
              return total;
            }, 0)}
            quizCount={studentReportsData.generalInformation.reduce((total, current) => {
              if (current.type === "QUIZ") {
                return total + current.count;
              }
              return total;
            }, 0)}
          />

          <div className="charts-container">
            <MonthlyTaskComparisonChart
              monthlyResults={studentReportsData.monthlyResults}
              totalClassMonthlyResults={
                showAverages ? location?.state?.totalMonthlyReadingCounts ?? null : null
              }
              itemCount={location?.state?.studentCount ?? 0}
            />

            <MonthlyReadingTaskIncentiveOrNotComparison
              monthlyResults={studentReportsData.monthlyResults}
            />

            <TaskCompletionSummary taskCompletion={studentReportsData.taskCompletion} />

            <QuizResults quizResults={studentReportsData.quizInformation[0]} />
          </div>
        </div>
      ) : null}
    </>
  );
};

StudentReports.defaultProps = {
  studentReportsData: null,
  localizations: null,
};

StudentReports.propTypes = {
  getReports: PropTypes.func.isRequired,
  studentReportsData: PropTypes.objectOf(PropTypes.any),
  localizations: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  studentReportsData: state.data.studentReports,
  localizations: state.localization?.pairs,
});

const mapDispatchToProps = {
  getReports: getStudentReports,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentReports);
