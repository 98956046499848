import React from "react";
import PropTypes from "prop-types";
import SuiBox from "components/SuiBox";
import { Switch } from "@mui/material";

const EmailApproveCell = ({ data, handleApproveSwitchChange }) => (
  <SuiBox mx={1}>
    <Switch
      checked={data.checked}
      onChange={(e) => {
        handleApproveSwitchChange({
          studentId: data.id,
          isEmailApproved: e.target.checked,
        });
      }}
    />
  </SuiBox>
);

EmailApproveCell.defaultProps = {};

EmailApproveCell.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  handleApproveSwitchChange: PropTypes.func.isRequired,
};

export default React.memo(
  EmailApproveCell,
  (prevProps, nextProps) =>
    prevProps.data.checked === nextProps.data.checked &&
    prevProps.data.id === nextProps.data.id &&
    prevProps.handleApproveSwitchChange === nextProps.handleApproveSwitchChange
);
