import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Card } from "@mui/material";
import SuiBox from "components/SuiBox";
import GradeFilter from "../GradeFilter";
import CourseFilter from "../CourseFilter";
import UnitSkillSpecialDayFilter from "../UnitSkillSpecialDayFilter";
import ObjectiveFilter from "../ObjectiveFilter";

const BooksFilter = ({ filters, setFilters }) => {
  const filtersRef = useRef(null);
  const [filtersWidth, setFiltersWidth] = useState(0);

  const items = [
    {
      key: "grade-filter",
      component: <GradeFilter width={filtersWidth} filters={filters} setFilters={setFilters} />,
    },
    {
      key: "course-filter",
      component: <CourseFilter width={filtersWidth} filters={filters} setFilters={setFilters} />,
    },
    {
      key: "ussd-filter",
      component: (
        <UnitSkillSpecialDayFilter width={filtersWidth} filters={filters} setFilters={setFilters} />
      ),
    },
    {
      key: "objective-filter",
      component: <ObjectiveFilter width={filtersWidth} filters={filters} setFilters={setFilters} />,
    },
  ];

  const updateWidth = () => {
    if (
      filtersRef.current?.clientWidth > 88 &&
      filtersRef.current?.clientWidth - 88 !== filtersWidth
    ) {
      setFiltersWidth(filtersRef.current?.clientWidth - 88);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    updateWidth();

    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
      }}
    >
      <SuiBox
        ref={filtersRef}
        component="ul"
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{ listStyle: "none" }}
      >
        {items.map(({ key, component }, index) => (
          <SuiBox key={key} component="li" pt={index === 0 ? 0 : 2}>
            {component}
          </SuiBox>
        ))}
      </SuiBox>
    </Card>
  );
};

BooksFilter.defaultProps = {};

BooksFilter.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default BooksFilter;
