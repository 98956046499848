/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import PacmanLoader from "react-spinners/PacmanLoader";
import { connect } from "react-redux";
import "./_custom-loading.css";
import { GENERAL_LOADING_IDENTIFIER } from "redux/actions/ui";

const Loading = ({ loading, identifier }) =>
  loading ? (
    <div
      className={
        !identifier || identifier === GENERAL_LOADING_IDENTIFIER
          ? "loading whole-screen"
          : "loading whole-element"
      }
    >
      <PacmanLoader
        color="#67BCEC"
        size={!identifier || identifier === GENERAL_LOADING_IDENTIFIER ? 50 : 25}
      />
    </div>
  ) : null;

const mapStateToProps = (state, ownProps) => ({
  loading:
    ownProps.identifier && ownProps.identifier !== ""
      ? state.ui.loading[ownProps.identifier]
      : state.ui.loading[GENERAL_LOADING_IDENTIFIER],
});

export default connect(mapStateToProps, {})(Loading);
