import React from "react";
import PropTypes from "prop-types";
import { Card, Grid } from "@mui/material";
import { connect } from "react-redux";

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { LEVELS } from "redux/actions/constants";
import BookItem from "../BookItem";

const BooksContainer = ({ books, localizations, filterCourse, lookups }) => (
  <Card sx={{ overflow: "visible" }}>
    <SuiBox p={3}>
      <SuiTypography variant="h5">{`${localizations?.BOOKS_CONTAINER_HEADER ?? "Kitaplar"} (${
        books.length
      })`}</SuiTypography>
    </SuiBox>
    <SuiBox pb={3} px={3}>
      <Grid container columnSpacing={1.5} rowSpacing={3}>
        {books
          .sort((a, b) => {
            if (a.level !== b.level) {
              if (lookups.l_levels && lookups.l_levels.lookupValues?.length > 0) {
                const aLevelIndex = lookups.l_levels.lookupValues.findIndex(
                  (lv) => lv.lookupValueKey === a.level
                );

                const bLevelIndex = lookups.l_levels.lookupValues.findIndex(
                  (lv) => lv.lookupValueKey === b.level
                );

                return aLevelIndex - bLevelIndex;
              }
            }
            return a.orderInLevel - b.orderInLevel;
          })
          .map((book) => (
            <BookItem book={book} key={`book-${book.id}`} filterCourse={filterCourse} />
          ))}
      </Grid>
    </SuiBox>
  </Card>
);

BooksContainer.defaultProps = {
  books: [],
  localizations: null,
  filterCourse: null,
  lookups: null,
};

BooksContainer.propTypes = {
  books: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  localizations: PropTypes.objectOf(PropTypes.any),
  filterCourse: PropTypes.string,
  lookups: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  books: state.data.filteredBooks ?? [],
  localizations: state.localization?.pairs,
  lookups:
    state.data.lookups && Object.keys(state.data.lookups).length !== 0
      ? {
          l_levels: state.data.lookups[LEVELS],
        }
      : {},
});

export default connect(mapStateToProps, null)(BooksContainer);
