/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { LEVELS } from "redux/actions/constants";

const LevelFilter = ({ lookups, localizations, filters, setFilters }) => {
  const [levels, setLevels] = useState([]);

  useEffect(() => {
    if (lookups.l_levels && lookups.l_levels.lookupValues?.length > 0) {
      setLevels(lookups.l_levels.lookupValues.map((lv) => lv.lookupValueKey));
    }
  }, [lookups.l_levels]);

  return (
    <Card>
      <SuiBox p={2}>
        <SuiTypography
          component="a"
          variant="button"
          fontWeight="bold"
          color="inherit"
          textTransform="capitalize"
        >
          {localizations?.LEVEL_FILTER_HEADER ?? "SEVİYE"}
        </SuiTypography>
        <div
          style={{
            width: "100%",
            overflowX: "auto",
            whiteSpace: "nowrap",
            display: "flex",
            alignItems: "center",
            paddingBottom: "16px",
          }}
        >
          {levels.length > 0
            ? levels.map((level) => {
                const prefixRemoved = level.replace("LEVEL_", "");
                return (
                  <SuiBox
                    bgColor={level}
                    height="50px"
                    width="50px"
                    minHeight="50px"
                    minWidth="50px"
                    borderRadius="50%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      cursor: "pointer",
                      backgroundColor: filters.levels?.includes(level) ? "" : "#CCC",
                    }}
                    m={1}
                    key={`level-${level}`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (filters.levels?.includes(level)) {
                        setFilters({
                          ...filters,
                          levels: filters.levels?.filter((lv) => lv !== level),
                        });
                      } else {
                        setFilters({
                          ...filters,
                          levels: [...filters.levels, level],
                        });
                      }
                    }}
                  >
                    <SuiTypography color="white" variant="h4">
                      {prefixRemoved}
                    </SuiTypography>
                  </SuiBox>
                );
              })
            : null}
        </div>
      </SuiBox>
    </Card>
  );
};

LevelFilter.defaultProps = {
  lookups: null,
  localizations: null,
};

LevelFilter.propTypes = {
  lookups: PropTypes.objectOf(PropTypes.any),
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  setFilters: PropTypes.func.isRequired,
  localizations: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
  lookups:
    state.data.lookups && Object.keys(state.data.lookups).length !== 0
      ? {
          l_levels: state.data.lookups[LEVELS],
        }
      : {},
});

export default connect(mapStateToProps, null)(LevelFilter);
