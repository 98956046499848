import React from "react";
import PropTypes from "prop-types";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiCover from "components/SuiCover";
import avatarPlaceholder from "assets/images/avatar-placeholder.png";

const StudentCell = ({ avatarUrl, fontWeight, name, surname }) => (
  <SuiBox display="flex" alignItems="center" py={0.5} px={1}>
    <SuiBox mr={2}>
      <SuiCover src={avatarUrl ?? avatarPlaceholder} name={name} variant="square" size="sm" />
    </SuiBox>
    <SuiTypography variant="button" fontWeight={fontWeight} sx={{ width: "max-content" }}>
      {`${name} ${surname}`}
    </SuiTypography>
  </SuiBox>
);

StudentCell.defaultProps = {
  fontWeight: "regular",
  avatarUrl: null,
};

StudentCell.propTypes = {
  avatarUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  surname: PropTypes.string.isRequired,
  fontWeight: PropTypes.string,
};

export default StudentCell;
