import React from "react";
import PropTypes from "prop-types";

import logoImage from "assets/images/logo-small.png";

const ReportHeader = ({ classInformation, gradeInformation }) => (
  <div className="class-reports-header">
    <span>
      {(() => {
        if (
          classInformation !== null &&
          classInformation !== undefined &&
          classInformation !== ""
        ) {
          return `${classInformation} Sınıf Raporu`;
        }

        if (
          gradeInformation !== null &&
          gradeInformation !== undefined &&
          gradeInformation !== ""
        ) {
          return `${gradeInformation}lar Raporu`;
        }

        return "Sınıf Raporu";
      })()}
    </span>
    <div className="logo">
      <img alt="Okuma Platformu Logo" src={logoImage} />
    </div>
  </div>
);

ReportHeader.defaultProps = {
  classInformation: null,
  gradeInformation: null,
};

ReportHeader.propTypes = {
  classInformation: PropTypes.string,
  gradeInformation: PropTypes.string,
};

export default ReportHeader;
