import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { BookOpen, Info, Question, X } from "phosphor-react";
import { Card, Dialog, Grid, Tooltip, IconButton } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import "./_book-item.css";
import QuizPreviewDialog from "../QuizPreviewDialog";

const BookItem = ({ book, localizations, filterCourse }) => {
  const history = useHistory();

  const [quizId, setQuizId] = useState(null);
  const [quizes, setQuizes] = useState([]);
  const [quizModalOpen, setQuizModalOpen] = useState(false);
  const [quizesModalOpen, setQuizesModalOpen] = useState(false);

  const onQuizDialogClose = useCallback(() => {
    setQuizModalOpen(false);
    setQuizId(null);
  }, []);

  const onQuizesDialogClose = useCallback(() => {
    setQuizesModalOpen(false);
    setQuizes([]);
  }, []);

  return (
    <>
      <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
        <div className="book-item">
          <img src={book.coverImage} alt={`${book.id}li kitap kapağı`} />
          <Grid container spacing={0.5}>
            <Grid item xl={4} xs={4}>
              <Tooltip title={localizations?.PREVIEW_BOOK_TOOLTIP ?? "Kitaba Gözat"}>
                {/* <a
                  href={`https://okumaplatformu.com/read/${book.id}`}
                  target="_blank"
                  rel="noreferrer"
                > */}
                <div
                  className="icon-container"
                  aria-hidden
                  onClick={() =>
                    window.open(`https://okumaplatformu.com/read/${book.id}`, "_blank")
                  }
                >
                  <BookOpen size="60%" weight="duotone" color="#413E3E" />
                </div>
                {/* </a> */}
              </Tooltip>
            </Grid>
            <Grid item xl={4} xs={4}>
              <Tooltip
                title={
                  book.primaryQuizId === null ||
                  book.primaryQuizId === undefined ||
                  !(book.primaryQuizId > 0)
                    ? localizations?.NO_QUIZ_TOOLTIP ?? "Değerlendirme bulunmamaktadır."
                    : localizations?.PREVIEW_QUIZ_TOOLTIP ?? "Değerlendirmeye Gözat"
                }
              >
                <div
                  aria-hidden
                  onClick={(e) => {
                    e.preventDefault();
                    // if (book.primaryQuizId > 0) {
                    //   setModalOpen(true);
                    //   setQuizId(book.primaryQuizId);
                    // }

                    if (book?.quizes?.length > 0) {
                      setQuizesModalOpen(true);
                      setQuizes(book.quizes);
                    }
                  }}
                  className={`icon-container ${book?.quizes?.length === 0 ? "disabled" : ""}`}
                >
                  <Question
                    size="60%"
                    weight="duotone"
                    color={book?.quizes?.length === 0 ? "#BFBFBF" : "#413E3E"}
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid item xl={4} xs={4}>
              <Tooltip title={localizations?.PREVIEW_LEARNING_MATERIAL_TOOLTIP ?? "Öğrenime Gözat"}>
                <div
                  className={
                    book.hasMaterial === true ? "icon-container-success" : "icon-container"
                  }
                  aria-hidden
                  onClick={(e) => {
                    e.preventDefault();
                    history.push({
                      pathname: "/book-info",
                      state: {
                        bookLevel: book.level,
                        bookId: book.id,
                        course: filterCourse,
                        coverImage: book.coverImage,
                        bookTitle: book.title,
                        bookOrder: book.orderInLevel,
                        bookGrades: book.grade?.split(","),
                        bookInUse: book.use,
                      },
                    });
                  }}
                >
                  <Info
                    size="60%"
                    weight="duotone"
                    color={book.hasMaterial === true ? "#FFFFFF" : "#413E3E"}
                  />
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </div>
      </Grid>
      {book?.quizes?.length > 0 ? (
        <>
          <QuizPreviewDialog open={quizModalOpen} onClose={onQuizDialogClose} quizId={quizId} />
          <Dialog
            scroll="body"
            fullWidth
            maxWidth="sm"
            onClose={onQuizesDialogClose}
            open={quizesModalOpen}
          >
            <Card>
              <IconButton
                aria-label="close"
                onClick={onQuizesDialogClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <X color="#344767" size={20} weight="bold" />
              </IconButton>
              <SuiBox variant="gradient">
                <SuiBox pt={3} px={3}>
                  <SuiTypography variant="h6" fontWeight="medium" color="dark">
                    {localizations?.QUIZES_DIALOG_SELECT_QUIZ ?? "Değerlendirme Seç"}
                  </SuiTypography>
                </SuiBox>
                <SuiBox px={5} py={2}>
                  {quizes.map((quiz) => (
                    <p>
                      <SuiTypography
                        variant="button"
                        fontWeight="regular"
                        color="dark"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          onQuizesDialogClose();
                          setQuizId(quiz?.id);
                          setQuizModalOpen(true);
                        }}
                      >
                        {localizations[quiz.type]}
                      </SuiTypography>
                    </p>
                  ))}
                </SuiBox>
              </SuiBox>
            </Card>
          </Dialog>
        </>
      ) : null}
    </>
  );
};

BookItem.defaultProps = {
  localizations: null,
  filterCourse: null,
};

BookItem.propTypes = {
  book: PropTypes.objectOf(PropTypes.any).isRequired,
  localizations: PropTypes.objectOf(PropTypes.any),
  filterCourse: PropTypes.string,
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
});

export default connect(mapStateToProps, null)(BookItem);
