/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/forbid-prop-types */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link, useHistory } from "react-router-dom";
// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import ReactSearchBox from "react-search-box";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";

// Soft UI Dashboard PRO React example components
import Breadcrumbs from "custom/Breadcrumbs";
import NotificationItem from "custom/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  // navbarDesktopMenu,
  navbarMobileMenu,
} from "custom/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard PRO React context
import { useSoftUIController, setTransparentNavbar, setMiniSidenav } from "context";

// Images
import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import { connect } from "react-redux";
import { signout, getSearchBooks } from "redux/actions/pages";
import { UserCircle, MagnifyingGlass } from "phosphor-react";
import { Tooltip } from "@mui/material";
import "./_dashboard-navbar.css";

function DashboardNavbar({
  absolute,
  light,
  isMini,
  auth,
  signoutAction,
  localizations,
  getBooks,
  books,
}) {
  const history = useHistory();

  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const [breadCrumbsTitle, setBreadCrumbsTitle] = useState("");

  useEffect(() => {
    getBooks();
  }, []);

  useEffect(() => {
    if (
      localizations !== undefined &&
      localizations !== null &&
      Object.keys(localizations).length > 0
    ) {
      const title = localizations[route[route.length - 1]?.toUpperCase()?.replace(/-/g, "_")];
      if (title && title !== "") {
        if (title !== breadCrumbsTitle) {
          setBreadCrumbsTitle(title);
        }
      } else {
        setBreadCrumbsTitle(route[route.length - 1]);
      }
    }
  }, [route, localizations]);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        image={<img src={team2} alt="person" />}
        title={["New message", "from Laur"]}
        date="13 minutes ago"
        onClick={handleCloseMenu}
      />
      <NotificationItem
        image={<img src={logoSpotify} alt="person" />}
        title={["New album", "by Travis Scott"]}
        date="1 day"
        onClick={handleCloseMenu}
      />
      <NotificationItem
        color="secondary"
        image={
          <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
            payment
          </Icon>
        }
        title={["", "Payment successfully completed"]}
        date="2 days"
        onClick={handleCloseMenu}
      />
    </Menu>
  );

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SuiBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={breadCrumbsTitle} route={route} light={light} />
        </SuiBox>
        {isMini ? null : (
          <SuiBox sx={(theme) => navbarRow(theme, { isMini })}>
            {books?.length > 0 ? (
              <div className="navbar-search-wrapper">
                <div className="navbar-search-container">
                  <ReactSearchBox
                    data={books.map((book) => ({ key: book.id, value: book.title }))}
                    placeholder={localizations?.SEARCH_BOOK_PLACEHOLDER ?? "Kitap ara.."}
                    onSelect={(record) => {
                      if (record.item?.key) {
                        const selectedBook = books.find((book) => book.id === record.item?.key);
                        if (selectedBook) {
                          history.push({
                            pathname: "/book-info",
                            state: {
                              bookLevel: selectedBook.level,
                              bookId: selectedBook.id,
                              coverImage: selectedBook.coverImage,
                              bookTitle: selectedBook.title,
                              bookOrder: selectedBook.orderInLevel,
                              bookGrades: selectedBook.grade?.split(","),
                              bookInUse: selectedBook.use,
                            },
                          });
                        }
                      }
                    }}
                    autoFocus
                    leftIcon={<MagnifyingGlass size={16} weight="duotone" color="#344767" />}
                    iconBoxSize="30px"
                    inputHeight="30px"
                    inputFontSize="12px"
                    inputFontColor="#344767"
                  />
                </div>
              </div>
            ) : null}
            {/* <SuiInput
                size="small"
                type="search"
                placeholder={localizations?.SEARCH_BOOK_PLACEHOLDER}
                icon={{ component: "search", direction: "left" }}
              /> */}
            <SuiBox color={light ? "white" : "inherit"}>
              {auth.currentUser?.id > 0 ? (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    signoutAction({ email: auth.currentUser.email });
                  }}
                >
                  <IconButton sx={navbarIconButton} size="small">
                    <Icon
                      sx={({ palette: { dark, white } }) => ({
                        color: light ? white.main : dark.main,
                      })}
                    >
                      logout
                    </Icon>
                    {localizations?.NAV_SIGNOUT ? (
                      <SuiTypography
                        variant="button"
                        fontWeight="medium"
                        color={light ? "white" : "dark"}
                      >
                        {localizations?.NAV_SIGNOUT}
                      </SuiTypography>
                    ) : null}
                  </IconButton>
                </Link>
              ) : (
                <Link to="/signin">
                  <IconButton sx={navbarIconButton} size="small">
                    <Icon
                      sx={({ palette: { dark, white } }) => ({
                        color: light ? white.main : dark.main,
                      })}
                    >
                      login
                    </Icon>
                    {localizations?.NAV_SIGNIN ? (
                      <SuiTypography
                        variant="button"
                        fontWeight="medium"
                        color={light ? "white" : "dark"}
                      >
                        {localizations?.NAV_SIGNIN}
                      </SuiTypography>
                    ) : null}
                  </IconButton>
                </Link>
              )}
              <IconButton
                size="small"
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon className={light ? "text-white" : "text-dark"}>
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              {/* <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon className={light ? "text-white" : "text-dark"}>notifications</Icon>
              </IconButton> */}
              {auth.currentUser?.id > 0 ? (
                <Link to="/profile">
                  <Tooltip
                    placement="bottom"
                    title={localizations?.PROFILE_TOOLTIP_TITLE ?? "Profil"}
                  >
                    <IconButton
                      size="small"
                      color="inherit"
                      sx={navbarIconButton}
                      variant="contained"
                    >
                      {/* <Icon className={light ? "text-white" : "text-dark"}>person</Icon> */}
                      <UserCircle weight="duotone" color="#344767" />
                    </IconButton>
                  </Tooltip>
                </Link>
              ) : null}
              {renderMenu()}
            </SuiBox>
          </SuiBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
  books: [],
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  auth: PropTypes.object,
  localizations: PropTypes.object,
  signoutAction: PropTypes.func,
  getBooks: PropTypes.func.isRequired,
  books: PropTypes.arrayOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  localizations: state.localization?.pairs,
  books: state.data.minimizedBooks,
});

const mapDispatchToProps = { signoutAction: signout, getBooks: getSearchBooks };

export default connect(mapStateToProps, mapDispatchToProps)(DashboardNavbar);
