import { SET_AUTH } from "redux/actions/auth";

const initState = {};

export const authReducer = (auth = initState, action) => {
  switch (action.type) {
    case SET_AUTH:
      return action.payload;
    default:
      return auth;
  }
};
