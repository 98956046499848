// Feature Name Part
export const HEADER = "[Header]";

// Action Types
// export const START_AUTH = `${AUTH} START`;
export const SET_HEADER = `${HEADER} SET`;
export const CLEAR_HEADER = `${HEADER} CLEAR`;

// Action Creator Functions
// export const startAuth = () => ({
//   type: START_AUTH,
// });

export const setHeader = ({ header }) => ({
  type: SET_HEADER,
  payload: header,
});

export const clearHeader = () => ({
  type: CLEAR_HEADER,
});
