/* GENERATED FILE */
import React, { forwardRef } from "react";

import {
  IconWeight,
  IconProps,
  PaintFunction,
  renderPathForWeight,
} from "../lib";
import IconBase, { RenderFunction } from "../lib/IconBase";

const pathsByWeight = new Map<IconWeight, PaintFunction>();

pathsByWeight.set("bold", (color: string) => (
  <>
    <line
      x1="92.8"
      y1="59"
      x2="85.1"
      y2="40.5"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    />
    <line
      x1="43"
      y1="108.8"
      x2="24.5"
      y2="101.1"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    />
    <line
      x1="213"
      y1="108.8"
      x2="231.5"
      y2="101.1"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    />
    <line
      x1="163.2"
      y1="59"
      x2="170.9"
      y2="40.5"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    />
    <line
      x1="240"
      y1="160"
      x2="16"
      y2="160"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    />
    <line
      x1="208"
      y1="200"
      x2="48"
      y2="200"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    />
    <path
      d="M77.4,160A53.2,53.2,0,0,1,76,148a52,52,0,0,1,104,0,53.2,53.2,0,0,1-1.4,12"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    />
  </>
));

pathsByWeight.set("duotone", (color: string) => (
  <>
    <path d="M70.2,160a60,60,0,1,1,115.6,0Z" opacity="0.2" />
    <line
      x1="92.8"
      y1="59"
      x2="85.1"
      y2="40.5"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <line
      x1="43"
      y1="108.8"
      x2="24.5"
      y2="101.1"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <line
      x1="213"
      y1="108.8"
      x2="231.5"
      y2="101.1"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <line
      x1="163.2"
      y1="59"
      x2="170.9"
      y2="40.5"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <line
      x1="240"
      y1="160"
      x2="16"
      y2="160"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <line
      x1="208"
      y1="200"
      x2="48"
      y2="200"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <path
      d="M70.2,160a60,60,0,1,1,115.6,0"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
  </>
));

pathsByWeight.set("fill", () => (
  <>
    <path d="M77.7,43.6a8.1,8.1,0,0,1,4.4-10.5,7.9,7.9,0,0,1,10.4,4.4l7.7,18.4a8.1,8.1,0,0,1-4.3,10.5,8.5,8.5,0,0,1-3.1.6,8.2,8.2,0,0,1-7.4-4.9ZM21.5,108.5l18.4,7.7a8.5,8.5,0,0,0,3.1.6,8,8,0,0,0,3.1-15.4L27.6,93.7a8.1,8.1,0,0,0-10.5,4.4A7.9,7.9,0,0,0,21.5,108.5ZM213,116.8a8.5,8.5,0,0,0,3.1-.6l18.4-7.7a7.9,7.9,0,0,0,4.4-10.4,8.1,8.1,0,0,0-10.5-4.4l-18.5,7.7a8,8,0,0,0,3.1,15.4ZM160.1,66.4a8.5,8.5,0,0,0,3.1.6,8.2,8.2,0,0,0,7.4-4.9l7.7-18.5a8.1,8.1,0,0,0-4.4-10.5,7.9,7.9,0,0,0-10.4,4.4l-7.7,18.4A8.1,8.1,0,0,0,160.1,66.4ZM240,152H195.5a70.1,70.1,0,0,0,.5-8,68,68,0,0,0-136,0,70.1,70.1,0,0,0,.5,8H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16Zm-32,40H48a8,8,0,0,0,0,16H208a8,8,0,0,0,0-16Z" />
  </>
));

pathsByWeight.set("light", (color: string) => (
  <>
    <line
      x1="92.8"
      y1="59"
      x2="85.1"
      y2="40.5"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
    />
    <line
      x1="43"
      y1="108.8"
      x2="24.5"
      y2="101.1"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
    />
    <line
      x1="213"
      y1="108.8"
      x2="231.5"
      y2="101.1"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
    />
    <line
      x1="163.2"
      y1="59"
      x2="170.9"
      y2="40.5"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
    />
    <line
      x1="240"
      y1="160"
      x2="16"
      y2="160"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
    />
    <line
      x1="208"
      y1="200"
      x2="48"
      y2="200"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
    />
    <path
      d="M70.2,160a60,60,0,1,1,115.6,0"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
    />
  </>
));

pathsByWeight.set("thin", (color: string) => (
  <>
    <line
      x1="92.8"
      y1="59"
      x2="85.1"
      y2="40.5"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="8"
    />
    <line
      x1="43"
      y1="108.8"
      x2="24.5"
      y2="101.1"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="8"
    />
    <line
      x1="213"
      y1="108.8"
      x2="231.5"
      y2="101.1"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="8"
    />
    <line
      x1="163.2"
      y1="59"
      x2="170.9"
      y2="40.5"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="8"
    />
    <line
      x1="240"
      y1="160"
      x2="16"
      y2="160"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="8"
    />
    <line
      x1="208"
      y1="200"
      x2="48"
      y2="200"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="8"
    />
    <path
      d="M70.2,160a60,60,0,1,1,115.6,0"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="8"
    />
  </>
));

pathsByWeight.set("regular", (color: string) => (
  <>
    <line
      x1="92.8"
      y1="59"
      x2="85.1"
      y2="40.5"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <line
      x1="43"
      y1="108.8"
      x2="24.5"
      y2="101.1"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <line
      x1="213"
      y1="108.8"
      x2="231.5"
      y2="101.1"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <line
      x1="163.2"
      y1="59"
      x2="170.9"
      y2="40.5"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <line
      x1="240"
      y1="160"
      x2="16"
      y2="160"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <line
      x1="208"
      y1="200"
      x2="48"
      y2="200"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <path
      d="M70.2,160a60,60,0,1,1,115.6,0"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
  </>
));

const renderPath: RenderFunction = (weight: IconWeight, color: string) =>
  renderPathForWeight(weight, color, pathsByWeight);

const SunHorizon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <IconBase ref={ref} {...props} renderPath={renderPath} />
));

SunHorizon.displayName = "SunHorizon";

export default SunHorizon;
