import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import MicRecorder from "mic-recorder-to-mp3";

async function requestRecorder() {
  const myRecorder = new MicRecorder({ bitRate: 128 });
  return myRecorder;
}

const useAudio = (localizations) => {
  const [audioUrl, setAudioUrl] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [audioData, setAudioData] = useState(null);
  const [audioError, setAudioError] = useState(false);
  const [startRecordCalled, setStartRecordCalled] = useState(false);

  useEffect(() => {
    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then(setRecorder, (err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          setAudioError(true);
          setIsRecording(false);
        });
      }

      return;
    }

    if (isRecording) {
      recorder
        .start()
        .then(() => {
          // Do nothing
        })
        .catch(() => {
          setAudioError(true);
          setIsRecording(false);
          setRecorder(null);
        });
    } else {
      recorder
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
          const file = new File(buffer, "something.mp3", {
            type: blob.type,
            lastModified: Date.now(),
          });

          setAudioData(file);
          setAudioUrl(URL.createObjectURL(file));
        });
    }
  }, [recorder, isRecording]);

  useEffect(() => {
    if (audioError || startRecordCalled) {
      Swal.fire({
        icon: "error",
        title: localizations?.MESSAGE_ERROR,
        text: localizations?.MESSAGE_START_AUDIO_ERROR_TEXT,
        showCancelButton: false,
        confirmButtonText: localizations?.MESSAGE_CONFIRM_BTN,
        // eslint-disable-next-line no-unused-vars
      }).then((_) => {
        setStartRecordCalled(false);
        setAudioError(false);
      });
    }
  }, [audioError]);

  const startRecording = () => {
    setStartRecordCalled(true);
    setIsRecording(true);
  };

  const stopRecording = () => {
    setIsRecording(false);
  };

  const removeAudioData = () => {
    setAudioUrl("");
    setIsRecording(false);
    setRecorder(null);
    setAudioData(null);
    setAudioError(false);
    setStartRecordCalled(false);
  };

  return [audioUrl, isRecording, startRecording, stopRecording, audioData, removeAudioData];
};

export default useAudio;
