/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { GRADES } from "redux/actions/constants";
import SuiTypography from "components/SuiTypography";
import FilterCheckBox from "../FilterCheckBox";

const GradeFilter = ({ lookups, filters, setFilters, localizations, width }) => (
  <>
    <SuiTypography
      component="a"
      variant="button"
      fontWeight="bold"
      color="inherit"
      textTransform="capitalize"
    >
      {localizations?.GRADE_FILTER_HEADER ?? "SINIF"}
    </SuiTypography>
    <ul style={{ listStyle: "none", marginLeft: "20px" }}>
      {lookups.l_grades?.lookupValues?.map((lv) => (
        <li key={`grade-lookupValue-${lv.id}`}>
          <FilterCheckBox
            width={width}
            checked={filters.grades.includes(lv.lookupValueKey)}
            onChange={(e) => {
              // Eğer grade işaretlenir ya da işareti kaldırılırsa;
              // Ders, beceri, ünite, özel gün, kazanım filtreleri sıfırlanır.
              if (e.target.checked) {
                setFilters({
                  ...filters,
                  grades: [lv.lookupValueKey],
                  course: "",
                  units: [],
                  skills: [],
                  specialDays: [],
                  objectives: [],
                });
              } else {
                setFilters({
                  ...filters,
                  grades: [],
                  course: "",
                  units: [],
                  skills: [],
                  specialDays: [],
                  objectives: [],
                });
              }
            }}
            label={lv.lookupValueKey}
          />
        </li>
      ))}
    </ul>
  </>
);

// return <div>{JSON.stringify(lookups.l_grades?.lookupValues)}</div>;
GradeFilter.defaultProps = {
  lookups: {},
  localizations: null,
  width: 100,
};

GradeFilter.propTypes = {
  lookups: PropTypes.objectOf(PropTypes.any),
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  setFilters: PropTypes.func.isRequired,
  localizations: PropTypes.objectOf(PropTypes.any),
  width: PropTypes.number,
};

const mapStateToProps = (state) => ({
  lookups:
    state.data.lookups && Object.keys(state.data.lookups).length !== 0
      ? { l_grades: state.data.lookups[GRADES] }
      : {},
  localizations: state.localization?.pairs,
});

export default connect(mapStateToProps, null)(GradeFilter);
