/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Dialog, Grid } from "@mui/material";
import SuiBox from "components/SuiBox";
import "./_lesson-plan-dialog.css";

const LessonPlanDialog = ({
  onClose,
  open,
  material,
  bookLevel,
  bookOrder,
  bookTitle,
  localizations,
  language,
}) => (
  <Dialog scroll="body" fullWidth maxWidth="lg" onClose={onClose} open={open}>
    <SuiBox p={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <p className="lp-dialog">
            {`${localizations[material.course]} ${
              localizations.LESSON_PLAN ?? "DERS PLANI"
            }`.toLocaleUpperCase(language)}
          </p>
        </Grid>
        <Grid item xs={12} lg={12}>
          <div className="lp-dialog-header">
            <div>{`${material.materialOrder}. ${localizations?.LESSON}`}</div>
            <div>{`${localizations?.DURATION}: ${
              material.duration < 40
                ? material.duration
                : material.duration === 40
                ? "1x40"
                : material.duration > 40
                ? `${material.duration / 40}x40`
                : ""
            }`}</div>
          </div>
          <div className="lp-dialog-objective">
            <h3>{localizations?.LESSON_PLAN_OBJECTIVE_HEADER}</h3>
            {material.objectives.split(",").map((obj) => (
              <p key={obj}>{localizations[obj]}</p>
            ))}
          </div>
        </Grid>
        <Grid item xs={12} lg={12}>
          <div className="lp-dialog-intro">
            <h3>{localizations?.LESSON_PLAN_INTRO_HEADER}</h3>
            <p>{material.introduction}</p>
          </div>
        </Grid>
        <Grid item xs={12} lg={12}>
          <div className="lp-dialog-activity">
            <h3>{localizations?.LESSON_PLAN_ACTIVITY_HEADER}</h3>
            <p>{material.activity}</p>
          </div>
        </Grid>
        <Grid item xs={12} lg={12}>
          <div className="lp-dialog-assessment">
            <h3>{localizations?.LESSON_PLAN_ASSESSMENT_HEADER}</h3>
            <p>{material.assessment}</p>
          </div>
        </Grid>
        <Grid item xs={12} lg={12}>
          <div className="lp-dialog-material">
            <h3>{localizations?.LESSON_PLAN_MATERIAL_HEADER}</h3>
            <a href={material.fileUrl} rel="noreferrer" target="_blank">
              {`${bookLevel.replace(
                "LEVEL_",
                ""
              )}.${bookOrder}_${material.documentType.toLowerCase()}_${bookTitle
                ?.toLowerCase()
                ?.replace(/ç/g, "c")
                ?.replace(/ğ/g, "g")
                ?.replace(/ı/g, "i")
                ?.replace(/ö/g, "o")
                ?.replace(/ş/g, "s")
                ?.replace(/ü/g, "u")
                ?.replace(/,|!|\?| /g, "")}_${material.materialOrder}`}
            </a>
          </div>
        </Grid>
      </Grid>
    </SuiBox>
  </Dialog>
);

LessonPlanDialog.defaultProps = {
  bookLevel: null,
  bookOrder: null,
  bookTitle: null,
  localizations: null,
  language: "TR",
};

LessonPlanDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  material: PropTypes.objectOf(PropTypes.any).isRequired,
  localizations: PropTypes.objectOf(PropTypes.any),
  bookLevel: PropTypes.string,
  language: PropTypes.string,
  bookOrder: PropTypes.number,
  bookTitle: PropTypes.string,
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
  language: state.localization?.language,
});

export default connect(mapStateToProps)(LessonPlanDialog);
