import React from "react";
import PropTypes from "prop-types";

const MessageCell = ({ content }) => (
  <div
    style={{
      display: "inline-block",
      width: "300px",
      whiteSpace: "pre-wrap",
      wordBreak: "break-word",
    }}
  >
    {content}
  </div>
);

MessageCell.defaultProps = {
  content: "",
};

MessageCell.propTypes = {
  content: PropTypes.string,
};

export default MessageCell;
