/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
// import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Timeline context
import { MessageTimelineProvider } from "custom/MessageTimeline/context";

function MessageTimelineList({ title, dark, children }) {
  return (
    <MessageTimelineProvider value={dark}>
      <SuiBox bgColor={dark ? "dark" : "white"} variant="gradient">
        <SuiBox pt={3} px={3}>
          <SuiTypography variant="h6" fontWeight="medium" color={dark ? "white" : "dark"}>
            {title}
          </SuiTypography>
        </SuiBox>
        <SuiBox p={2}>{children}</SuiBox>
      </SuiBox>
    </MessageTimelineProvider>
  );
}

// Setting default values for the props of TimelineList
MessageTimelineList.defaultProps = {
  dark: false,
};

// Typechecking props for the TimelineList
MessageTimelineList.propTypes = {
  title: PropTypes.string.isRequired,
  dark: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default MessageTimelineList;
