import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { jsPDF as JsPdf } from "jspdf";
import html2canvas from "html2canvas";
import { Card, Grid } from "@mui/material";

import Loading from "custom/Loading";
import DashboardLayout from "custom/LayoutContainers/DashboardLayout";
import DashboardNavbar from "custom/Navbars/DashboardNavbar";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import Footer from "custom/Footer";
import { getGeneralSuborganizationGradeReports } from "redux/actions/pages";
import { connect } from "react-redux";
import { getAcademicStartDate } from "layouts/reports/components/month.mapper";
import ReportHeader from "../../components/ReportHeader";
import ClassGeneralInfo from "../../components/ClassGeneralInfo";
import MonthlyTaskComparisonChart from "../../components/MonthlyTaskComparisonChart";
import MonthlyReadingTaskIncentiveOrNotComparison from "../../components/MonthlyReadingTaskIncentiveOrNotComparison";
import TaskCompletionSummary from "../../components/TaskCompletionSummary";
import QuizResults from "../../components/QuizResults";

const PageOrientationType = Object.freeze({
  portrait: "portrait",
  landscape: "landscape",
});

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const SchoolReports = ({ getReports, localizations, generalGradeReports, suborganization }) => {
  const pdfContainerRef = useRef();

  // const [showAverages, setShowAverages] = useState(true);
  const [selectedGradeLevel, setSelectedGradeLevel] = useState("");
  const [selectedGrade, setSelectedGrade] = useState(null);

  useEffect(() => {
    if (suborganization > 0) {
      getReports({ academicStartDate: getAcademicStartDate() });
    }
  }, [suborganization]);

  useEffect(() => {
    if (generalGradeReports?.length > 0) {
      setSelectedGradeLevel(generalGradeReports[0].classLevel);
    }
  }, [generalGradeReports]);

  useEffect(() => {
    if (selectedGradeLevel !== null && selectedGradeLevel !== "") {
      setSelectedGrade(generalGradeReports.find((gr) => gr.classLevel === selectedGradeLevel));
    }
  }, [selectedGradeLevel]);

  const downloadPDFReport = async (e) => {
    e.preventDefault();
    const element = pdfContainerRef.current;
    element.style.display = "block";

    await delay(2000);

    const { clientHeight, clientWidth } = element;

    const canvas = await html2canvas(element, { scale: 1 });
    const data = canvas.toDataURL("image/jpeg", 100);

    const pageOrientation = PageOrientationType.portrait;

    const pdf = new JsPdf({ orientation: pageOrientation });

    const originalPdfWidth = pdf.internal.pageSize.getWidth();

    let pdfWidth = 0;
    let pdfHeight = 0;

    // calculate belongs to width
    pdfWidth = originalPdfWidth;
    pdfHeight = (originalPdfWidth * clientHeight) / clientWidth;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("print.pdf");
    element.style.display = "none";
  };

  return (
    <>
      <Loading />
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <Grid container>
            <Grid item sm={7} xs={12} mb={{ xs: 2, sm: 0 }}>
              <h3>Sınıf Raporu</h3>
            </Grid>
            <Grid
              item
              size={{ sm: 5, xs: 12 }}
              flex={{ sm: true, xs: false }}
              ml={{ sm: "auto", xs: 0 }}
            >
              <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                <SuiButton
                  size="medium"
                  variant="contained"
                  color="error"
                  onClick={downloadPDFReport}
                >
                  {localizations?.REPORTS_DOWNLOAD_PDF_BTN_LABEL ?? "PDF İndir"}
                </SuiButton>
              </div>
            </Grid>
            {selectedGrade !== null && selectedGrade !== undefined ? (
              <Grid item xs={12} mt={3} xl={12}>
                <Card sx={{ overflow: "visible" }}>
                  <div className="class-reports-container">
                    <SuiBox pt={3} px={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} xl={12}>
                          <ReportHeader />
                        </Grid>
                        <Grid item xs={12} xl={12}>
                          <div className="class-reports-line" />
                        </Grid>
                        <Grid item xs={12} xl={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={4}>
                              <SuiSelect
                                willMaxHeightBeUsed
                                onChange={(selected) => setSelectedGradeLevel(selected.value)}
                                value={
                                  selectedGradeLevel !== null && selectedGradeLevel !== ""
                                    ? generalGradeReports
                                        ?.map((gr) => ({
                                          value: gr.classLevel,
                                          label:
                                            localizations && localizations[gr.classLevel]
                                              ? localizations[gr.classLevel]
                                              : gr.classLevel,
                                        }))
                                        ?.find((gr) => gr.value === selectedGradeLevel)
                                    : null
                                }
                                placeholder="Sınıf Seçiniz"
                                options={generalGradeReports?.map((gr) => ({
                                  value: gr.classLevel,
                                  label:
                                    localizations && localizations[gr.classLevel]
                                      ? localizations[gr.classLevel]
                                      : gr.classLevel,
                                }))}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} xl={12}>
                          <ClassGeneralInfo
                            studentCount={selectedGrade?.studentCount}
                            readingCount={
                              selectedGrade?.generalInformation?.reduce((total, current) => {
                                if (current.type === "READ") {
                                  return total + current.count;
                                }
                                return total;
                              }, 0) ?? 0
                            }
                            listeningCount={
                              selectedGrade?.generalInformation?.reduce((total, current) => {
                                if (current.type === "LISTEN") {
                                  return total + current.count;
                                }
                                return total;
                              }, 0) ?? 0
                            }
                            recordingCount={
                              selectedGrade?.generalInformation?.reduce((total, current) => {
                                if (current.type === "RECORD") {
                                  return total + current.count;
                                }
                                return total;
                              }, 0) ?? 0
                            }
                            quizCount={
                              selectedGrade?.generalInformation?.reduce((total, current) => {
                                if (current.type === "QUIZ") {
                                  return total + current.count;
                                }
                                return total;
                              }, 0) ?? 0
                            }
                          />
                        </Grid>
                        <Grid item xs={12} xl={6}>
                          <MonthlyTaskComparisonChart
                            monthlyResults={selectedGrade?.monthlyResults}
                          />
                        </Grid>
                        <Grid item xs={12} xl={6}>
                          <MonthlyReadingTaskIncentiveOrNotComparison
                            monthlyResults={selectedGrade?.monthlyResults}
                          />
                        </Grid>
                        <Grid item xs={12} xl={6}>
                          <TaskCompletionSummary taskCompletion={selectedGrade?.taskCompletion} />
                        </Grid>
                        <Grid item xs={12} xl={6}>
                          <QuizResults quizResults={selectedGrade?.quizInformation[0]} />
                        </Grid>
                      </Grid>
                    </SuiBox>
                  </div>
                </Card>
              </Grid>
            ) : null}
          </Grid>
        </SuiBox>
        <Footer />
      </DashboardLayout>

      {/* This part will be created dynamically, after saving pdf this part will be hidden */}
      {selectedGrade !== null && selectedGrade !== undefined ? (
        <div className="class-reports-container-to-pdf" id="to-pdf" ref={pdfContainerRef}>
          <ReportHeader
            gradeInformation={
              localizations && selectedGrade ? localizations[selectedGrade?.classLevel] ?? "" : null
            }
          />
          <div className="class-reports-line" />
          <ClassGeneralInfo
            studentCount={selectedGrade?.studentCount}
            readingCount={
              selectedGrade?.generalInformation?.reduce((total, current) => {
                if (current.type === "READ") {
                  return total + current.count;
                }
                return total;
              }, 0) ?? 0
            }
            listeningCount={
              selectedGrade?.generalInformation?.reduce((total, current) => {
                if (current.type === "LISTEN") {
                  return total + current.count;
                }
                return total;
              }, 0) ?? 0
            }
            recordingCount={
              selectedGrade?.generalInformation?.reduce((total, current) => {
                if (current.type === "RECORD") {
                  return total + current.count;
                }
                return total;
              }, 0) ?? 0
            }
            quizCount={
              selectedGrade?.generalInformation?.reduce((total, current) => {
                if (current.type === "QUIZ") {
                  return total + current.count;
                }
                return total;
              }, 0) ?? 0
            }
          />

          <div className="charts-container">
            <MonthlyTaskComparisonChart monthlyResults={selectedGrade?.monthlyResults} />
            <MonthlyReadingTaskIncentiveOrNotComparison
              monthlyResults={selectedGrade?.monthlyResults}
            />
            <TaskCompletionSummary taskCompletion={selectedGrade?.taskCompletion} />
            <QuizResults quizResults={selectedGrade?.quizInformation[0]} />
          </div>
        </div>
      ) : null}
    </>
  );
};

SchoolReports.defaultProps = {
  localizations: null,
  generalGradeReports: [],
  suborganization: 0,
};

SchoolReports.propTypes = {
  getReports: PropTypes.func.isRequired,
  localizations: PropTypes.objectOf(PropTypes.any),
  generalGradeReports: PropTypes.arrayOf(PropTypes.any),
  suborganization: PropTypes.number,
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
  generalGradeReports: state.data.generalSuborganizationGradeReports,
  suborganization: state.header?.suborganization,
});

const mapDispatchToProps = {
  getReports: getGeneralSuborganizationGradeReports,
};

export default connect(mapStateToProps, mapDispatchToProps)(SchoolReports);
