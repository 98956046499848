import React from "react";
import PropTypes from "prop-types";
import { Dialog, Grid } from "@mui/material";
import SuiBox from "components/SuiBox";
import { connect } from "react-redux";
import { removePasswordGenerationResult } from "redux/actions/pages";
import SuiButton from "components/SuiButton";
import "./_password-generated-dialog.css";
import { CheckCircle } from "phosphor-react";

const PasswordGeneratedDialog = ({ passwordGenerationResult, removeResult }) => (
  <Dialog
    scroll="body"
    onClose={() => removeResult()}
    open={passwordGenerationResult?.studentId > 0}
  >
    <SuiBox p={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <div className="pg-icon-container">
            <CheckCircle size={100} color="#67BCEC" weight="duotone" />
          </div>
        </Grid>
        <Grid item xs={12} lg={12}>
          <p className="pg-dialog-title">Şifre oluşturma işlemi başarıyla gerçekleştirildi.</p>
        </Grid>
        <Grid item xs={12} lg={12}>
          <div className="pg-password-container">
            {passwordGenerationResult?.generatedPassword?.split("")?.map((letter, i) => (
              <div key={`${letter}-${i + 1}`} className="pg-password-letter">
                <span>{letter}</span>
              </div>
            ))}
          </div>
        </Grid>
        <Grid item xs={12} lg={12}>
          <div className="pg-button-container">
            <SuiButton
              onClick={(e) => {
                e.preventDefault();
                removeResult();
              }}
              color="info"
            >
              Tamam
            </SuiButton>
          </div>
        </Grid>
      </Grid>
    </SuiBox>
  </Dialog>
);

PasswordGeneratedDialog.defaultProps = {
  passwordGenerationResult: null,
};

PasswordGeneratedDialog.propTypes = {
  passwordGenerationResult: PropTypes.objectOf(PropTypes.any),
  removeResult: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  passwordGenerationResult: state.data.passwordGenerationResult,
});

const mapDispatchToProps = {
  removeResult: removePasswordGenerationResult,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordGeneratedDialog);
