/* GENERATED FILE */
import React, { forwardRef } from "react";

import {
  IconWeight,
  IconProps,
  PaintFunction,
  renderPathForWeight,
} from "../lib";
import IconBase, { RenderFunction } from "../lib/IconBase";

const pathsByWeight = new Map<IconWeight, PaintFunction>();

pathsByWeight.set("bold", (color: string) => (
  <>
    <path
      d="M112,114.4l-26-45a20,20,0,0,1,34.6-20l40,69.3"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    />
    <path
      d="M85.4,148.3l-42-72.8A20,20,0,0,1,78,55.5l42,72.8"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    />
    <path
      d="M168.6,132.5l-18-31.2a20,20,0,0,1,34.7-20l20,34.7A80,80,0,0,1,66.7,196l-42-72.8a20,20,0,0,1,34.7-20l22,38.1"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    />
    <line
      x1="156"
      y1="12"
      x2="156"
      y2="28"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    />
    <line
      x1="204"
      y1="24.9"
      x2="196"
      y2="38.7"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    />
    <line
      x1="239.1"
      y1="60"
      x2="225.3"
      y2="68"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    />
  </>
));

pathsByWeight.set("duotone", (color: string) => (
  <>
    <path
      d="M77,93.5,69,79.7a17.6,17.6,0,0,0-9.1-7.8h0l-2.4-4.1a18,18,0,1,1,31.2-18L99,67.7h0A18,18,0,0,0,77,93.5"
      opacity="0.2"
    />
    <path
      d="M156.2,94.7a18,18,0,0,0-22,25.8l-26-45A17.9,17.9,0,0,0,99,67.7h0l-2.3-4a18,18,0,1,1,31.2-18l28.3,49Z"
      opacity="0.2"
    />
    <path
      d="M153.9,90.7a18,18,0,1,1,31.2-18l17,29.4A72.1,72.1,0,0,1,188,191.5h0a71.5,71.5,0,0,0-5.7-59.5l-17-29.5a18.2,18.2,0,0,0-9.1-7.8h0Z"
      opacity="0.2"
    />
    <path
      d="M51.8,121.9a18,18,0,1,0-31.2,18l37,64.1a72,72,0,0,0,124.7-72l-17-29.5a18,18,0,0,0-31.1,18"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <path
      d="M107,145.5,69,79.7a18,18,0,1,0-31.2,18l38,65.8"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <path
      d="M150.2,148.3l-42-72.8A18,18,0,1,0,77,93.5"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <path
      d="M99,67.7,88.7,49.8a18,18,0,1,0-31.2,18l2.4,4.1"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <path
      d="M188,191.5a72.1,72.1,0,0,0,14.1-89.4l-17-29.4a18,18,0,1,0-31.2,18"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <path
      d="M156.2,94.7l-28.3-49a18,18,0,1,0-31.2,18"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <line
      x1="176.8"
      y1="11.3"
      x2="172.7"
      y2="26.7"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <line
      x1="207.1"
      y1="25.4"
      x2="197.9"
      y2="38.5"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <line
      x1="230.6"
      y1="48.9"
      x2="217.5"
      y2="58.1"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
  </>
));

pathsByWeight.set("fill", () => (
  <>
    <path d="M205.5,100.1l-17-29.4a21.9,21.9,0,0,0-30-8.1A21.5,21.5,0,0,0,148.9,74L131.3,43.7a21.9,21.9,0,0,0-30-8.1A21.5,21.5,0,0,0,91.7,47a22.1,22.1,0,0,0-29.6-7.3A21.9,21.9,0,0,0,51.9,53.1a20.9,20.9,0,0,0-.4,9.6,26.5,26.5,0,0,0-11.1,3.4,26.1,26.1,0,0,0-9.5,35.6l2,3.4a24.3,24.3,0,0,0-9.7,3.3,26,26,0,0,0-9.5,35.5l37,64.1a80,80,0,0,0,146.6-19.3l.3-1.3A76.5,76.5,0,0,0,205.5,100.1Zm-23.7,84.5A63.3,63.3,0,0,1,152,223.4,64,64,0,0,1,64.6,200l-37-64.1a10,10,0,0,1,11.2-14.7,10.3,10.3,0,0,1,6.1,4.7h0l24,41.6a8.2,8.2,0,0,0,6.9,4,8,8,0,0,0,6.9-12l-38-65.8a10,10,0,0,1,17.4-10l38,65.8a8,8,0,1,0,13.8-8l-30-52h0a10.1,10.1,0,0,1,3.7-13.7,10,10,0,0,1,13.6,3.7l42,72.8a8.2,8.2,0,0,0,7,4,8.6,8.6,0,0,0,4-1.1,8,8,0,0,0,2.9-10.9l-16-27.8h0a9.9,9.9,0,0,1-1-7.5,9.6,9.6,0,0,1,4.7-6.1,9.4,9.4,0,0,1,7.5-1,9.8,9.8,0,0,1,6.1,4.6l17,29.5A63.5,63.5,0,0,1,181.8,184.6ZM165,24.7l4.1-15.5a8,8,0,1,1,15.5,4.1l-4.2,15.5a7.9,7.9,0,0,1-7.7,5.9l-2.1-.2A8,8,0,0,1,165,24.7Zm26.3,9.2,9.2-13.1a8.1,8.1,0,0,1,11.2-2A8,8,0,0,1,213.6,30l-9.2,13.1A7.9,7.9,0,0,1,193.3,45,8,8,0,0,1,191.3,33.9Zm43.9,21.6-13.1,9.2a8.6,8.6,0,0,1-4.6,1.4,8,8,0,0,1-4.6-14.5L226,42.4a8,8,0,0,1,11.2,1.9A8.1,8.1,0,0,1,235.2,55.5Z" />
  </>
));

pathsByWeight.set("light", (color: string) => (
  <>
    <path
      d="M51.8,121.9a18,18,0,1,0-31.2,18l37,64.1a72,72,0,0,0,124.7-72l-17-29.5a18,18,0,0,0-31.1,18"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
    />
    <path
      d="M107,145.5,69,79.7a18,18,0,1,0-31.2,18l38,65.8"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
    />
    <path
      d="M150.2,148.3l-42-72.8A18,18,0,1,0,77,93.5"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
    />
    <path
      d="M99,67.7,88.7,49.8a18,18,0,1,0-31.2,18l2.4,4.1"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
    />
    <path
      d="M188,191.5a72.1,72.1,0,0,0,14.1-89.4l-17-29.4a18,18,0,1,0-31.2,18"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
    />
    <path
      d="M156.2,94.7l-28.3-49a18,18,0,1,0-31.2,18"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
    />
    <line
      x1="176.8"
      y1="11.3"
      x2="172.7"
      y2="26.7"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
    />
    <line
      x1="207.1"
      y1="25.4"
      x2="197.9"
      y2="38.5"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
    />
    <line
      x1="230.6"
      y1="48.9"
      x2="217.5"
      y2="58.1"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
    />
  </>
));

pathsByWeight.set("thin", (color: string) => (
  <>
    <path
      d="M51.8,121.9a18,18,0,1,0-31.2,18l37,64.1a72,72,0,0,0,124.7-72l-17-29.5a18,18,0,0,0-31.1,18"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="8"
    />
    <path
      d="M107,145.5,69,79.7a18,18,0,1,0-31.2,18l38,65.8"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="8"
    />
    <path
      d="M150.2,148.3l-42-72.8A18,18,0,1,0,77,93.5"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="8"
    />
    <path
      d="M99,67.7,88.7,49.8a18,18,0,1,0-31.2,18l2.4,4.1"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="8"
    />
    <path
      d="M188,191.5a72.1,72.1,0,0,0,14.1-89.4l-17-29.4a18,18,0,1,0-31.2,18"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="8"
    />
    <path
      d="M156.2,94.7l-28.3-49a18,18,0,1,0-31.2,18"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="8"
    />
    <line
      x1="176.8"
      y1="11.3"
      x2="172.7"
      y2="26.7"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="8"
    />
    <line
      x1="207.1"
      y1="25.4"
      x2="197.9"
      y2="38.5"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="8"
    />
    <line
      x1="230.6"
      y1="48.9"
      x2="217.5"
      y2="58.1"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="8"
    />
  </>
));

pathsByWeight.set("regular", (color: string) => (
  <>
    <path
      d="M51.8,121.9a18,18,0,1,0-31.2,18l37,64.1a72,72,0,0,0,124.7-72l-17-29.5a18,18,0,0,0-31.1,18"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <path
      d="M107,145.5,69,79.7a18,18,0,1,0-31.2,18l38,65.8"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <path
      d="M150.2,148.3l-42-72.8A18,18,0,1,0,77,93.5"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <path
      d="M99,67.7,88.7,49.8a18,18,0,1,0-31.2,18l2.4,4.1"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <path
      d="M188,191.5a72.1,72.1,0,0,0,14.1-89.4l-17-29.4a18,18,0,1,0-31.2,18"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <path
      d="M156.2,94.7l-28.3-49a18,18,0,1,0-31.2,18"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <line
      x1="176.8"
      y1="11.3"
      x2="172.7"
      y2="26.7"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <line
      x1="207.1"
      y1="25.4"
      x2="197.9"
      y2="38.5"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <line
      x1="230.6"
      y1="48.9"
      x2="217.5"
      y2="58.1"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
  </>
));

const renderPath: RenderFunction = (weight: IconWeight, color: string) =>
  renderPathForWeight(weight, color, pathsByWeight);

const HandsClapping = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <IconBase ref={ref} {...props} renderPath={renderPath} />
));

HandsClapping.displayName = "HandsClapping";

export default HandsClapping;
