import React from "react";
import PropTypes from "prop-types";
import SuiBox from "components/SuiBox";
import SuiCover from "components/SuiCover";
import SuiTypography from "components/SuiTypography";

const BookCell = ({ data }) => (
  <SuiBox display="flex" alignItems="center" py={0.5}>
    {data.coverImage && data.coverImage !== "" ? (
      <SuiBox mr={2}>
        <SuiCover src={data.coverImage} name={data.title} variant="square" size="sm" />
      </SuiBox>
    ) : null}
    <SuiTypography variant="button" fontWeight={data.fontWeight} sx={{ width: "max-content" }}>
      {data.title ?? "-"}
    </SuiTypography>
  </SuiBox>
);

BookCell.defaultProps = {};

BookCell.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default React.memo(
  BookCell,
  (prevProps, nextProps) =>
    prevProps.data.coverImage === nextProps.data.coverImage &&
    prevProps.data.title === nextProps.data.title &&
    prevProps.data.fontWeight === nextProps.data.fontWeight
);
