import { SET_NOTIFICATION, REMOVE_NOTIFICATION } from "redux/actions/notifications";

const initState = [];

export const notificationsReducer = (notifications = initState, action) => {
  switch (true) {
    case action.type.includes(SET_NOTIFICATION):
      return [...notifications, action.payload];
    case action.type.includes(REMOVE_NOTIFICATION):
      // eslint-disable-next-line
      const filteredNotifications = notifications.filter(
        (notification) => notification.message?.id !== action.payload
      );
      return filteredNotifications?.length > 0 ? filteredNotifications : [];
    default:
      return notifications;
  }
};
