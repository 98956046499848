// action types

// Aşağıdaki işlemler state: { data: { VAR } } şeklinde uzanan
// VAR'ın tipine göre seçilmelidir.

// 1. VAR type:  (ARRAY | OBJECT | VALUE )
// Direkt olarak data objesi içerisinde değeri atanacaksa
// YOKSA EKLER, VARSA YERİNE YENİSİNİ KOYAR
export const SET_DATA = "SET_DATA";
export const PUT_DATA = "PUT_DATA";
export const UPDATE_ITEM_DATA = "UPDATE_ITEM_DATA";
export const UPDATE_ITEM_WITH_ITEM = "UPDATE_ITEM_WITH_ITEM";
export const REMOVE_DATA = "REMOVE_DATA";
export const UPDATE_ITEM_OF_ARRAY_OF_OBJECT = "UPDATE_ITEM_OF_ARRAY_OF_OBJECT";
export const CLEAR_DATA = "CLEAR_DATA";
export const APPEND_DATA_TO_OBJECT_ITEM = "APPEND_DATA_TO_OBJECT_ITEM";
export const MANIPULATE_DATA = "MANIPULATE_DATA";

// action creators
export const setData = ({ data, feature }) => ({
  type: `${feature} ${SET_DATA}`,
  payload: data,
  meta: { feature },
});

export const putData = ({ feature, data, where }) => ({
  type: `${feature} ${PUT_DATA}`,
  payload: { data, where },
  meta: { feature },
});

export const updateItemData = ({ feature, where, item, key, value }) => ({
  type: `${feature} ${UPDATE_ITEM_DATA}`,
  payload: { where, item, key, value },
  meta: { feature },
});

export const updateItemWithItem = ({ feature, where, itemLocation, itemValues }) => ({
  type: `${feature} ${UPDATE_ITEM_WITH_ITEM}`,
  payload: { where, itemLocation, itemValues },
  meta: { feature },
});

export const updateItemOfArrayOfObject = ({ feature, where, item, key, id, data }) => ({
  type: `${feature} ${UPDATE_ITEM_OF_ARRAY_OF_OBJECT}`,
  payload: { where, item, key, id, data },
  meta: { feature },
});

export const removeData = ({ feature, where }) => ({
  type: `${feature} ${REMOVE_DATA}`,
  payload: { where },
  meta: { feature },
});

export const clearData = () => ({
  type: CLEAR_DATA,
});

export const appendDataToObjectItem = ({ feature, where, item, data }) => ({
  type: `${feature} ${APPEND_DATA_TO_OBJECT_ITEM}`,
  payload: { where, item, data },
  meta: { feature },
});

export const manipulateData = ({ feature, operation, finder, value }) => ({
  type: `${feature} ${MANIPULATE_DATA}`,
  payload: { operation, finder, value },
  meta: { feature },
});
