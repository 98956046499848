/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// sweetalert2 components
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import { Trash } from "phosphor-react";
import { connect } from "react-redux";
import { deleteAssignment } from "redux/actions/pages";

const DeleteAssignment = ({ assignmentId, localizations, deleteCurrentAssignment }) => {
  const showAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-info",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: localizations?.DELETE_DIALOG_TITLE ?? "Görev Silme İşlemi",
        html: `
        ${
          localizations?.DELETE_DIALOG_TEXT !== null &&
          localizations?.DELETE_DIALOG_TEXT !== undefined
            ? localizations.DELETE_DIALOG_TEXT.split("\n").map(
                (line) => `<p style="text-align:left;margin-bottom:10px;">${line}</p>`
              )
            : `
                <p style="text-align:left;margin-bottom:10px;">
                  Göreve ilişkin tüm öğrenci verileri silinecektir ve işlem geri alınamaz.
                </p>
                <p style="text-align:left;margin-bottom:10px;">
                  Silme işlemine devam etmek istiyor musunuz?
                </p>
              `
        }

        `,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: localizations?.DELETE_DIALOG_DELETE_BTN ?? "EVET, GÖREVİ SİL",
        cancelButtonText: localizations?.DELETE_DIALOG_CANCEL_BTN ?? "HAYIR, VAZGEÇ",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          // deleteCurrentAssignment({ assignmentId });
          newSwal
            .fire({
              title: localizations?.DELETE_DIALOG_PROCESSED_TITLE ?? "İşleme alındı!",
              icon: "success",
              text:
                localizations?.DELETE_DIALOG_PROCESSED_TEXT ?? "Silme talebiniz işleme alınmıştır.",
              showCancelButton: false,
              confirmButtonText: localizations?.DELETE_DIALOG_PROCESSED_CONFIRM_BTN ?? "Tamam",
              confirmButtonColor: "info",
            })
            .then(() => {
              deleteCurrentAssignment({ assignmentId });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // No actions
        }
      });
  };

  return (
    <SuiBox ml={0.5}>
      <SuiButton
        iconOnly
        circular
        color="error"
        onClick={(e) => {
          e.preventDefault();
          showAlert();
        }}
      >
        <Trash size={32} weight="duotone" color="white" />
      </SuiButton>
    </SuiBox>
  );
};

DeleteAssignment.defaultProps = {
  localizations: null,
};

DeleteAssignment.propTypes = {
  assignmentId: PropTypes.number.isRequired,
  localizations: PropTypes.objectOf(PropTypes.any),
  deleteCurrentAssignment: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
});

const mapDispatchToProps = {
  deleteCurrentAssignment: deleteAssignment,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAssignment);
