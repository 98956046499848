import { GENERAL_LOADING_IDENTIFIER, SET_LOADER, SET_REDIRECT, SET_ORIGIN } from "redux/actions/ui";

const initState = { loading: { [GENERAL_LOADING_IDENTIFIER]: false }, redirectTo: "" };

export const uiReducer = (ui = initState, action) => {
  switch (true) {
    case action.type.includes(SET_LOADER):
      if (action.meta.identifier !== GENERAL_LOADING_IDENTIFIER && !action.payload) {
        const copyOfLoading = { ...ui.loading };
        delete copyOfLoading[action.meta.identifier];
        return { ...ui, loading: copyOfLoading };
      }

      return { ...ui, loading: { ...ui.loading, [action.meta.identifier]: action.payload } };

    case action.type.includes(SET_REDIRECT):
      return { ...ui, redirectTo: action.payload };
    case action.type.includes(SET_ORIGIN):
      return { ...ui, origin: action.payload };
    default:
      return ui;
  }
};
