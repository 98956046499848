import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const ClassGeneralInfo = ({
  localizations,
  studentCount,
  readingCount,
  listeningCount,
  recordingCount,
  quizCount,
}) => (
  <div className="class-reports-general-info">
    <div className="section-header">
      {localizations?.REPORT_GENERAL_INFO_HEADER ?? "Genel Bilgiler"}
    </div>
    <div className="class-reports-items-container">
      <div className="class-reports-item">
        <div className="item-value">{studentCount}</div>
        <div className="item-label">
          {localizations?.REPORT_GENERAL_INFO_STUDENT_ITEM ?? "Öğrenci Sayısı"}
        </div>
      </div>
      <div className="class-reports-item">
        <div className="item-value">{readingCount}</div>
        <div className="item-label">
          {localizations?.REPORT_GENERAL_INFO_READING_ITEM ?? "Okuma"}
        </div>
      </div>
      <div className="class-reports-item">
        <div className="item-value">{listeningCount}</div>
        <div className="item-label">
          {localizations?.REPORT_GENERAL_INFO_LISTENING_ITEM ?? "Dinleme"}
        </div>
      </div>
      <div className="class-reports-item">
        <div className="item-value">{recordingCount}</div>
        <div className="item-label">
          {localizations?.REPORT_GENERAL_INFO_RECORDING_ITEM ?? "Oku Kaydet"}
        </div>
      </div>
      <div className="class-reports-item">
        <div className="item-value">{quizCount}</div>
        <div className="item-label">
          {localizations?.REPORT_GENERAL_INFO_QUIZ_ITEM ?? "Değerlendirme"}
        </div>
      </div>
    </div>
  </div>
);

ClassGeneralInfo.defaultProps = {
  localizations: null,
};

ClassGeneralInfo.propTypes = {
  localizations: PropTypes.objectOf(PropTypes.any),
  studentCount: PropTypes.string.isRequired,
  readingCount: PropTypes.number.isRequired,
  listeningCount: PropTypes.number.isRequired,
  recordingCount: PropTypes.number.isRequired,
  quizCount: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
});

export default connect(mapStateToProps, null)(ClassGeneralInfo);
