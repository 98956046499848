import PropTypes from "prop-types";
import SuiBox from "components/SuiBox";
import SuiBadgeDotLevelColor from "components/SuiBadgeDotLevelColor";

function BookLevelCell({ content, color }) {
  return (
    <SuiBox ml={-1.325}>
      <SuiBadgeDotLevelColor size="sm" badgeContent={content} color={color} />
    </SuiBox>
  );
}

BookLevelCell.defaultProps = {
  color: "dark",
};

BookLevelCell.propTypes = {
  content: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default BookLevelCell;
