import React, { useState, useEffect, useMemo, createRef } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { jsPDF as JsPdf } from "jspdf";
import html2canvas from "html2canvas";
import Switch from "@mui/material/Switch";
import { connect } from "react-redux";

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiDatePicker from "components/SuiDatePicker";
import DashboardLayout from "custom/LayoutContainers/DashboardLayout";
import Loading from "custom/Loading";
import SuiSelect from "components/SuiSelect";
import DashboardNavbar from "custom/Navbars/DashboardNavbar";
import DataTable from "custom/Tables/DataTable";
import Footer from "custom/Footer";
import brand from "assets/images/logo-small.png";
import { getClassStudentReports } from "redux/actions/pages";
import {
  calculateMinOfStartDate,
  calculateMaxOfStartDate,
  calculateMinOfEndDate,
  calculateMaxOfEndDate,
  getAcademicStartDate,
  getAcademicEndDate,
} from "layouts/reports/components/month.mapper";
import TableCell from "../../components/TableCell";

const PageOrientationType = Object.freeze({
  portrait: "portrait",
  landscape: "landscape",
});

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const ClassStudentReports = ({
  getReports,
  classReports,
  reportStartDate,
  reportEndDate,
  suborganization,
}) => {
  const history = useHistory();

  const [elementRefs, setElementRefs] = useState([]);
  const [sortingInfo, setSortingInfo] = useState(null);
  const [showAverages, setShowAverages] = useState(true);
  const [selectedClassId, setSelectedClassId] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const columns = [
    {
      Header: "Adı Soyadı",
      accessor: "student",
      // eslint-disable-next-line react/prop-types
      Cell: ({ value: [data] }) => (
        <div
          style={{ cursor: data.id === 0 ? "default" : "pointer" }}
          aria-hidden
          onClick={(e) => {
            e.preventDefault();
            if (data?.id > 0) {
              const currentClass = classReports?.find((cl) => cl.id === selectedClassId);
              history.push({
                pathname: "/class-report/student-list/student-report",
                state: {
                  studentId: data.id,
                  totalMonthlyReadingCounts: currentClass?.monthlyClassResults,
                  studentCount:
                    currentClass?.students?.filter((stu) => stu.studentId > 0)?.length ?? 0,
                },
              });
            }
          }}
        >
          <TableCell data={data} />
        </div>
      ),
      disableSortBy: true,
    },
    {
      Header: "Okuma",
      accessor: "reading",
      // eslint-disable-next-line react/prop-types
      Cell: ({ value: [data] }) => <TableCell data={data} />,
      sortType: (rowA, rowB) => rowA.values.reading[0].value - rowB.values.reading[0].value,
    },
    {
      Header: "Dinleme",
      accessor: "listening",
      // eslint-disable-next-line react/prop-types
      Cell: ({ value: [data] }) => <TableCell data={data} />,
      sortType: (rowA, rowB) => rowA.values.listening[0].value - rowB.values.listening[0].value,
    },
    {
      Header: "Kayıt",
      accessor: "recording",
      // eslint-disable-next-line react/prop-types
      Cell: ({ value: [data] }) => <TableCell data={data} />,
      sortType: (rowA, rowB) => rowA.values.recording[0].value - rowB.values.recording[0].value,
    },
    {
      Header: "Değerlendirme",
      accessor: "quiz",
      // eslint-disable-next-line react/prop-types
      Cell: ({ value: [data] }) => <TableCell data={data} />,
      sortType: (rowA, rowB) => rowA.values.quiz[0].value - rowB.values.quiz[0].value,
    },
    {
      Header: "Puan (Bireysel)",
      accessor: "individualPoints",
      // eslint-disable-next-line react/prop-types
      Cell: ({ value: [data] }) => <TableCell data={data} />,
      // eslint-disable-next-line no-unused-vars
      sortType: (rowA, rowB) =>
        rowA.values.individualPoints[0].value - rowB.values.individualPoints[0].value,
    },
    {
      Header: "Puan (Görev)",
      accessor: "assignmentPoints",
      // eslint-disable-next-line react/prop-types
      Cell: ({ value: [data] }) => <TableCell data={data} />,
      sortType: (rowA, rowB) =>
        rowA.values.assignmentPoints[0].value - rowB.values.assignmentPoints[0].value,
    },
    {
      Header: "Puan (Toplam)",
      accessor: "totalPoints",
      // eslint-disable-next-line react/prop-types
      Cell: ({ value: [data] }) => <TableCell data={data} />,
      sortType: (rowA, rowB) => rowA.values.totalPoints[0].value - rowB.values.totalPoints[0].value,
    },
  ];

  const getTableData = (users) => {
    if (users && users.length > 0) {
      return {
        columns,
        rows: users
          ?.sort(
            (a, b) =>
              (a.userScores?.taskPoint ?? 0) +
              (a.userScores?.incentivePoint ?? 0) -
              ((b.userScores?.taskPoint ?? 0) + (b.userScores?.incentivePoint ?? 0))
          )
          ?.map((user) => ({
            student: [{ value: `${user.name} ${user.surname}`, id: user.studentId }],
            reading: [{ value: user.userScores?.read ?? 0, id: user.studentId }],
            listening: [{ value: user.userScores?.listen ?? 0, id: user.studentId }],
            recording: [{ value: user.userScores?.record ?? 0, id: user.studentId }],
            quiz: [{ value: user.userScores?.quiz ?? 0, id: user.studentId }],
            individualPoints: [{ value: user.userScores?.incentivePoint ?? 0, id: user.studentId }],
            assignmentPoints: [{ value: user.userScores?.taskPoint ?? 0, id: user.studentId }],
            totalPoints: [
              {
                value: (user.userScores?.incentivePoint ?? 0) + (user.userScores?.taskPoint ?? 0),
                id: user.studentId,
              },
            ],
          })),
      };
    }

    return { columns, rows: [] };
  };

  const memoizedTableData = useMemo(() => {
    const classReport = classReports?.find((cl) => cl.id === selectedClassId);
    if (classReport) {
      const { students } = classReport;

      if (students && students.length > 0) {
        if (showAverages === true) {
          if (!students.some((user) => user.studentId === 0)) {
            const numberOfUsers = students.length;
            const sumOfUserScores = students.reduce(
              (prev, current) => ({
                incentivePoint: prev.incentivePoint + (current?.userScores?.incentivePoint ?? 0),
                taskPoint: prev.taskPoint + (current?.userScores?.taskPoint ?? 0),
                listen: prev.listen + (current?.userScores?.listen ?? 0),
                quiz: prev.quiz + (current?.userScores?.quiz ?? 0),
                read: prev.read + (current?.userScores?.read ?? 0),
                record: prev.record + (current?.userScores?.record ?? 0),
              }),
              {
                incentivePoint: 0,
                taskPoint: 0,
                listen: 0,
                quiz: 0,
                read: 0,
                record: 0,
              }
            );

            const averageOfUserScores = {
              incentivePoint:
                Math.floor((sumOfUserScores.incentivePoint / numberOfUsers) * 10) / 10,
              taskPoint: Math.floor((sumOfUserScores.taskPoint / numberOfUsers) * 10) / 10,
              listen: Math.floor((sumOfUserScores.listen / numberOfUsers) * 10) / 10,
              quiz: Math.floor((sumOfUserScores.quiz / numberOfUsers) * 10) / 10,
              read: Math.floor((sumOfUserScores.read / numberOfUsers) * 10) / 10,
              record: Math.floor((sumOfUserScores.record / numberOfUsers) * 10) / 10,
            };

            const newStudents = [
              ...students,
              {
                studentId: 0,
                name: "Ortalama",
                surname: "",
                avatarUrl: "",
                userScores: averageOfUserScores,
              },
            ];

            return getTableData(newStudents);
          }
        }
        return getTableData(students);
      }
    }
    return getTableData(null);
  }, [classReports, selectedClassId, showAverages]);

  const downloadPDFReport = async (e) => {
    e.preventDefault();

    if (elementRefs.length > 0) {
      const pageOrientation = PageOrientationType.landscape;
      const pdf = new JsPdf({ orientation: pageOrientation });
      const originalPdfWidth = pdf.internal.pageSize.getWidth();

      let index = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const elementRef of elementRefs) {
        const element = elementRef.current;
        element.style.display = "block";
        // eslint-disable-next-line no-await-in-loop
        await delay(200);
        const { clientHeight, clientWidth } = element;
        // eslint-disable-next-line no-await-in-loop
        const canvas = await html2canvas(element, { scale: 1 });
        const data = canvas.toDataURL("image/jpeg", 100);
        let pdfWidth = 0;
        let pdfHeight = 0;

        // calculate belongs to width
        pdfWidth = originalPdfWidth;
        pdfHeight = (originalPdfWidth * clientHeight) / clientWidth;
        pdf.addImage(data, "JPEG", 0, 0, pdfWidth, pdfHeight);
        element.style.display = "none";

        if (elementRefs.length > index + 1) {
          pdf.addPage();
        }

        index += 1;
      }
      pdf.save("print.pdf");
    }
  };

  const getSortingInfo = (id, isSorted, isSortedDesc) => {
    if (sortingInfo !== null) {
      if (sortingInfo.id === id) {
        if (isSorted) {
          setSortingInfo({ id, isSorted, isSortedDesc });
        } else {
          setSortingInfo(null);
        }
      }
    } else if (isSorted) {
      setSortingInfo({ id, isSorted, isSortedDesc });
    }
  };

  useEffect(() => {
    if (suborganization > 0) {
      const academicStartDate = getAcademicStartDate();
      const academicEndDate = getAcademicEndDate();

      getReports({
        startDate: startDate !== null && startDate !== "" ? startDate : academicStartDate,
        endDate: endDate !== null && endDate !== "" ? endDate : academicEndDate,
        academicStartDate,
      });
    }
  }, [suborganization]);

  useEffect(() => {
    if (classReports && classReports.length > 0) {
      setSelectedClassId(classReports[0].id);
    }
  }, [classReports]);

  useEffect(() => {
    if (classReports && classReports.length > 0 && memoizedTableData) {
      const loopCount = Math.ceil(memoizedTableData.rows.length / 16);
      const refArray = [...Array(loopCount).keys()].map(() => createRef());

      setElementRefs(refArray);
    }
  }, [classReports, memoizedTableData]);

  const mainTable = useMemo(
    () => (
      <DataTable
        isSorted
        table={{
          columns: memoizedTableData.columns,
          rows: memoizedTableData.rows,
        }}
        entriesPerPage={{
          defaultValue: 25,
          entries: [5, 10, 15, 20, 25],
        }}
        // canSearch
        pagination={{ variant: "contained", color: "dark" }}
        showTotalEntries
        showPagination
        noEndBorder
        sendSortingInfoToContainer={getSortingInfo}
      />
    ),
    [classReports, sortingInfo, memoizedTableData, showAverages]
  );

  return (
    <>
      <Loading />
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <Grid container>
            <Grid item sm={7} xs={12} mb={{ xs: 2, sm: 0 }}>
              <h3>Sınıf Öğrenci Raporu</h3>
            </Grid>
            <Grid
              item
              size={{ sm: 5, xs: 12 }}
              flex={{ sm: true, xs: false }}
              ml={{ sm: "auto", xs: 0 }}
            >
              <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                <SuiBox display="flex" mr={3}>
                  <SuiBox>
                    <Switch
                      checked={showAverages}
                      onChange={() => setShowAverages(!showAverages)}
                    />
                  </SuiBox>
                  <SuiBox width="80%" ml={1}>
                    <SuiTypography variant="button" fontWeight="regular" color="text">
                      Ortalama Göster
                    </SuiTypography>
                  </SuiBox>
                </SuiBox>
                <SuiButton
                  size="medium"
                  variant="contained"
                  color="error"
                  // color="info"
                  onClick={downloadPDFReport}
                >
                  {/* {localizations?.REPORTS_DOWNLOAD_PDF_BTN_LABEL ?? "PDF İndir"} */}
                  PDF İndir
                </SuiButton>
              </div>
            </Grid>
            <Grid item lg={12} mt={3} xs={12}>
              <Card sx={{ overflow: "visible" }}>
                <SuiBox p={3}>
                  <Grid container spacing={1}>
                    <Grid item xxl={3} xl={4} lg={4} md={6} sm={12} xs={12}>
                      <SuiSelect
                        willMaxHeightBeUsed
                        onChange={(selected) => {
                          setSelectedClassId(selected.value);
                        }}
                        value={
                          selectedClassId > 0
                            ? classReports
                                ?.map((cl) => ({
                                  value: cl.id,
                                  label: `${cl.className} Sınıfı`,
                                }))
                                ?.find((cl) => cl.value === selectedClassId)
                            : null
                        }
                        placeholder="Sınıf Seçiniz"
                        options={classReports?.map((cl) => ({
                          value: cl.id,
                          label: `${cl.className} Sınıfı`,
                        }))}
                      />
                    </Grid>
                    {/* Offset */}
                    <Grid item xxl={3} xl={2} lg={2} md={0} sm={0} xs={0} />
                    <Grid item xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                          <SuiDatePicker
                            input={{
                              placeholder: "Başlangıç Tarihi",
                            }}
                            options={{
                              locale: "TR".toLowerCase(),
                              dateFormat: "d.m.Y",
                              enableTime: false,
                              minDate: calculateMinOfStartDate(),
                              maxDate: calculateMaxOfStartDate(endDate),
                            }}
                            value={startDate}
                            onChange={([date]) => {
                              setStartDate(
                                date && date !== ""
                                  ? `${
                                      date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
                                    }.${
                                      date.getMonth() + 1 < 10
                                        ? `0${date.getMonth() + 1}`
                                        : date.getMonth() + 1
                                    }.${date.getFullYear()}`
                                  : null
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                          <SuiDatePicker
                            input={{
                              placeholder: "Bitiş Tarihi",
                            }}
                            options={{
                              locale: "TR".toLowerCase(),
                              dateFormat: "d.m.Y",
                              enableTime: false,
                              minDate: calculateMinOfEndDate(startDate),
                              maxDate: calculateMaxOfEndDate(),
                            }}
                            value={endDate}
                            onChange={([date]) => {
                              setEndDate(
                                date && date !== ""
                                  ? `${
                                      date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
                                    }.${
                                      date.getMonth() + 1 < 10
                                        ? `0${date.getMonth() + 1}`
                                        : date.getMonth() + 1
                                    }.${date.getFullYear()}`
                                  : null
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                          <SuiButton
                            size="medium"
                            variant="contained"
                            color="info"
                            // color="info"
                            fullWidth
                            onClick={() => {
                              const academicStartDate = getAcademicStartDate();
                              const academicEndDate = getAcademicEndDate();

                              getReports({
                                startDate:
                                  startDate !== null && startDate !== ""
                                    ? startDate
                                    : academicStartDate,
                                endDate:
                                  endDate !== null && endDate !== "" ? endDate : academicEndDate,
                                academicStartDate,
                              });
                            }}
                          >
                            {/* {localizations?.REPORTS_DOWNLOAD_PDF_BTN_LABEL ?? "PDF İndir"} */}
                            Filtrele
                          </SuiButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </SuiBox>
              </Card>
            </Grid>
            <Grid item lg={12} mt={3} xs={12}>
              {mainTable}
            </Grid>
          </Grid>
        </SuiBox>
        {/* <SuiButton onClick={downloadPDFReport}>PDF İndir</SuiButton> */}
        <Footer />
      </DashboardLayout>
      {elementRefs.map((ref, index) => {
        let rows = [...memoizedTableData.rows];
        if (!showAverages) {
          rows = memoizedTableData.rows.filter((row) => row.student[0].id > 0);
        }

        if (sortingInfo) {
          switch (sortingInfo?.id) {
            case "reading":
              rows.sort((a, b) => {
                if (sortingInfo.isSortedDesc === false) {
                  return a.reading[0].value - b.reading[0].value;
                }
                return b.reading[0].value - a.reading[0].value;
              });
              break;
            case "listening":
              rows.sort((a, b) => {
                if (sortingInfo.isSortedDesc === false) {
                  return a.listening[0].value - b.listening[0].value;
                }
                return b.listening[0].value - a.listening[0].value;
              });
              break;
            case "recording":
              rows.sort((a, b) => {
                if (sortingInfo.isSortedDesc === false) {
                  return a.recording[0].value - b.recording[0].value;
                }
                return b.recording[0].value - a.recording[0].value;
              });
              break;
            case "quiz":
              rows.sort((a, b) => {
                if (sortingInfo.isSortedDesc === false) {
                  return a.quiz[0].value - b.quiz[0].value;
                }
                return b.quiz[0].value - a.quiz[0].value;
              });
              break;
            case "individualPoints":
              rows.sort((a, b) => {
                if (sortingInfo.isSortedDesc === false) {
                  return a.individualPoints[0].value - b.individualPoints[0].value;
                }
                return b.individualPoints[0].value - a.individualPoints[0].value;
              });
              break;
            case "assignmentPoints":
              rows.sort((a, b) => {
                if (sortingInfo.isSortedDesc === false) {
                  return a.assignmentPoints[0].value - b.assignmentPoints[0].value;
                }
                return b.assignmentPoints[0].value - a.assignmentPoints[0].value;
              });
              break;
            case "totalPoints":
              rows.sort((a, b) => {
                if (sortingInfo.isSortedDesc === false) {
                  return a.totalPoints[0].value - b.totalPoints[0].value;
                }
                return b.totalPoints[0].value - a.totalPoints[0].value;
              });
              break;
            default:
              rows.sort((a, b) => {
                if (sortingInfo.isSortedDesc === false) {
                  return a.totalPoints[0].value - b.totalPoints[0].value;
                }
                return b.totalPoints[0].value - a.totalPoints[0].value;
              });
              break;
          }
        } else {
          rows.sort((a, b) => a.totalPoints[0].value - b.totalPoints[0].value);
        }

        const currentElementRows = rows.slice(16 * index, 16 * (index + 1));
        return (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            ref={ref}
            style={{
              width: "1400px",
              marginTop: "1200px",
              paddingTop: "40px",
              display: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                marginLeft: "32px",
                marginRight: "32px",
                marginBottom: "20px",
              }}
            >
              <p style={{ fontSize: "24px", fontWeight: 600, color: "#67748E" }}>
                {(() => {
                  if (selectedClassId > 0) {
                    const selectedClassName = classReports?.find(
                      (cl) => cl.id === selectedClassId
                    )?.className;

                    if (selectedClassName && selectedClassName !== "") {
                      return `${selectedClassName} Sınıfı Öğrenci Raporu`;
                    }
                    return "Sınıf Öğrenci Raporu";
                  }
                  return "Sınıf Öğrenci Raporu";
                })()}
              </p>
              <p style={{ fontSize: "16px", fontWeight: 300, color: "#67748E" }}>
                {(() => {
                  if (
                    reportStartDate !== null &&
                    reportStartDate !== "" &&
                    reportEndDate !== null &&
                    reportEndDate !== ""
                  ) {
                    return `${reportStartDate} - ${reportEndDate}`;
                  }
                  return "";
                })()}
              </p>
              <img src={brand} alt="Okuma Platformu Logo" height="40px" />
            </div>
            <DataTable
              isSorted={false}
              table={{ columns: memoizedTableData.columns, rows: currentElementRows }}
              entriesPerPage={{
                defaultValue: 25,
                entries: [5, 10, 15, 20, 25],
              }}
              // canSearch
              pagination={{ variant: "contained", color: "dark" }}
              showTotalEntries={false}
              showPagination={false}
              noEndBorder
            />
            <div
              style={{
                display: "flex",
                color: "#67748E",
                fontSize: 10,
                fontWeight: 400,
                justifyContent: "center",
                textAlign: "center",
              }}
            >{`Sayfa ${index + 1}`}</div>
          </div>
        );
      })}
    </>
  );
};

ClassStudentReports.defaultProps = {
  classReports: [],
  reportStartDate: "",
  reportEndDate: "",
  suborganization: 0,
};

ClassStudentReports.propTypes = {
  getReports: PropTypes.func.isRequired,
  classReports: PropTypes.arrayOf(PropTypes.any),
  reportStartDate: PropTypes.string,
  reportEndDate: PropTypes.string,
  suborganization: PropTypes.number,
};

const mapStateToProps = (state) => ({
  classReports: state.data.classReports?.classReports,
  reportStartDate: state.data.classReports?.startDate,
  reportEndDate: state.data.classReports?.endDate,
  suborganization: state.header?.suborganization,
});

const mapDispatchToProps = {
  getReports: getClassStudentReports,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassStudentReports);
