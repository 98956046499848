/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import SuiTypography from "components/SuiTypography";
import { UNITS, SKILLS, SPECIAL_DAYS, OBJECTIVES } from "redux/actions/constants";
import FilterCheckBox from "../FilterCheckBox";

const UnitSkillSpecialDayFilter = ({ lookups, localizations, filters, setFilters, width }) => {
  const [course, setCourse] = useState("NA");

  useEffect(() => {
    if (filters.course?.includes("LIFE_SCIENCE")) {
      setCourse("L");
    } else if (filters.course?.includes("TURKISH")) {
      setCourse("T");
    } else if (filters.course === "SPECIAL_DAYS") {
      setCourse("S");
    } else {
      setCourse("NA");
    }
  }, [filters.course]);

  const headerMapper = {
    L: localizations?.UNIT_FILTER_HEADER ?? "ÜNİTE",
    T: localizations?.SKILL_FILTER_HEADER ?? "BECERİ",
    S: localizations?.SPECIALDAY_FILTER_HEADER ?? "ÖZEL GÜN",
    NA: localizations?.UNIT_SKILL_SPECIALDAY_FILTER_HEADER ?? "ÜNİTE / BECERİ / ÖZEL GÜN",
  };

  const lookupValuesMapper = {
    L: lookups.l_units?.lookupValues?.filter((lv) => filters.course === lv.parentValueKey),
    T: lookups.l_skills?.lookupValues?.filter((lv) => filters.course === lv.parentValueKey),
    S: lookups.l_specialDays?.lookupValues?.filter((lv) => filters.course === lv.parentValueKey),
    NA: null,
  };

  const checkMapper = {
    L: (lookupV) => filters.units?.includes(lookupV),
    T: (lookupV) => filters.skills?.includes(lookupV),
    S: (lookupV) => filters.specialDays?.includes(lookupV),
    NA: () => false,
  };

  const changeMapper = {
    L: (checked, lookupV) =>
      checked
        ? setFilters({
            ...filters,
            skills: [],
            specialDays: [],
            units: [...filters.units, lookupV],
          })
        : setFilters({
            ...filters,
            skills: [],
            specialDays: [],
            units: filters.units.filter((un) => un !== lookupV),
            objectives: filters.objectives.filter(
              (obj) =>
                !lookups.l_objectives?.lookupValues
                  ?.filter((lv) => lv.parentValueKey === lookupV)
                  ?.map((lv) => lv.lookupValueKey)
                  ?.includes(obj)
            ),
          }),
    T: (checked, lookupV) =>
      checked
        ? setFilters({
            ...filters,
            units: [],
            specialDays: [],
            skills: [...filters.skills, lookupV],
          })
        : setFilters({
            ...filters,
            units: [],
            specialDays: [],
            skills: filters.skills.filter((sk) => sk !== lookupV),
            objectives: filters.objectives.filter(
              (obj) =>
                !lookups.l_objectives?.lookupValues
                  ?.filter((lv) => lv.parentValueKey === lookupV)
                  ?.map((lv) => lv.lookupValueKey)
                  ?.includes(obj)
            ),
          }),
    S: (checked, lookupV) =>
      checked
        ? setFilters({
            ...filters,
            units: [],
            skills: [],
            specialDays: [...filters.specialDays, lookupV],
          })
        : setFilters({
            ...filters,
            units: [],
            skills: [],
            specialDays: filters.specialDays.filter((sd) => sd !== lookupV),
            objectives: [],
          }),
    NA: () => {},
  };

  return (
    <>
      <SuiTypography
        component="a"
        variant="button"
        fontWeight="bold"
        color="inherit"
        textTransform="capitalize"
      >
        {headerMapper[course]}
      </SuiTypography>
      <ul style={{ listStyle: "none", marginLeft: "20px" }}>
        {lookupValuesMapper[course]?.map((lv) => (
          <li key={lv.id}>
            <FilterCheckBox
              width={width}
              checked={checkMapper[course](lv.lookupValueKey)}
              onChange={(e) => {
                changeMapper[course](e.target.checked, lv.lookupValueKey);
              }}
              label={lv.lookupValueKey}
            />
          </li>
        ))}
      </ul>
    </>
  );
};

UnitSkillSpecialDayFilter.defaultProps = {
  localizations: null,
  lookups: {},
  width: 100,
};

UnitSkillSpecialDayFilter.propTypes = {
  localizations: PropTypes.objectOf(PropTypes.any),
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  setFilters: PropTypes.func.isRequired,
  lookups: PropTypes.objectOf(PropTypes.any),
  width: PropTypes.number,
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
  lookups:
    state.data.lookups && Object.keys(state.data.lookups).length !== 0
      ? {
          l_units: state.data.lookups[UNITS],
          l_skills: state.data.lookups[SKILLS],
          l_specialDays: state.data.lookups[SPECIAL_DAYS],
          l_objectives: state.data.lookups[OBJECTIVES],
        }
      : {},
});

export default connect(mapStateToProps, null)(UnitSkillSpecialDayFilter);
