import { PAGES } from "redux/actions/pages/constants";

// feature name
export const BOOK_INFO = `${PAGES} [BookInfo]`;

// action types
export const GET_TABS_LOOKUPS = `${BOOK_INFO} GET_TABS_LOOKUPS`;

// action creators
export const getTabsLookups = () => ({
  type: GET_TABS_LOOKUPS,
});
