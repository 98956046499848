/* eslint-disable no-lonely-if */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Grid, AppBar, Tabs, Tab } from "@mui/material";
import breakpoints from "assets/theme/base/breakpoints";
import SuiBox from "components/SuiBox";
import { connect } from "react-redux";
import { getTabsLookups } from "redux/actions/pages";
import { COURSES } from "redux/actions/constants";
import SummaryLessonPlan from "../SummaryLessonPlan";

const SummaryLessonPlanContainer = ({
  localizations,
  course,
  bookMaterials,
  bookLevel,
  bookOrder,
  bookTitle,
  coverImage,
  bookGrades,
  getLookups,
  lookups,
}) => {
  const lessonSummaryContainerRef = useRef(null);
  const [lessonSummaryContainerWidth, setLessonSummaryContainerWidth] = useState(0);

  const [tabs, setTabs] = useState([]);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [cardSettings, setCardSettings] = useState({
    division: 4,
    substracted: 76,
  });

  // const tabs = ["TURKISH", "LIFE_SCIENCE", "SPECIAL_DAYS"];

  useEffect(() => {
    getLookups();
  }, []);

  useEffect(() => {
    if (
      bookGrades &&
      bookGrades.length > 0 &&
      lookups.l_courses &&
      lookups.l_courses.lookupValues?.length > 0 &&
      bookMaterials
    ) {
      setTabs(
        lookups.l_courses.lookupValues
          .filter(
            (lv) =>
              bookGrades.includes(lv.parentValueKey) &&
              Object.values(bookMaterials)
                .map((bm) => bm.course)
                .includes(lv.lookupValueKey)
          )
          .map((lv) => lv.lookupValueKey)
      );
    }
  }, [bookGrades, lookups, bookMaterials]);

  useEffect(() => {
    if (tabs.length > 0) {
      if (course !== null && course !== undefined && course !== "") {
        let val;
        for (let i = 0; i < tabs.length; i += 1) {
          if (course.includes(tabs[i])) {
            val = i;
            break;
          }
        }

        if (val >= 0) {
          setTabValue(val);
        }
      }
    }
  }, [tabs]);

  useEffect(() => {
    function updateWidth() {
      if (
        lessonSummaryContainerRef.current?.clientWidth > 0 &&
        lessonSummaryContainerRef.current?.clientWidth !== lessonSummaryContainerWidth
      ) {
        setLessonSummaryContainerWidth(lessonSummaryContainerRef.current?.clientWidth);
      }

      if (window.innerWidth < breakpoints.values.md) {
        setCardSettings({ division: 2, substracted: 76 });
      } else {
        setCardSettings({ division: 4, substracted: 76 });
      }

      if (window.innerWidth < breakpoints.values.sm) {
        setTabsOrientation("vertical");
      } else {
        setTabsOrientation("horizontal");
      }
    }

    window.addEventListener("resize", updateWidth);
    updateWidth();

    return () => window.removeEventListener("resize", updateWidth);
  }, [tabs]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return tabs.length > 0 ? (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={12}>
        <Grid container>
          <Grid item xs={12} lg={12}>
            <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                {tabs.map((tab) => (
                  <Tab
                    key={tab}
                    label={
                      <div
                        style={{
                          height: "35px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "16px",
                          fontWeight: 700,
                        }}
                      >
                        {`${localizations[`TAB_LABEL_${tab}`] ?? tab} (${
                          bookMaterials.filter((bm) => bm.course.includes(tab)).length
                        })`}
                      </div>
                    }
                  />
                ))}
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={12}>
        <SuiBox ref={lessonSummaryContainerRef} p={1}>
          <div
            style={{
              width: "100%",
              overflowY: "hidden",
              overflowX: "auto",
              whiteSpace: "nowrap",
              display: "flex",
              alignItems: "top",
              paddingBottom: "16px",
            }}
          >
            {bookMaterials
              ?.filter((bm) => bm.course.includes(tabs[tabValue]))
              .map((m) => (
                <SuiBox
                  key={m.id}
                  mx={1}
                  width={
                    (lessonSummaryContainerWidth - cardSettings.substracted) / cardSettings.division
                  }
                  minWidth={
                    (lessonSummaryContainerWidth - cardSettings.substracted) / cardSettings.division
                  }
                >
                  <SummaryLessonPlan
                    bookLevel={bookLevel}
                    bookOrder={bookOrder}
                    bookTitle={bookTitle}
                    material={m}
                    coverImage={coverImage}
                  />
                </SuiBox>
              ))}
          </div>
        </SuiBox>
      </Grid>
    </Grid>
  ) : null;
};

SummaryLessonPlanContainer.defaultProps = {
  localizations: null,
  course: null,
  bookMaterials: [],
  bookLevel: null,
  bookOrder: null,
  bookTitle: null,
  coverImage: null,
  bookGrades: [],
  lookups: null,
};

SummaryLessonPlanContainer.propTypes = {
  localizations: PropTypes.objectOf(PropTypes.any),
  course: PropTypes.string,
  bookMaterials: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  bookLevel: PropTypes.string,
  bookOrder: PropTypes.number,
  bookTitle: PropTypes.string,
  coverImage: PropTypes.string,
  bookGrades: PropTypes.arrayOf(PropTypes.string),
  getLookups: PropTypes.func.isRequired,
  lookups: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
  bookMaterials:
    state.data.bookMaterialList !== undefined &&
    state.data.bookMaterialList !== null &&
    state.data.bookMaterialList.materials !== null &&
    state.data.bookMaterialList.materials !== undefined
      ? Object.values(state.data.bookMaterialList.materials).sort(
          (a, b) => a.materialOrder - b.materialOrder
        )
      : [],
  lookups:
    state.data.lookups && Object.keys(state.data.lookups).length > 0
      ? {
          l_courses: state.data.lookups[COURSES],
        }
      : {},
});

const mapDispatchToProps = {
  getLookups: getTabsLookups,
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryLessonPlanContainer);
