import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, Grid, CircularProgress } from "@mui/material";
import { Pencil } from "phosphor-react";
import DashboardLayout from "custom/LayoutContainers/DashboardLayout";
import DashboardNavbar from "custom/Navbars/DashboardNavbar";
import SuiBox from "components/SuiBox";
import SuiAvatar from "components/SuiAvatar";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import curved0 from "assets/images/curved-images/curved0.jpg";
import avatarPlaceholder from "assets/images/avatar-placeholder.png";
import requireAuth from "hocs/requireAuth";
import {
  getMyProfile,
  getUploadParams,
  removeUploadParams,
  updateMyProfile,
} from "redux/actions/pages";
import Alert from "custom/Alert";
import ChangePassword from "./components/ChangePassword";
import ProfileInfo from "./components/ProfileInfo";

const AVATAR_IDENTIFIER = "AVATAR";

const Profile = ({
  currentUser,
  localizations,
  getProfile,
  profile,
  getUploadingParameters,
  uploadParameters,
  removeUploadParameters,
  updateProfile,
}) => {
  const [avatarUploading, setAvatarUploading] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [avatarFile, setAvatarFile] = useState(null);

  useEffect(() => {
    if (profile?.avatarUrl) {
      setAvatarUrl(profile?.avatarUrl);
    }
  }, [profile?.avatarUrl]);

  useEffect(() => {
    if (currentUser?.id > 0) {
      // Call get detailed profile of current user
      getProfile();
    }
  }, [currentUser]);

  useEffect(() => {
    if (
      uploadParameters &&
      uploadParameters.key &&
      uploadParameters.key !== "" &&
      uploadParameters.url &&
      uploadParameters.url !== ""
    ) {
      const { key, url } = uploadParameters;
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", `${url}`);
      xhr.setRequestHeader("Content-Type", "image/*");
      xhr.setRequestHeader("x-amz-acl", "public-read");
      xhr.send(avatarFile);
      xhr.onload = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            const fileUrl = `https://edictdigitalspaces.fra1.digitaloceanspaces.com/${key}`;
            setAvatarUrl(fileUrl);
            updateProfile({ updatedFields: { avatarUrl: fileUrl } });
            removeUploadParameters({ identifier: AVATAR_IDENTIFIER });
          } else {
            // eslint-disable-next-line no-console
            console.error(xhr.statusText);
          }
        }
      };
      xhr.onerror = () => {
        setAvatarUploading(false);
        // eslint-disable-next-line no-console
        console.error("on file upload error");
        // eslint-disable-next-line no-console
        console.error(xhr.statusText);
      };
      xhr.onloadend = () => {
        setAvatarUploading(false);
      };
    }
  }, [uploadParameters]);

  const onAvatarChanged = (event) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      setAvatarUploading(true);

      const file = files[0];
      const fileNameArray = file.name.split(".");
      let extention;
      if (fileNameArray && fileNameArray.length > 1) {
        extention = fileNameArray[fileNameArray.length - 1];
        setAvatarFile(file);
      }
      const folderName = "useravatars";

      getUploadingParameters({
        type: "image/*",
        folder: folderName,
        extention,
        identifier: AVATAR_IDENTIFIER,
      });
    }
  };

  return (
    <>
      <Alert />
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox position="relative">
          <SuiBox
            display="flex"
            alignItems="center"
            position="relative"
            minHeight="18.75rem"
            borderRadius="xl"
            sx={{
              backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                `${linearGradient(
                  rgba(gradients.info.main, 0.6),
                  rgba(gradients.info.state, 0.6)
                )}, url(${curved0})`,
              backgroundSize: "cover",
              backgroundPosition: "50%",
              overflow: "hidden",
            }}
          />
          <Card
            sx={{
              backdropFilter: `saturate(200%) blur(30px)`,
              backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                rgba(white.main, 0.8),
              boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
              position: "relative",
              mt: -8,
              mx: 3,
              py: 2,
              px: 2,
            }}
          >
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <SuiBox position="relative" height="max-content" mx="auto">
                  <SuiAvatar
                    src={avatarUrl ?? avatarPlaceholder}
                    alt="profile-image"
                    variant="rounded"
                    size="xl"
                    shadow="sm"
                  />
                  <SuiBox
                    alt="spotify logo"
                    position="absolute"
                    right={0}
                    bottom={0}
                    mr={-1}
                    mb={-1}
                  >
                    <SuiButton
                      variant="gradient"
                      component="label"
                      color="light"
                      size="small"
                      iconOnly
                    >
                      {" "}
                      {avatarUploading ? (
                        <CircularProgress color="primary" size="1rem" />
                      ) : (
                        <SuiBox
                          height="100%"
                          width="100%"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Pencil weight="duotone" />
                        </SuiBox>
                      )}
                      <input
                        name="file"
                        type="file"
                        accept="image/*"
                        hidden
                        onChange={onAvatarChanged}
                      />
                    </SuiButton>
                  </SuiBox>
                </SuiBox>
              </Grid>
              <Grid item>
                <SuiBox height="100%" mt={0.5} lineHeight={1}>
                  <SuiTypography variant="h5" fontWeight="medium">
                    {`${currentUser?.name ?? ""} ${currentUser?.surname ?? ""}`}
                  </SuiTypography>
                  <SuiTypography variant="button" color="text" fontWeight="medium">
                    {localizations?.[currentUser?.organization?.organizationKey] ?? ""}
                  </SuiTypography>
                </SuiBox>
              </Grid>
            </Grid>
          </Card>
        </SuiBox>

        <SuiBox mt={5} mb={3}>
          <Grid container spacing={3}>
            {profile && (
              <Grid item xs={12} lg={6}>
                <ProfileInfo profile={profile} />
              </Grid>
            )}
            <Grid item xs={12} lg={6}>
              <ChangePassword />
            </Grid>
          </Grid>
        </SuiBox>
      </DashboardLayout>
    </>
  );
};

Profile.defaultProps = {
  currentUser: null,
  localizations: null,
  profile: null,
  uploadParameters: null,
};

Profile.propTypes = {
  currentUser: PropTypes.objectOf(PropTypes.any),
  localizations: PropTypes.objectOf(PropTypes.any),
  getProfile: PropTypes.func.isRequired,
  profile: PropTypes.objectOf(PropTypes.any),
  getUploadingParameters: PropTypes.func.isRequired,
  removeUploadParameters: PropTypes.func.isRequired,
  uploadParameters: PropTypes.objectOf(PropTypes.any),
  updateProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: state.auth?.currentUser,
  localizations: state.localization?.pairs,
  profile: state.data.profile,
  uploadParameters: state.data.uploadParameters
    ? state.data.uploadParameters[AVATAR_IDENTIFIER]
    : null,
});

const mapDispatchToProps = {
  getProfile: getMyProfile,
  getUploadingParameters: getUploadParams,
  removeUploadParameters: removeUploadParams,
  updateProfile: updateMyProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(requireAuth(Profile));
