import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { monthMapper } from "layouts/reports/components/month.mapper";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const MonthlyTaskComparisonChart = ({
  monthlyResults,
  localizations,
  totalClassMonthlyResults,
  itemCount,
}) => {
  const createMonthArray = () => {
    const date = new Date();
    const indexOfCurrentMonth = date.getMonth() + 1;
    const firstMonthIndex = parseInt(monthMapper[0].index, 10);

    return Array.from(
      {
        length:
          indexOfCurrentMonth < firstMonthIndex
            ? 13 + indexOfCurrentMonth - firstMonthIndex
            : 1 + indexOfCurrentMonth - firstMonthIndex,
      },
      (_, i) => i + firstMonthIndex
    );
  };

  const getReadingData = () =>
    createMonthArray().map((month) =>
      monthlyResults.reduce((total, current) => {
        if (
          parseInt(current.mon, 10) === (month === 12 ? 12 : month % 12) &&
          current.type === "READ"
        ) {
          return total + current.count;
        }
        return total;
      }, 0)
    );

  const getListeningData = () =>
    createMonthArray().map((month) =>
      monthlyResults.reduce((total, current) => {
        if (
          parseInt(current.mon, 10) === (month === 12 ? 12 : month % 12) &&
          current.type === "LISTEN"
        ) {
          return total + current.count;
        }
        return total;
      }, 0)
    );

  const getQuizData = () =>
    createMonthArray().map((month) =>
      monthlyResults.reduce((total, current) => {
        if (
          parseInt(current.mon, 10) === (month === 12 ? 12 : month % 12) &&
          current.type === "QUIZ"
        ) {
          return total + current.count;
        }
        return total;
      }, 0)
    );

  const getReadingAveragesData = () =>
    createMonthArray().map((month) =>
      itemCount > 0
        ? Math.floor(
            (totalClassMonthlyResults.reduce((total, current) => {
              if (parseInt(current.mon, 10) === month && current.type === "READ") {
                return total + current.count;
              }
              return total;
            }, 0) /
              itemCount) *
              10
          ) / 10
        : 0
    );

  const calculateAcademicYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    if (currentDate.getMonth() + 1 >= parseInt(monthMapper[0].index, 10)) {
      return `${currentYear}-${currentYear + 1}`;
    }
    return `${currentYear - 1}-${currentYear}`;
  };

  const getChartData = () => {
    const datasets = [
      {
        label: localizations?.MONTHLY_TASK_COMPARISON_READING_LABEL ?? "Okuma",
        data: getReadingData(),
        borderColor:
          localizations?.MONTHLY_TASK_COMPARISON_READING_BORDER_COLOR ?? "rgb(79, 118, 255)",
        backgroundColor:
          localizations?.MONTHLY_TASK_COMPARISON_READING_BGCOLOR ?? "rgba(79, 118, 255, 1)",
      },
      {
        label: localizations?.MONTHLY_TASK_COMPARISON_LISTENING_LABEL ?? "Dinleme",
        data: getListeningData(),
        borderColor:
          localizations?.MONTHLY_TASK_COMPARISON_LISTENING_BORDER_COLOR ?? "rgb(255, 41, 96)",
        backgroundColor:
          localizations?.MONTHLY_TASK_COMPARISON_LISTENING_BGCOLOR ?? "rgba(255, 41, 96, 1)",
      },
      {
        label: localizations?.MONTHLY_TASK_COMPARISON_QUIZ_LABEL ?? "Değerlendirme",
        data: getQuizData(),
        borderColor:
          localizations?.MONTHLY_TASK_COMPARISON_QUIZ_BORDER_COLOR ?? "rgb(255, 193, 23)",
        backgroundColor:
          localizations?.MONTHLY_TASK_COMPARISON_QUIZ_BGCOLOR ?? "rgba(255, 193, 23, 1)",
      },
    ];

    if (
      totalClassMonthlyResults !== undefined &&
      totalClassMonthlyResults !== null &&
      totalClassMonthlyResults.length > 0 &&
      itemCount > 0
    ) {
      datasets.push({
        label: "Okuma Ortalaması",
        data: getReadingAveragesData(),
        borderColor: "rgba(79, 118, 255, 0.5)",
        backgroundColor: "rgb(255, 255, 255)",
        borderDash: [5, 5],
        pointBackgroundColor: "rgba(79, 118, 255, 0.5)",
      });
    }

    return {
      labels: monthMapper.map((month) => month.text),
      datasets,
    };
  };

  return (
    <div className="improvement-progress">
      <div className="section-header">
        {`${
          localizations?.MONTHLY_TASK_COMPARISON_HEADER ?? "Gelişim Durumu"
        } (${calculateAcademicYear()} ${
          localizations?.REPORT_ACADEMIC_YEAR ?? "Eğitim Öğretim Yılı"
        })`}
      </div>
      <div className="chart-container">
        <Line
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: "bottom",
                align: "center",
                labels: { font: { size: 16 }, padding: 8 },
              },
              title: {
                display: false,
              },
              datalabels: {
                display: false,
              },
            },
            scales: {
              x: {
                ticks: { font: { size: 16 } },
              },
              y: {
                ticks: { font: { size: 20 }, precision: 0 },
                beginAtZero: true,
                min: 0,
              },
            },
          }}
          data={getChartData()}
        />
      </div>
    </div>
  );
};

MonthlyTaskComparisonChart.defaultProps = {
  localizations: null,
  monthlyResults: [],
  totalClassMonthlyResults: null,
  itemCount: 0,
};

MonthlyTaskComparisonChart.propTypes = {
  localizations: PropTypes.objectOf(PropTypes.any),
  monthlyResults: PropTypes.arrayOf(PropTypes.any),
  totalClassMonthlyResults: PropTypes.arrayOf(PropTypes.any),
  itemCount: PropTypes.number,
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
});

export default connect(mapStateToProps, null)(MonthlyTaskComparisonChart);
