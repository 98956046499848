/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";

function Shield({ color, size }) {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width={size}
    //   height={size}
    //   id="Raw"
    //   viewBox="0 0 256 256"
    // >
    //   <rect width="256" height="256" fill="none" />
    //   <path
    //     d="M224,56H160a32.00018,32.00018,0,0,0-32,32A31.99986,31.99986,0,0,0,96,56H32a7.99993,7.99993,0,0,0-8,8V192a7.99993,7.99993,0,0,0,8,8H96a31.99986,31.99986,0,0,1,32,32,32.00018,32.00018,0,0,1,32-32h64a7.99993,7.99993,0,0,0,8-8V64A7.99993,7.99993,0,0,0,224,56Z"
    //     opacity="0.2"
    //     fill={colors[color] ? colors[color].main : colors.dark.main}
    //   />
    //   <path
    //     d="M128,88a32,32,0,0,1,32-32h64a8,8,0,0,1,8,8V192a8,8,0,0,1-8,8H160a32,32,0,0,0-32,32"
    //     fill="none"
    //     stroke={colors[color] ? colors[color].main : colors.dark.main}
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //     strokeWidth="16"
    //   />
    //   <path
    //     d="M24,192a8,8,0,0,0,8,8H96a32,32,0,0,1,32,32V88A32,32,0,0,0,96,56H32a8,8,0,0,0-8,8Z"
    //     fill="none"
    //     stroke={colors[color] ? colors[color].main : colors.dark.main}
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //     strokeWidth="16"
    //   />
    // </svg>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      id="Raw"
      viewBox="0 0 256 256"
    >
      <rect width="256" height="256" fill="none" />
      <path
        className="path-fill path-opacity-59p"
        d="M40,106.66667V48a8,8,0,0,1,8-8H208a8,8,0,0,1,8,8v58.66667c0,84.01533-71.306,111.85016-85.5438,116.57058a7.54755,7.54755,0,0,1-4.9124,0C111.306,218.51683,40,190.682,40,106.66667Z"
        fillRule="evenodd"
      />
      <path
        className="path-stroke"
        d="M40,106.66667V48a8,8,0,0,1,8-8H208a8,8,0,0,1,8,8v58.66667c0,84.01533-71.306,111.85016-85.5438,116.57058a7.54755,7.54755,0,0,1-4.9124,0C111.306,218.51683,40,190.682,40,106.66667Z"
        fill="none"
        stroke={colors[color] ? colors[color].main : colors.dark.main}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
    </svg>
  );
}

// Setting default values for the props of Shield
Shield.defaultProps = {
  color: "dark",
  size: "16px",
};

// Typechecking props for the Shield
Shield.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
    "white",
  ]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Shield;
